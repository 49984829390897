import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPaperclip, faImage, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const IconPicker = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(true);
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const videoInputRef = useRef(null);

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);  // Toggle date picker visibility
    };

    const openDatePicker = () => {
        // Programmatically trigger the date picker
        document.getElementById('date-picker').focus();
    };

    const openFilePicker = () => {
        fileInputRef.current.click();
    };

    const openImagePicker = () => {
        imageInputRef.current.click();
    };

    const openVideoPicker = () => {
        videoInputRef.current.click();
    };

    return (
        <div>
            <FontAwesomeIcon title="Upload any file" style={{ cursor: 'pointer' }} icon={faPaperclip} className="mr-4" onClick={openFilePicker} />
            <FontAwesomeIcon title="Upload image" style={{ cursor: 'pointer' }} icon={faImage} className="mr-4" onClick={openImagePicker} />
            <FontAwesomeIcon title="Upload video" style={{ cursor: 'pointer' }} icon={faVideoCamera} className="mr-4" onClick={openVideoPicker} />

            {/* Hidden input elements */}

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => console.log('File selected:', e.target.files[0])}
            />

            <input
                type="file"
                ref={imageInputRef}
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => console.log('Image selected:', e.target.files[0])}
            />

            <input
                type="file"
                ref={videoInputRef}
                accept="video/*"
                style={{ display: 'none' }}
                onChange={(e) => console.log('Video selected:', e.target.files[0])}
            />
        </div>
    );
};

export default IconPicker;
