import React, { useState, useCallback, useEffect } from 'react';
import { ClayToggle } from '@clayui/form';
import { ClaySelect, ClayInput } from '@clayui/form';
import ClayButton from '@clayui/button'
import { Modal } from 'react-bootstrap';
import C1TableCheckingDP from './C1TableCheckingDP';
import C1TableCheckingKtoeDP from './C1TableCheckingKtoeDP';
import { getProductAndForms } from "../../../../api/nebProductService";
import "../../../../App.css";


const C1TableDP = ({ viewMode, fetchedReport, updateCheckedFormsCount, agencyId, setFuelValuesC1Checking, setProductAndUnitC1Checking, selectedQuarter, selectedYear, reportStatus, combinedStructure, setCombinedStructure, reportMap, setReportMap, totalSellouts }) => {


  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [productAndFormsList, setProductAndFormsList] = useState([]);
  const [checkingReport, setCheckingReport] = useState();
  const [checkingReportKtoe, setCheckingReportKtoe] = useState();
  const [showKtoe, setShowKtoe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowCheckingModal = () => setShowModal(true);
  const handleCloseCheckingModal = () => setShowModal(false);
  const [checked, setChecked] = useState(false);
  const [inputsPopulated, setInputsPopulated] = useState(false);
  const [c1Loading, setC1Loading] = useState(false);



  //API call runs only once, after the initial render
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getProductAndForms(agencyId, 'C1-FECS');
        setProductAndFormsList(result);
        console.log('debug c1: FETCHING P&F' + JSON.stringify(result, null, 2));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  const inputStyle = {
    textAlign: "right",
    width: "100%",
    height: "30px",
    marginRight: "5px",
    borderRadius: "5px"
  };


  const [fuelValues, setFuelValues] = useState([]);

  useEffect(() => {

  }, [c1Loading])

  // const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, subtractive) => {
  //   const { name, value: inputValue } = e.target;
  //   let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue))

  //   //Make value negative based on form type
  //   if (subtractive) {
  //     value = -Math.abs(value);
  //   }

  //   setFuelValues(prevFuelValues => {
  //     // Check if an object with matching index exists
  //     const exists = prevFuelValues.some(item => item.index === index);

  //     if (exists) {
  //       // Update the existing object
  //       return prevFuelValues.map(item => {
  //         if (item.index === index) {
  //           // Find the form
  //           let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
  //           let updatedForms;

  //           if (formIndex !== -1) {
  //             // Update the existing form
  //             updatedForms = item.forms.map((form, idx) => {
  //               if (idx === formIndex) {
  //                 let updatedCategories = form.categories.map(category => {
  //                   if (category.formCode === name) {
  //                     return { formCode: name, value: value, description: formFieldName };
  //                   } else {
  //                     return category;
  //                   }
  //                 });

  //                 // If the category doesn't exist, add it
  //                 if (!updatedCategories.some(category => category.formCode === name)) {
  //                   updatedCategories.push({ formCode: name, value: value, description: formFieldName });
  //                 }

  //                 return { ...form, categories: updatedCategories };
  //               } else {
  //                 return form;
  //               }
  //             });
  //           } else {
  //             // Add a new form
  //             updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
  //           }

  //           // Calculate the new balance
  //           const balance = updatedForms.reduce((sum, form) => {
  //             return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
  //           }, 0);

  //           return { ...item, forms: updatedForms, balance: balance };
  //         } else {
  //           return item;
  //         }
  //       });
  //     } else {
  //       // Add a new object
  //       const newItem = {
  //         index: index,
  //         balance: value,
  //         productName: productName,
  //         forms: [
  //           {
  //             formCode: parentFormFieldCode,
  //             categories: [
  //               { formCode: name, value: value, description: formFieldName }
  //             ]
  //           }
  //         ]
  //       };

  //       // Calculate the new balance
  //       const balance = newItem.forms.reduce((sum, form) => {
  //         return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
  //       }, 0);

  //       return [
  //         ...prevFuelValues,
  //         { ...newItem, balance: balance }
  //       ];
  //     }
  //   });
  // };

  const handleFuelValueChange = (e, index, productName, parentFormFieldCode, formFieldName, subtractive) => {
    const { name, value: inputValue } = e.target;
    let value = isNaN(Number(parseFloat(inputValue))) ? 0 : Number(parseFloat(inputValue));

    // Make value negative based on form type
    if (subtractive) {
      value = -Math.abs(value);
    }

    // Find the product in productList to check if it's standalone
    const product = productAndFormsList.find(p => p.productName === productName);
    const isStandalone = product ? product.standalone : false;

    // Get the totalSellout value if standalone is true
    let totalSelloutBalance = 0;
    if (isStandalone) {
      const totalSellout = totalSellouts.find(sellout => sellout[productName] !== undefined);
      totalSelloutBalance = totalSellout ? totalSellout[productName] : 0;
    }

    setFuelValues(prevFuelValues => {
      const exists = prevFuelValues.some(item => item.index === index);

      if (exists) {
        return prevFuelValues.map(item => {
          if (item.index === index) {
            let formIndex = item.forms.findIndex(form => form.formCode === parentFormFieldCode);
            let updatedForms;

            if (formIndex !== -1) {
              updatedForms = item.forms.map((form, idx) => {
                if (idx === formIndex) {
                  let updatedCategories = form.categories.map(category => {
                    if (category.formCode === name) {
                      return { formCode: name, value: value, description: formFieldName };
                    } else {
                      return category;
                    }
                  });

                  if (!updatedCategories.some(category => category.formCode === name)) {
                    updatedCategories.push({ formCode: name, value: value, description: formFieldName });
                  }

                  return { ...form, categories: updatedCategories };
                } else {
                  return form;
                }
              });
            } else {
              updatedForms = [...item.forms, { formCode: parentFormFieldCode, categories: [{ formCode: name, value: value, description: formFieldName }] }];
            }

            // Calculate balance by adding both the form balance and totalSelloutBalance if standalone is true
            const originalBalance = updatedForms.reduce((sum, form) => {
              return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
            }, 0);

            const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);
            // console.log("debugc1: isStandalone: " + isStandalone + " balance: " + balance + " product: " + productName)

            return { ...item, forms: updatedForms, balance: balance };
          } else {
            return item;
          }
        });
      } else {
        const newItem = {
          index: index,
          balance: value,
          productName: productName,
          forms: [
            {
              formCode: parentFormFieldCode,
              categories: [
                { formCode: name, value: value, description: formFieldName }
              ]
            }
          ]
        };

        const originalBalance = newItem.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0);

        const balance = originalBalance + (isStandalone ? totalSelloutBalance : 0);

        return [
          ...prevFuelValues,
          { ...newItem, balance: balance }
        ];
      }
    });
  };




  const [productAndUnit, setProductAndUnit] = useState([]);

  const handleUnitChange = (e, index, productName) => {
    const { value: unit } = e.target;

    setProductAndUnit(prevProductAndUnit => {
      // Check if an object with matching productName exists
      const exists = prevProductAndUnit.some(item => item.fieldName === productName);

      if (exists) {
        // Update the existing object
        return prevProductAndUnit.map(item => {
          if (item.fieldName === productName) {
            return {
              ...item,
              unit: unit
            };
          } else {
            return item;
          }
        });
      } else {
        // Add a new object
        const newItem = {
          fieldName: productName,
          unit: unit
        };

        return [
          ...prevProductAndUnit,
          newItem
        ];
      }
    });
  };

  // After productList changes, rows are formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;
    const dynamicRows = productAndFormsList.map((product, index) => {
      const fuelObject = fuelValues.find(fuel => fuel.index === index + 1);
      return {
        no: index + 1,
        product: product.productName,
        // totalSellout: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{totalSellouts.find(item => item[product.productName])?.[product.productName] ?? 0}</span>),
        unit: (
          <ClaySelect id={`draft_c1_selectUnit_${product.productName}`} onChange={(e) => handleUnitChange(e, index, product.productName)}>
            <option value="">Select...</option>
            {product.units.map((unit, unitIndex) => (
              <option
                key={unitIndex}
                value={unit.productUnitName}
              >
                {unit.productUnitName}
              </option>
            ))}
          </ClaySelect>
        ),
        ...product.forms.reduce((acc, form) => {
          acc[form.formFieldCode] = (
            <ClayInput
              className='no-spinner'
              key={`${index + 1}_${form.formFieldCode}`}
              id={`draft_c1_num_${product.productName}_${form.formFieldCode}`}
              style={inputStyle}
              type="number"
              name={form.formFieldCode}
              onChange={(e) => handleFuelValueChange(e, index + 1, product.productName, form.parentFormFieldCode, form.formFieldName, form.subtractive)}
            />
          );
          return acc;
        }, {}),
        balance: (<span style={{ "padding-right": "10px", display: "block", textAlign: "right" }}>{fuelObject ? fuelObject.balance : 0}</span>) // Handle undefined case
      }
    });

    //console.log("debugc1: " + JSON.stringify(fuelValues, null, 2))

    setRows(dynamicRows);
  }, [totalSellouts, productAndFormsList, fuelValues]);

  //After rows changes, headers is formed
  useEffect(() => {
    if (productAndFormsList.length === 0) return;

    const dynamicHeaders = [
      { id: 'no', name: 'No.' },
      { id: 'product', name: 'Product' },
      { id: 'unit', name: 'Unit' },
      { id: 'balance', name: 'Balance' },
      ...productAndFormsList[0].forms.map(form => ({
        id: form.formFieldCode,
        name: `${form.formFieldName} 
        (${form.parentFormFieldDesc})`
      })),
      // totalSellouts
      //   ?
      //   { id: 'totalSellout', name: 'Total Sell Out' }
      //   : null,
    ];
    setHeaders(dynamicHeaders);
    console.log("dynamicHeaders: " + JSON.stringify(dynamicHeaders, null, 2))
  }, [rows]);

  //merge

  // const [combinedStructure, setCombinedStructure] = useState(null);

  useEffect(() => {
    const formsMap = {};

    fuelValues.forEach(fuelItem => {
      fuelItem.forms.forEach(form => {
        if (!formsMap[form.formCode]) {
          formsMap[form.formCode] = {
            formCode: form.formCode,
            categories: []
          };
        }

        form.categories.forEach(category => {
          const product = {
            fieldName: fuelItem.productName,
            value: Math.abs(category.value), // ensure always positive
            unit: productAndUnit.find(item => item.fieldName === fuelItem.productName)?.unit || ''
          };

          const categoryIndex = formsMap[form.formCode].categories.findIndex(cat => cat.formCode === category.formCode);

          if (categoryIndex !== -1) {
            formsMap[form.formCode].categories[categoryIndex].products.push(product);
          } else {
            formsMap[form.formCode].categories.push({
              formCode: category.formCode,
              description: category.description,
              products: [product]
            });
          }
        });
      });
    });

    const formsArray = Object.values(formsMap);
    //setCombinedStructure({ forms: formsArray });

    console.log("fb cat, reportMap.forms : ", JSON.stringify(...reportMap.forms, null, 2));
    console.log("fb cat, formsArray : ", JSON.stringify(...formsArray, null, 2));

    //reportMap.forms = [...reportMap.forms, ...formsArray];
    //setCombinedStructure(reportMap);

    setReportMap(prevState => ({
      ...prevState,
      reportPeriodYear: selectedYear,
      reportQuarter: selectedQuarter,
      forms: [
        // Remove existing forms with the same formCode
        ...prevState.forms.filter(form => !formsArray.some(newForm => newForm.formCode === form.formCode)),
        // Add the updated forms
        ...formsArray
      ]
    }));

  }, [fuelValues, productAndUnit, selectedQuarter, selectedYear]);

  useEffect(() => {
    setCombinedStructure(reportMap);
  }, [reportMap]);

  //changes in F4-SEU standalone product in FB will be detected here for balance calculation since cannot be detected in handleFuelValueChange
  useEffect(() => {
    console.log("totalsellouts: " + JSON.stringify(totalSellouts, null, 2))
    setFuelValues(prevFuelValues => {
      return prevFuelValues.map(item => {
        const product = productAndFormsList.find(p => p.productName === item.productName);
        const isStandalone = product ? product.standalone : false;

        let totalSelloutBalance = 0;
        if (isStandalone) {
          const totalSellout = totalSellouts.find(sellout => sellout[product.productName] !== undefined);
          totalSelloutBalance = totalSellout ? totalSellout[product.productName] : 0;
        }

        const updatedBalance = item.forms.reduce((sum, form) => {
          return sum + form.categories.reduce((acc, category) => acc + category.value, 0);
        }, 0) + (isStandalone ? totalSelloutBalance : 0);

        return { ...item, balance: updatedBalance };
      });
    });
  }, [totalSellouts]);

  useEffect(() => {
    console.log("fuelValues: " + JSON.stringify(fuelValues, null, 2))
  }, [fuelValues])

  useEffect(() => {
    if (productAndFormsList.length !== 0) {
      const ktoeReport = convertToKtoe(combinedStructure);
      setCheckingReport(combinedStructure); // Original report
      setCheckingReportKtoe(ktoeReport);    // ktoe-converted report
    }
  }, [combinedStructure]);

  function convertToKtoe(report) {
    if (!report?.forms) return report;  // Early return if forms don't exist

    // Deep clone the report to avoid mutating the original state
    const updatedReport = {
      ...report,
      forms: report.forms.map((form) => ({
        ...form,
        categories: form.categories.map((category) => ({
          ...category,
          products: category.products.map((product) => {
            const matchingProduct = productAndFormsList.find(
              (p) => p.productName === product.fieldName
            );

            if (matchingProduct) {
              const matchingUnit = matchingProduct.units.find(
                (unit) =>
                  unit.productUnitName === product.unit || unit.productUnitName === "ktoe"
              );

              if (matchingUnit?.inKtoe !== undefined) {
                return {
                  ...product,
                  value: product.value * matchingUnit.inKtoe,
                  unit: "ktoe",
                };
              } else {
                console.log("debugc1: matching unit not found")
                console.error(
                  `Matching unit or ktoe value not found for product: ${product.fieldName}`
                );
              }
            } else {
              console.log("debugc1: matching product not found: " + product.fieldName)
              console.log("debugc1: productAndFormsList: " + JSON.stringify(productAndFormsList, null, 2))
            }
            return { ...product }; // Return the original product if no matching product is found
          }),
        })),
      })),
    };
    return updatedReport;
  }

  //check if form is balanced and have no missing unit
  useEffect(() => {
    setFuelValuesC1Checking(fuelValues);
  }, [fuelValues]);

  useEffect(() => {
    setProductAndUnitC1Checking(productAndUnit);
  }, [productAndUnit]);

  //after finish forming rows and headers, try fetch report. if got result, fill into the inputs.
  useEffect(() => {
    if (fetchedReport && productAndFormsList.length > 0 && !inputsPopulated && viewMode === "draft") {
      // Iterate through the forms and categories in fetchedData
      fetchedReport.forms.forEach((form) => {
        form.categories.forEach((category) => {
          category.products.forEach((product) => {

            // NUMERICAL INPUTS
            // Construct the id to match the <input> elements
            const numInputId = `draft_c1_num_${product.fieldName}_${category.formCode}`;
            const numInputElement = document.getElementById(numInputId);

            // Start loading
            setC1Loading(true);

            if (numInputElement) {
              // Wait for 2 seconds before executing the block of code
              const timer = setTimeout(() => {
                const value = product.value;
                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
                nativeInputValueSetter.call(numInputElement, value);

                // Dispatch the 'input' event to trigger onChange in React
                const event = new Event('input', { bubbles: true });
                numInputElement.dispatchEvent(event);

                // Stop loading after the operation is done
                setC1Loading(false);
              }, 5000); // 2-second delay
            }

            // DROPDOWN INPUTS
            // Construct the id to match the <select> elements
            const selectInputId = `draft_c1_selectUnit_${product.fieldName}`;
            const selectElement = document.getElementById(selectInputId);

            if (selectElement) {
              const value = product.unit;
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
              nativeInputValueSetter.call(selectElement, value);

              // Dispatch the 'input' event to trigger onChange in React
              const event = new Event('change', { bubbles: true });
              selectElement.dispatchEvent(event);
            }
          });
        });
      });
      setInputsPopulated(true);
    }
  }, [fetchedReport, headers]);

  return (
    <div>
      <div class="table-responsive">
        {c1Loading ? <div style={{ textAlign: 'center' }}>Loading...</div> : null}
        <table class="table-md table-bordered">
          <thead>
            <tr>
              {headers.map((column) => (
                <th
                  key={column.id}
                  className={
                    column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                      "table-cell-no-width"
                      : "table-cell-minw-200"}
                  style={
                    column.id === 'product'
                      ? {
                        position: 'sticky',
                        left: 0,  // First sticky column
                        backgroundColor: 'white',
                        zIndex: 1,  // Ensure it's above other cells
                      }
                      : column.id === 'balance'
                        ? {
                          position: 'sticky',
                          left: '199px',  // Adjust based on column width
                          backgroundColor: 'white',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : {}
                  }
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map(row => (
              <tr key={row.no}>
                {headers.map(column => (
                  <td
                    key={column.id}
                    className={
                      column.id === 'no' || column.id === 'balance' || column.id === 'totalSellout' ?
                        "table-cell-no-width"
                        : "table-cell-minw-200"}
                    style={
                      column.id === 'product'
                        ? {
                          position: 'sticky',
                          left: 0,  // First sticky column
                          backgroundColor: 'white',
                          zIndex: 1,  // Ensure it's above other cells
                        }
                        : column.id === 'balance'
                          ? {
                            position: 'sticky',
                            left: '199px',  // Adjust based on column width
                            backgroundColor: 'white',
                            zIndex: 1,  // Ensure it's above other cells
                          }
                          : {}
                    }
                  >
                    {row[column.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center mt-5 mb-3 d-flex justify-content-end"
      ><ClayButton onClick={handleShowCheckingModal}>Check</ClayButton >
        <Modal size='xl' show={showModal} onHide={handleCloseCheckingModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Submission</Modal.Title>
          </Modal.Header>
          <Modal.Body><span>Check all numbers, balances or units in your C1. </span>
            <div>
              <div className="d-flex justify-content-end">
                <table>
                  <tr>
                    <div className='d-flex justify-content-end'>
                      <td><span className='mr-2'>Original unit</span></td>
                      <td>
                        <ClayToggle
                          //label="In ktoe"
                          onToggle={(newVal) => setShowKtoe(newVal)} // Explicitly pass the new toggle state
                          toggled={showKtoe}
                        />
                      </td>
                      <td><span>In ktoe</span></td>
                    </div>
                  </tr>
                </table>
              </div>
              {
                !showKtoe ? (
                  <C1TableCheckingDP
                    agencyId={agencyId}
                    checkingReport={checkingReport}
                    totalSellouts={totalSellouts}
                  />
                ) : (
                  <C1TableCheckingKtoeDP
                    agencyId={agencyId}
                    checkingReportOriginalUnit={checkingReport}
                    checkingReportKtoe={checkingReportKtoe}
                    totalSellouts={totalSellouts}
                  />
                )
              }
            </div>


          </Modal.Body>
          <Modal.Footer>
            {/* <button className="btn btn-outline-secondary mr-1" onClick={handleCloseCheckingModal}>
                Close
              </button>
              <button
                    onClick={() => submitReport("Draft")}
                    className="btn btn-outline-primary mr-1"
                  >
                    Save As Draft
                  </button>
              <button className="btn btn-primary mr-1" onClick={handleShowConfirmationModal}>
                Submit
              </button> */}

          </Modal.Footer>
        </Modal>
      </div>
    </div>

  );
};

export default C1TableDP;
