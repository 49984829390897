import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClaySelect, ClayInput } from "@clayui/form";
import { Card } from "react-bootstrap";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayLayout from "@clayui/layout";
import { Table } from "@clayui/core";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import {
  getSubmissionListAgency,
  deleteReport,
} from "../../../api/nebReportService";
import { setQuarter } from "date-fns";

function DataSubmissionListDP() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [quarterList, setQuarterList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [lifeId, setLifeId] = useState();
  const [agencyId, setAgencyId] = useState();
  const [listLoading, setListLoading] = useState(true);
  const [yearAndQuarters, setYearAndQuarters] = useState();

  // Get the user object from the Zustand store
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    if (user && user.id) {
      setLifeId(user.id);
    }
  }, [user]);

  useEffect(() => {
    const fetchAgency = async () => {
      if (lifeId) {
        try {
          const agency = await getAgencyByLiferayId(lifeId);
          setAgencyId(parseInt(agency.id));
        } catch (error) {
          console.error("Error fetching agency:", error);
        }
      }
    };

    fetchAgency();
  }, [lifeId]);

  useEffect(() => {
    const fetchSubmissionList = async () => {
      if (agencyId) {
        try {
          const result = await getSubmissionListAgency(agencyId);
          setData(result.data);
          setFilteredData(result.data);
          console.log("result.data: " + JSON.stringify(result.data, null, 2));
        } catch (error) {
          console.error("Error fetching list:", error);
        } finally {
          setListLoading(false);
        }
      }
    };

    fetchSubmissionList();
  }, [agencyId]);

  useEffect(() => {
    // get years
    const yearSet = new Set();
    const quarterSet = new Set();
    filteredData.forEach((form) => {
      yearSet.add(form.reportPeriodYear);
      quarterSet.add(form.reportQuarter);
    });
    setYearList(Array.from(yearSet).sort((a, b) => parseInt(b) - parseInt(a))); // Sort in descending order
    setQuarterList(Array.from(quarterSet));
    const yearQuarters = getYearQuarterPairs(filteredData);
    setYearAndQuarters(yearQuarters);
    console.log("set: " + JSON.stringify(yearQuarters, null, 2));
  }, [filteredData]);

  const getYearQuarterPairs = (data) => {
    // Create a map to store year and its corresponding quarters
    const yearQuarterMap = {};

    data.forEach((item) => {
      const { reportPeriodYear, reportQuarter } = item;

      // If the year is not in the map, initialize it with an empty array
      if (!yearQuarterMap[reportPeriodYear]) {
        yearQuarterMap[reportPeriodYear] = [];
      }

      // Add the quarter to the year if it's not already in the array
      if (!yearQuarterMap[reportPeriodYear].includes(reportQuarter)) {
        yearQuarterMap[reportPeriodYear].push(reportQuarter);
      }
    });

    // Convert the map into an array of objects in the desired format
    const result = Object.keys(yearQuarterMap).map((year) => ({
      year: year,
      quarters: yearQuarterMap[year],
    }));

    return result;
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleDelete = async (reportId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this report?"
    );

    if (isConfirmed) {
      try {
        await deleteReport(reportId);
        // Update the list by filtering out the deleted report
        setFilteredData(filteredData.filter((d) => d.id !== reportId));
        console.log("Report deleted successfully");
      } catch (error) {
        console.error("Error deleting report:", error);
      }
    }
  };

  return (
    <>
      <div title="Data Submission" />
      <div>
        {/* <ClaySelect id="year" value={selectedYear} onChange={handleYearChange}>
                      <option value="" disabled>Year</option>
                      {yearList.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </ClaySelect> */}
        {/* Filters */}
        {/* <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <ClayLayout.Row className="w-100">
              <ClayLayout.Col md={15}>
                <ClayInput.Group>
                  <ClayInput.GroupItem>
                  
                  </ClayInput.GroupItem>
                </ClayInput.Group>
              </ClayLayout.Col>
            </ClayLayout.Row>
          </Card.Header>
        </ClayCard> */}
        <ClayLayout.Col md={15} className="d-flex justify-content-end">
          <Link
            to={`/neb-data-submission/neb-data-submission-new`}
            state={{ yearAndQuarters }}
          >
            <ClayButton displayType="primary" className="mb-2 mb-md-0">
              + Submit New Report
            </ClayButton>
          </Link>
        </ClayLayout.Col>
      </div>

      {listLoading ? (
        <p>Loading...</p>
      ) : (
        yearList.map((year) => (
          <div key={year}>
            <h3>{year}</h3>
            <Table>
              <thead className="text-dark">
                <tr>
                  <th>Quarter</th>
                  <th>Year</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData
                  .filter((d) => d.reportPeriodYear === year)
                  .sort((a, b) =>
                    a.reportQuarter.localeCompare(b.reportQuarter)
                  )
                  .map((d, i) => (
                    <tr key={i}>
                      <td>{d.reportQuarter}</td>
                      <td>{d.reportPeriodYear}</td>
                      <td>
                        <span
                          className={`badge ${
                            d.reportStatus === "Approved"
                              ? "bg-success"
                              : d.reportStatus === "Pending for Approval"
                              ? "bg-warning"
                              : d.reportStatus === "Pending for Amendment"
                              ? "bg-danger"
                              : "bg-secondary"
                          }`}
                        >
                          {d.reportStatus}
                        </span>
                      </td>
                      <td>
                        <div>
                          <Link
                            state={{ yearAndQuarters }}
                            to={`/neb-data-submission/neb-data-submission-view/draft/${d.id}/${d.agency.agencyName}/${d.reportQuarter}/${d.reportPeriodYear}/${d.reportStatus}/`}
                            className=""
                          >
                            View
                          </Link>
                          {d.reportStatus === "Draft" && (
                            <>
                              {" | "}
                              <span
                                onClick={() => handleDelete(d.id)}
                                style={{ color: "red", cursor: "pointer" }}
                                className="delete-link"
                              >
                                Delete
                              </span>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        ))
      )}
    </>
  );
}

export default DataSubmissionListDP;
