import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ClaySidebarLayout from './ClaySidebarLayout';
import { useLiferayOAuth } from '../hooks/useLiferayOAuth';
import menuItems from '../config/menuconfig';
import Activate from '../views/neb/agency/Activate';

const AppRoutes = () => {
  const location = useLocation();
  const requiresAuth = !location.pathname.startsWith('/activate');
  const { token, loginInProgress } = useLiferayOAuth(requiresAuth);

  if (loginInProgress) {
    return <div>Loading...</div>;
  }

  // Recursive function to generate routes
  const generateRoutes = (items) => {
    return items.flatMap(item => {
      const routes = [];

      // Add route if the item has an element
      if (item.element) {
        routes.push(
          <Route 
            key={item.id} 
            path={item.href} 
            element={token ? item.element : <Navigate to="/" replace />} // Protect routes
          />
        );
      }

      // Recursively generate routes for nested items
      if (item.items) {
        routes.push(...generateRoutes(item.items));
      }

      return routes;
    });
  };

  return (
    <ClaySidebarLayout>
      <Routes>
        {/* Allow access to /activate without authentication */}
        <Route path="/activate" element={<Activate />} />
        
        {/* Generate routes based on menu items recursively */}
        {generateRoutes(menuItems)}

        {/* Redirect any unmatched routes to home */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ClaySidebarLayout>
  );
};

export default AppRoutes;