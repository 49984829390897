import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

// Initialize the modules
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);


const GaugeChart = ({ quarter, gaugeData, totalSubmissionThatYear }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (gaugeData && Object.keys(gaugeData).length > 0) {
            console.log("received gaugedata: " + quarter + " " + JSON.stringify(gaugeData, null, 2))
            const renderIcons = function () {
                this.series.forEach(series => {
                    if (!series.icon) {
                        series.icon = this.renderer
                            .text(
                                `<i class="fa fa-${series.options.custom.icon}"></i>`,
                                0,
                                0,
                                true
                            )
                            .attr({
                                zIndex: 10,
                            })
                            .css({
                                color: series.options.custom.iconColor,
                                fontSize: '1.5em',
                            })
                            .add(this.series[2].group);
                    }
                    series.icon.attr({
                        x: this.chartWidth / 2 - 15,
                        y:
                            this.plotHeight / 2 -
                            series.points[0].shapeArgs.innerR -
                            (series.points[0].shapeArgs.r - series.points[0].shapeArgs.innerR) / 2 +
                            8,
                    });
                });
            };

            const trackColors = Highcharts.getOptions().colors.map(color =>
                new Highcharts.Color(color).setOpacity(0.3).get()
            );

            if (chartRef.current) {
                Highcharts.chart(chartRef.current, {
                    credits: false,
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    chart: {
                        type: 'solidgauge',
                        height: '90%',
                        events: {
                            render: renderIcons,
                        },
                    },

                    title: {
                        text: quarter,
                        style: {
                            fontSize: '14px',
                        },
                    },

                    tooltip: {
                        borderWidth: 0,
                        backgroundColor: 'none',
                        shadow: false,
                        style: {
                            fontSize: '17px',
                        },
                        valueSuffix: '',
                        pointFormat:
                            '{series.name}<br>' +
                            '<span style="font-size: 2em; color: black; ' +
                            'font-weight: bold">{point.y}</span>',
                        positioner: function (labelWidth) {
                            return {
                                x: (this.chart.chartWidth - labelWidth) / 2,
                                y: this.chart.plotHeight / 2 + 15,
                            };
                        },
                    },

                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [
                            {
                                // Track for Conversion
                                outerRadius: '112%',
                                innerRadius: '88%',
                                backgroundColor: "#cbe7d1",
                                borderWidth: 0,
                            },
                            {
                                // Track for Engagement
                                outerRadius: '87%',
                                innerRadius: '63%',
                                backgroundColor: "#ffedc5",
                                borderWidth: 0,
                            },
                            {
                                // Track for Feedback
                                outerRadius: '62%',
                                innerRadius: '38%',
                                backgroundColor: "#fdc8c8",
                                borderWidth: 0,
                            },
                        ],
                    },

                    yAxis: {
                        min: 0,
                        max: totalSubmissionThatYear,
                        lineWidth: 0,
                        tickPositions: [],
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false,
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true,
                        },
                    },

                    series: [
                        {
                            name: 'Approved',
                            data: [
                                {
                                    color: "#169b32",
                                    radius: '112%',
                                    innerRadius: '88%',
                                    //y: (gaugeData["Approved"] / maxGaugeData) * 100,
                                    y: gaugeData["Approved"]
                                },
                            ],
                            custom: {
                                icon: 'filter',
                                iconColor: '#303030',
                            },
                        },
                        {
                            name: 'Pending for Approval',
                            data: [
                                {
                                    color: "#ffae00",
                                    radius: '87%',
                                    innerRadius: '63%',
                                    //y: (gaugeData["Pending for Approval"] / maxGaugeData) * 100,
                                    y: gaugeData["Pending for Approval"]
                                },
                            ],
                            custom: {
                                icon: 'comments-o',
                                iconColor: '#ffffff',
                            },
                        },
                        {
                            name: 'Pending for Amendment',
                            data: [
                                {
                                    color: "#ff6060",
                                    radius: '62%',
                                    innerRadius: '38%',
                                    // y: (gaugeData["Pending for Amendment"] / maxGaugeData) * 100,
                                    y: gaugeData["Pending for Amendment"],
                                },
                            ],
                            custom: {
                                icon: 'commenting-o',
                                iconColor: '#303030',
                            },
                        },
                    ],
                });
            }
        }
    }, [gaugeData]);

    return <div id="container" ref={chartRef} />;
};

export default GaugeChart;
