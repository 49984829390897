import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getDataProviderByAgencyId } from "../../../../api/nebDataProviderService";
import { getAgencyById } from "../../../../api/nebAgencyService";
import { getAllProductCategory } from "../../../../api/nebProductCategoryService";
import { getProductByCategoryId } from "../../../../api/nebProductService";
import {
  deleteCategoriesAndProductsByAgencyId,
  saveCategoryList,
} from "../../../../api/nebAgencyCategoryListService";
import { saveProductNameList } from "../../../../api/nebAgencyProductNameListService";

const ProductList = ({ agencyId, handleSave, handleBack }) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [productNameListing, setProductNameListing] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCategoryListing();
    if (agencyId) {
      fetchExistingData(agencyId);
    }
    addRow();
  }, [agencyId]);

  const fetchExistingData = async (agencyId) => {
    try {
      const result = await getAgencyById(agencyId);
      const existingRows = result.categories.map((category) => ({
        agencyId: result.id,
        categoryCode: category.categoryCode,
        categoryName: category.categoryName,
        productCodes: category.products.map((product) => product.productCode),
        productNames: category.products.map((product) => product.productName),
      }));

      setRows(existingRows);
      existingRows.forEach((row, index) => {
        fetchProductNameListing(row.categoryCode, index, row.productCodes);
      });
    } catch (error) {
      console.error("Error fetching existing data:", error);
      toast.error(`Failed to fetch existing data`);
    }
  };

  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        agencyId: agencyId || null,
        categoryCode: "",
        categoryName: "",
        productCodes: [],
      },
    ]);
  };

  const handleInputChange = async (index, event) => {
    const { name, value } = event.target;
    try {
      const newRows = rows.map((row, rowIndex) => {
        if (index === rowIndex) {
          if (name === "categoryCode") {
            const categoryId = parseInt(value);
            const selectedCategory = categoryListing.find(
              (category) => parseInt(category.id) === parseInt(categoryId)
            );
            return {
              ...row,
              [name]: categoryId,
              categoryName: selectedCategory
                ? selectedCategory.productCategory
                : "",
              productCodes: [],
            };
          }
          return { ...row, [name]: value };
        }
        return row;
      });

      setRows(newRows);

      if (name === "categoryCode") {
        await fetchProductNameListing(parseInt(value), index);
      }
    } catch (error) {
      console.error("Error in handleInputChange:", error);
      toast.error(`Error handling input change`);
    }
  };

  const handleProductChange = (selectedOptions, index) => {
    console.log("selectedOptions", selectedOptions);
    console.log("index", index);

    const newRows = rows.map((row, rowIndex) => {
      console.log("row", row);
      console.log("rowIndex", rowIndex);

      if (index === rowIndex) {
        // Convert selected options to integers
        const newProductCodes = selectedOptions.map((option) =>
          parseInt(option.value, 10)
        );
        console.log("newProductCodes", newProductCodes);

        return {
          ...row,
          productCodes: newProductCodes,
          // Optional: Update productNames based on new product codes
          productNames: newProductCodes.map((code) => ({
            value: code,
            label:
              productNameListing[index]?.find(
                (product) => parseInt(product.productCode, 10) === code
              )?.productName || "",
          })),
        };
      }
      return row;
    });

    setRows(newRows);
  };

  const fetchCategoryListing = async () => {
    try {
      const result = await getAllProductCategory();
      const sortedResult = [...result].sort((a, b) => {
        return a.productCategory.localeCompare(b.productCategory);
      });
      console.log("productcatlist: " + JSON.stringify(sortedResult, null, 2));
      setCategoryListing(sortedResult);
    } catch (error) {
      console.error("Error fetching category listing:", error);
      
      toast.error(`Failed to fetch category listing`);
    }
  };

  const fetchProductNameListing = async (
    categoryCode,
    index,
    selectedProductCodes = []
  ) => {
    try {
      const result = await getProductByCategoryId(categoryCode);

      // Sort the result based on product names
      const sortedResult = [...result].sort((a, b) => {
        return a.productName.localeCompare(b.productName);
      });
      const filteredProducts = sortedResult.filter(
        (product) => !selectedProductCodes.includes(product.productCode)
      );

      setProductNameListing((prevProductNameListing) => ({
        ...prevProductNameListing,
        [index]: filteredProducts.map((product) => ({
          ...product,
          productCode: product.productCode,
        })),
      }));

      setRows((prevRows) =>
        prevRows.map((row, rowIndex) => {
          if (rowIndex === index) {
            return {
              ...row,
              productCodes: selectedProductCodes,
              productNames: selectedProductCodes.map((code) => ({
                value: code,
                label:
                  sortedResult.find((product) => product.productCode === code)
                    ?.productName || "",
              })),
            };
          }
          return row;
        })
      );
    } catch (error) {
      console.error("Error fetching product name listing:", error);
      toast.error(`Failed to fetch product name listing`);
    }
  };

  useEffect(() => {
    console.log(
      "setProductNameListing: " + JSON.stringify(productNameListing, null, 2)
    );
  }, [productNameListing]);

  const validateForm = () => {
    const newErrors = {};
    rows.forEach((row, index) => {
      if (!row.categoryCode) {
        newErrors[`categoryCode-${index}`] = "Product category is required";
      }
      if (row.productCodes.length === 0) {
        newErrors[`productCodes-${index}`] =
          "At least one product name is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSaveClickProduct = async () => {
    if (!validateForm()) {
      
      toast.error(`Please fill in all required fields`);
      return;
    }

    try {
      const categoriesToSave = rows.map((row) => ({
        agencyId: row.agencyId,
        categoryCode: row.categoryCode,
        categoryName: row.categoryName,
      }));

      const uniqueAgencyIds = [...new Set(rows.map((row) => row.agencyId))];

      for (const agencyId of uniqueAgencyIds) {
        await deleteCategoriesAndProductsByAgencyId(agencyId);
      }

      const savedCategories = await saveCategoryList(categoriesToSave);

      const productNameList = [];
      savedCategories.forEach((category, index) => {
        rows[index].productCodes.forEach((productCode) => {
          productNameList.push({
            categoryId: category.id,
            productCode: productCode,
            productName:
              productNameListing[index]?.find(
                (product) => parseInt(product.id) === parseInt(productCode)
              )?.productName || "",
          });
        });
      });

      await saveProductNameList(productNameList);
      // Show success toast notification
      
      toast.success(`The details have been saved!`);
      setTimeout(() => {
        handleSave(uniqueAgencyIds[0]);
      }, 2000);
    } catch (error) {
      console.error("Error saving the details:", error);
      toast.error(`Failed to save agency details`);
    }
  };

  const onCancelClick = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      
      toast.success(`The process have been cancel`);
      setTimeout(() => {
        navigate(`/neb-agency`);
      }, 2000);
    }
  };

  const getAvailableCategories = (index) => {
    const selectedCategoryCodes = rows
      .slice(0, index)
      .map((row) => row.categoryCode);
    return categoryListing.filter(
      (category) => !selectedCategoryCodes.includes(category.id)
    );
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    const updatedProductNameListing = { ...productNameListing };
    delete updatedProductNameListing[index];

    setRows(updatedRows);
    setProductNameListing(updatedProductNameListing);
  };

  return (
    <div>
      <Card>
        <Card.Header className="d-flex flex-column align-items-start">
          <div className="d-flex justify-content-between w-100 mb-3">
            <Card.Title as="h2" className="mb-0">
              <b>Products</b>
              <p>
                Select products based on your requirement{" "}
                <span className="text-danger">*</span>
              </p>
            </Card.Title>
          </div>
        </Card.Header>
        <Card.Body>
          {rows.map((row, index) => (
            <Form.Group as={Row} className="mt-3" key={index}>
              <Col sm={6}>
                <Form.Label
                  htmlFor={`category-${index}`}
                  className="font-weight-normal"
                >
                  Product Category <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="categoryCode"
                  value={parseInt(row.categoryCode) || ""}
                  onChange={(event) => handleInputChange(index, event)}
                  aria-label="Select Product Category"
                  isInvalid={!!errors[`categoryCode-${index}`]}
                >
                  <option value="">Please Select...</option>
                  {getAvailableCategories(index).map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.productCategory}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors[`categoryCode-${index}`]}
                </Form.Control.Feedback>
              </Col>
              <Col sm={6}>
                <Form.Label
                  htmlFor={`product-${index}`}
                  className="font-weight-normal"
                >
                  Product Name <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  id={`product-${index}`}
                  name="productCodes"
                  options={
                    productNameListing[index]?.map((product) => ({
                      value: product.id,
                      label: product.productName,
                    })) || []
                  }
                  value={row.productCodes.map((code) => {
                    const product = productNameListing[index]?.find(
                      (product) => parseInt(product.id) === parseInt(code)
                    );
                    return {
                      value: parseInt(code),
                      label: product ? product.productName : "",
                    };
                  })}
                  onChange={(selectedOptions) =>
                    handleProductChange(selectedOptions, index)
                  }
                  isMulti
                  isInvalid={!!errors[`productCodes-${index}`]}
                />
                <div className="invalid-feedback d-block">
                  {errors[`productCodes-${index}`]}
                </div>
              </Col>
              <Col md={4}>
                {index > 0 && (
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteRow(index)}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Form.Group>
          ))}
          <Button variant="primary" onClick={addRow} className="mt-4">
            + Add More Product
          </Button>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="light"
          className="mr-2 btn btn-outline-secondary"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="link"
          className="mr-2 btn btn-outline-primary"
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        <Button variant="primary" className="mr-2" onClick={onSaveClickProduct}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProductList;
