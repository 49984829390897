import axiosInstance from './axiosInstance';

const endpoint = "/neb/agency/productlist";

export const saveCategoryList = async (agencyCategoryLists) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/save`, agencyCategoryLists);
    return response.data;
  } catch (error) {
    console.error('Error saving agency category lists:', error);
    throw error;
  }
};

export const getCategoryListByAgencyId = async (agencyId) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/listByAgencyId/${agencyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const deleteCategoriesAndProductsByAgencyId = async (agencyId) => {
  try {
    const response = await axiosInstance.delete(`${endpoint}/deleteByAgencyId/${agencyId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting categories and products:', error);
    throw error;
  }
};