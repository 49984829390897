import React, { useEffect, useRef, useState } from "react";
import profilePlaceholder from "./profile-svgrepo-com.svg";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import { getDataProviderByAgencyId } from "../../../api/nebDataProviderService";
import {
  fetchUserProfileImage,
  uploadUserImage,
} from "../../../api/userService";
import DetailsSection from "./DetailsSection";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import { Body, Cell, Head, Row as RowClay } from "@clayui/core";
import ClayCard from "@clayui/card";
import { Link } from "react-router-dom";
import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";
import { fetchCurrentUser } from "../../../api/userService";
import { format } from "date-fns";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "loading",
    role: "loading",
    designation: "loading",
    agency: "loading",
    email: "loading",
    phoneNumber: "loading",
    contactPerson: {
      name: "loading",
      email: "loading",
      phoneNumber: "loading",
    },
    productList: [],
    formList: [],
    profileImageUrl: profilePlaceholder, // Default placeholder image
  });

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function
  const fileInputRef = useRef(null); // Ref for the hidden file input
  
  const [lastLoginDate, setLastLoginDate] = useState("");

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user?.id) return;

      try {
        console.log("user id profile: ", user.id);
        const agency = await getAgencyByLiferayId(user.id);
        const dp = await getDataProviderByAgencyId(agency.id);

        // Fetch the profile image as a base64-encoded string
        const profileImageBase64 = await fetchUserProfileImage();

        setProfileData({
          name: dp.name,
          role: "Data Provider", // Example role
          designation: "Agency Representative", // Example designation
          agency: agency.agencyName,
          email: dp.email,
          phoneNumber: dp.phoneNo,
          contactPerson: {
            name: dp.agency.cpdName,
            email: dp.agency.cpdEmail,
            phoneNumber: dp.agency.cpdPhoneNumber,
          },
          productList: extractProductList(dp),
          formList: extractFormFields(dp),
          profileImageUrl: profileImageBase64 || profilePlaceholder, // Set base64 URL for image
        });
      } catch (error) {
        console.error("Error fetching profile data:", error);
        logout(); // Handle token invalidation
      }
    };

    if (user?.id) {
      fetchProfileData();
    }
  }, [user, logout]);

  // get last login date
  useEffect(() => {
    const getLastLoginDate = async () => {
      try {
        const user = await fetchCurrentUser();
        console.log("Current User", user);

        // Set the user's last login
        setLastLoginDate(formatDate(user.lastLoginDate));

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getLastLoginDate();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

  // Trigger file input click
  const handleProfileImageClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input when the image is clicked
  };

  // Handle file selection and upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        // Upload the image
        const response = await uploadUserImage(formData);
        if (response.status == 200) {
          const updatedProfileImageBase64 = await fetchUserProfileImage(); // Fetch the updated profile image
          setProfileData((prevState) => ({
            ...prevState,
            profileImageUrl: updatedProfileImageBase64 || profilePlaceholder,
          }));
        }
      } catch (error) {
        console.error("Error uploading profile image:", error);
      }
    }
  };

  const extractProductList = (data) => {
    return data.agency.categories.flatMap((category) =>
      category.products.map((product) => ({
        productName: product.productName,
      }))
    );
  };

  const extractFormFields = (data) => {
    if (!data?.agency?.forms) return [];

    const formsList = data.agency.forms
      .map((form) => {
        const filteredFields = form.formFields.filter(
          (field) => !field.formFieldCode.includes("-NA")
        );
        if (filteredFields.length === 0) return null;

        return {
          formType: form.formType,
          formDescription: form.formDescription,
          formFields: filteredFields.map((field) => ({
            formFieldCode: field.formFieldCode,
            formFieldName: field.formFieldName,
          })),
        };
      })
      .filter(Boolean);

    if (data.agency.installedCapacity) {
      formsList.push({ formDescription: "Installed Capacity", formFields: [] });
    }
    if (data.agency.powerPlantPerformance) {
      formsList.push({
        formDescription: "Power Plant Performance",
        formFields: [],
      });
    }
    if (data.agency.netCalorificValue) {
      formsList.push({
        formDescription: "Net Calorific Value",
        formFields: [],
      });
    }

    return formsList;
  };

  const {
    name,
    role,
    designation,
    agency,
    email,
    phoneNumber,
    contactPerson,
    productList,
    formList,
    profileImageUrl,
  } = profileData;

  return (
    <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <ClayLayout.Row className="w-100">
          <ClayLayout.Col md={12} className="d-flex justify-content-end">
            <Link to="/neb-profile/edit">
              <ClayButton displayType="primary" className="mb-2 mb-md-0">
                Edit Profile
              </ClayButton>
            </Link>
          </ClayLayout.Col>
        </ClayLayout.Row>
      </Card.Header>
      <Card.Body>
        <Row>
          {/* Profile */}
          <Col md={3} className="text-center">
            <img
              src={profileImageUrl}
              alt="Profile"
              className="profile-image mb-3"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileImageClick}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
            <h3 className="profile-name">{name}</h3>
            <p className="text-muted mb-1">{role}</p>
            <p className="text-muted">{designation}</p>
          </Col>

          {/* My Details */}
          <Col md={3}>
            <h5>My Details</h5>
            <p>
              <strong>Agency:</strong> {agency}
            </p>
            <p>
              <strong>Email:</strong> {email}
            </p>
            <p>
              <strong>Phone Number:</strong> {phoneNumber}
            </p>
            <p>
              <strong>Last Login:</strong> {lastLoginDate}
            </p>
          </Col>

          {/* Contact Person */}
          <Col md={3}>
            <h5>Contact Person</h5>
            <p>
              <strong>Name:</strong> {contactPerson.name}
            </p>
            <p>
              <strong>Email:</strong> {contactPerson.email}
            </p>
            <p>
              <strong>Phone Number:</strong> {contactPerson.phoneNumber}
            </p>
          </Col>

          {/* Product & Form */}
          <Col md={3}>
            <h5>Product & Form</h5>
            <strong>Product List</strong>
            <ul className="list-group list-group-flush">
              {productList.length ? (
                productList.map((product, index) => (
                  <li key={index} className="list-group-item">
                    {product.productName}
                  </li>
                ))
              ) : (
                <li className="list-group-item">No Products Available</li>
              )}
            </ul>

            <strong>Form List</strong>
            {formList.length ? (
              formList.map((form, index) => (
                <div key={index} className="mb-3">
                  <div>{form.formDescription}</div>
                  <ul className="form-field-list list-unstyled">
                    {form.formFields.map((field, fieldIndex) => (
                      <li key={fieldIndex}>
                        {field.formFieldCode} ({field.formFieldName})
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <p>No Forms Available</p>
            )}
          </Col>
        </Row>
      </Card.Body>
    </ClayCard>
  );
};

export default Profile;
