// services/crawlService.js
import axiosInstance from './axiosInstance'; 

// Existing searchContents function
export const searchContents = async (keyword, title, page = 0, size = 10) => {
  try {
    const response = await axiosInstance.get('/news/contents/search', {
      params: {
        keyword,
        title,
        page,
        size
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching news contents:', error);
    throw error;
  }
};

// New function to publish selected news items
export const publishContents = async (ids) => {
  try {
    const response = await axiosInstance.post('/news/contents/publish', { ids });
    return response.data;
  } catch (error) {
    console.error('Error publishing news contents:', error);
    throw error;
  }
};