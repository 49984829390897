import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Body, Table } from "@clayui/core";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayDropdown from "@clayui/drop-down";
import ClayIcon from "@clayui/icon";
import { Link } from "react-router-dom";
import { listManualSubmissionByYearAndCreatedBy } from "../../../api/nebManualSubmissionService";
import { downloadFile } from "../../../api/fileSystemService";
import useAuthStore from "../../../store/useAuthStore";

function ManualSubmission() {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10;
  const availableYears = Array.from({ length: 11 }, (_, i) =>
    (startYear + i).toString()
  );

  const [manualSubmissionList, setManualSubmissionList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    availableYears[availableYears.length - 1]
  );
  const [activeYearDropdown, setActiveYearDropdown] = useState(false);
  const [sortedList, setSortedList] = useState([]);

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await listManualSubmissionByYearAndCreatedBy(selectedYear, user.id);
        setManualSubmissionList(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    setSortedList(sortListByQuarter([...manualSubmissionList]));
  }, [manualSubmissionList]);

  const handleYearSelection = (year) => {
    setSelectedYear(year);
    setActiveYearDropdown(false);
  };

  const handleDownload = async (filePath) => {
    try {
      console.log("File path: ", filePath);
      await downloadFile(filePath);
      console.log("File downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const sortListByQuarter = (list) => {
    return list.sort((a, b) => {
      return parseInt(a.quarter) - parseInt(b.quarter);
    });
  };

  return (
    <>
      <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div className="ml-0">
            <ClayCard.Description displayType="title" className="mb-0">
              <b>List of Submission</b>
            </ClayCard.Description>
          </div>

          <div className="d-flex ml-auto align-items-center">
            <span className="mr-2">Year:</span>
            <ClayDropdown
              trigger={
                <ClayButton displayType="secondary" className="mr-3">
                  {selectedYear}
                  <ClayIcon symbol="caret-bottom" className="ml-2" />
                </ClayButton>
              }
              active={activeYearDropdown}
              onActiveChange={setActiveYearDropdown}
            >
              <ClayDropdown.ItemList>
                {availableYears.map((year, index) => (
                  <ClayDropdown.Item
                    key={index}
                    onClick={() => handleYearSelection(year)}
                  >
                    {year}
                  </ClayDropdown.Item>
                ))}
              </ClayDropdown.ItemList>
            </ClayDropdown>

            <Link to="/neb-manual-submission/neb-manual-submission-upload">
              <ClayButton
                displayType="btn btn-outline-primary"
                className="mb-2 mb-md-0 mr-1"
              >
                + Upload File
              </ClayButton>
            </Link>

            {/* <Link to="/neb-manual-submission/filesystemcomponent">
              <ClayButton
                displayType="btn btn-outline-primary"
                className="mb-2 mb-md-0"
              >
                test upload file
              </ClayButton>
            </Link> */}

            <Link
              className="btn btn-link"
              onClick={() =>
                handleDownload(
                  `manualSubmission/downloadFile/Form Manual Submission.xlsx`
                )
              }
            >
              <ClayButton displayType="primary" className="mb-2 mb-md-0">
                Download Template
              </ClayButton>
            </Link>
          </div>
        </Card.Header>

        <Card.Body className="p-3" style={{ overflowX: "auto" }}>
          {sortedList.length === 0 ? (
            <p>No list file in this year.</p>
          ) : (
            <Table>
              <thead className="text-dark">
                <tr>
                  <th>Quarter</th>
                  <th>Form</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sortedList.map((submission) => (
                  <tr key={submission.id}>
                    <td>{submission.quarter}</td>
                    <td>{submission.fileName}</td>
                    <td>
                      <Link
                        className="btn btn-link"
                        onClick={() =>
                          handleDownload(
                            `${submission.filePath.replace(/\\/g, "/")}/${
                              submission.fileName
                            }`
                          )
                        }
                      >
                        Download
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </ClayCard>
    </>
  );
}

export default ManualSubmission;
