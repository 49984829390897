import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/agencyform";

// Save agency form field
export const saveAgencyFormField = async (agencyFormField) => {
    try {
        const response = await axiosInstance.put(`${endpoint}/saveformfield`, agencyFormField);
        return response.data;
    } catch (error) {
        console.error('Error saving agency form field:', error);
        throw error;
    }
};
