import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import ClayTabs from '@clayui/tabs';
import DataTrendAnalysisDP from "./charts/DataTrendAnalysisDP";
import PlantEfficiencyDP from "./charts/PlantEfficiencyDP";

const FuelBalanceChartTabsDP = ({ energySector, plantRatio, selectedYear, selectedQuarter, productList, formList, productAndFormsList, agencyId }) => {
  const [active, setActive] = useState(0);


  return (
    <>
      <ClayTabs active={active} onActiveChange={setActive}>
        <ClayTabs.List>
          <ClayTabs.Item
            innerProps={{
              "aria-controls": "tabpanel-1"
            }}
          >
            Data Trend Analysis
          </ClayTabs.Item>
          {energySector === "PS-ES" && <ClayTabs.Item
            innerProps={{
              "aria-controls": "tabpanel-2"
            }}
          >
            Plant Efficiency
          </ClayTabs.Item>
          }
        </ClayTabs.List>
        <ClayTabs.Panels>
          <ClayTabs.TabPanel aria-labelledby="tab-1">
            <DataTrendAnalysisDP selectedYear={selectedYear} selectedQuarter={selectedQuarter} productList={productList} formList={formList} productAndFormsList={productAndFormsList} agencyId={agencyId} />
          </ClayTabs.TabPanel>
          <ClayTabs.TabPanel aria-labelledby="tab-2">
            <PlantEfficiencyDP plantRatio={plantRatio} />
          </ClayTabs.TabPanel>
        </ClayTabs.Panels>
      </ClayTabs>
    </>
  );
};

export default FuelBalanceChartTabsDP;



