import axiosInstance from './axiosInstance';

const endpoint = "/neb/agency/productlist";

export const saveProductNameList = async (agencyProductNameLists) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/saveproductname`, agencyProductNameLists);
    return response.data;
  } catch (error) {
    console.error('Error saving agency product lists:', error);
    throw error;
  }
};