import React, { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import glossaryData from './glossary.json';

function Glossary() {
    const [visibleModuleGuide, setVisibleModuleGuide] = useState(true);
  
    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">
            <b className="tm-header">Glossary</b>
          </h3>
          <button
            type="button"
            onClick={() => setVisibleModuleGuide(!visibleModuleGuide)}
            className="btn btn-tool"
            title={visibleModuleGuide ? "Collapse" : "Expand"}
          >
            <i className={`fas ${visibleModuleGuide ? "fa-minus" : "fa-plus"}`} />
          </button>
        </div>
        <div
          className="card-body"
          style={{ display: visibleModuleGuide ? "block" : "none" }}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Term</th>
                <th>Definition</th>
              </tr>
            </thead>
            <tbody>
              {glossaryData.map((item, index) => (
                <tr key={index}>
                  <td>{item.term}</td>
                  <td>{item.definition}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

export default Glossary;
