import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getDataProviderById } from "../../../api/nebDataProviderService";

const DataProviderById = () => {
  const { userId } = useParams();
  const [dataProviderDetails, setDataProviderDetails] = useState(null);

  console.log("userId:", userId);

  useEffect(() => {
    const fetchDataProviderDetails = async () => {
      try {
        const response = await getDataProviderById(userId);
        console.error("Data Provider:", response);
        setDataProviderDetails(response);
      } catch (error) {
        console.error("Error fetching agency details:", error);
      }
    };

    fetchDataProviderDetails();
  }, [userId]);

  return (
    <>
        <div className="text-center p-1 mb-3 d-flex justify-content-end">
          <Link to={`/createNewDataProvider/${userId}`} className="btn btn-primary">
            Update Details
          </Link>
        </div>  
        {dataProviderDetails && (
          <div className="card" style={{boxShadow: "0px 0px 0px 0px"}}>
            <div className="card-header">
              <h4>Data Provider Summary</h4>
              <p>
                Check all the details before creating data provider's account
              </p>
            </div>
            <div className="card-body">
              <div className="row mb-2">
                <h5>
                  <strong>Data Provider's Details</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-3">
                  <strong>NAME</strong>
                  <br />
                  {dataProviderDetails.name}
                </div>
                <div className="col-sm-3">
                  <strong>EMAIL</strong>
                  <br />
                  {dataProviderDetails.email}
                </div>
                <div className="col-sm-3">
                  <strong>PHONE NUMBER</strong>
                  <br />
                  {dataProviderDetails.phoneNo}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Agency Details</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-3">
                  <strong>ENERGY SECTOR</strong>
                  <br />
                  {dataProviderDetails.agency.sectorId}
                </div>
                <div className="col-sm-3">
                  <strong>AGENCY</strong>
                  <br />
                  {dataProviderDetails.agency.agencyName}
                </div>
                <div className="col-sm-3">
                  <strong>OFFICE NUMBER</strong>
                  <br />
                  {dataProviderDetails.agency.phoneNumber}
                </div>
                <div className="col-sm-3">
                  <strong>ADDRESS</strong>
                  <br />
                  {dataProviderDetails.agency.address1}, {dataProviderDetails.agency.address2},{" "}
                  {dataProviderDetails.agency.address3}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Contact Person details</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-3">
                  <strong>NAME</strong>
                  <br />
                  {dataProviderDetails.agency.cpdName}
                </div>
                <div className="col-sm-3">
                  <strong>EMAIL</strong>
                  <br />
                  {dataProviderDetails.agency.cpdEmail}
                </div>
                <div className="col-sm-3">
                  <strong>PHONE NUMBER</strong>
                  <br />
                  {dataProviderDetails.agency.cpdPhoneNumber}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Product List</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-3">
                  {dataProviderDetails.agency.categories.map((category) =>
                    category.products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.productName}</td>
                      </tr>
                    ))
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <h5>
                  <strong>Form List</strong>
                </h5>
              </div>
              <div className="row mb-4">
                <div className="col-sm-12">
                  {dataProviderDetails.agency.forms.map((form) => (
                    <div key={form.id} className="mb-3">
                      {form.formDescription}
                      <div>
                        {form.formFields.map((formField) => (
                          <span key={formField.id} className="badge bg-primary me-1 mr-1">
                            {formField.formFieldName}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default DataProviderById;
