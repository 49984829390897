import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ClaySelect } from '@clayui/form';
import { Card, Button } from "react-bootstrap";
import { getDistinctYears, getDashboardDTAResult } from '../../../api/nebReportService';


const FormUsageFrequencyChart = ({ selectedYear }) => {
  const [selectedForm, setSelectedForm] = useState('');
  const [availableForms, setAvailableForms] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [selectedQuarter, setSelectedQuarter] = useState();
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [dtaResult, setDTAResult] = useState([]);

  const fetchDashboardDTAResult = async (yearFrom, yearTo) => {
    try {
      const result = await getDashboardDTAResult(yearFrom, yearTo);
      console.log("dtadb result: " + JSON.stringify(result.data, null, 2));
      setDTAResult(result.data);
    } catch (error) {
      console.error('Error fetching dashboard dta:', error);
    } finally {
      //setListLoading(false);
    }
  }

  const fetchDistinctYears = async () => {
    try {
      const result = await getDistinctYears();
      console.log("DISTINCT YEARS: " + JSON.stringify(result.data, null, 2));
      const sortedYears = result.data.sort((a, b) => a.localeCompare(b));
      setFromOptions(sortedYears);
      setToOptions(sortedYears);
    } catch (error) {
      console.error('Error fetching years:', error);
    } finally {
      //setListLoading(false);
    }
  }

  // Extract all form codes that start with "F"
  useEffect(() => {
    console.log("trend analysis: " + JSON.stringify(dtaResult, null, 2))
    const formsWithCategories = new Set();
    const formQuarters = new Set();

    if (dtaResult && dtaResult.length > 0) {
      dtaResult.forEach(report => {
        formQuarters.add(report.reportQuarter);
        report.forms.forEach(form => {
          if (form.formCode.startsWith('F')) {
            formsWithCategories.add(form.formCode);
          }
        });
      });
    }

    // Set available forms for the dropdown
    setAvailableForms(Array.from(formsWithCategories));
    //Set available quarters for the dropdown
    // Convert the Set to an array
    const sortedQuarters = Array.from(formQuarters).sort((a, b) => {
      // Extract the quarter number and compare them
      const quarterA = parseInt(a.slice(1)); // Get the number after "Q"
      const quarterB = parseInt(b.slice(1));

      return quarterA - quarterB; // Sort in ascending order
    });
    setQuarterOptions(sortedQuarters);
    fetchDistinctYears();
  }, [dtaResult]);

  useEffect(() => {
    fetchDashboardDTAResult(selectedFrom, selectedTo)
  }, [selectedFrom, selectedTo]);

  // Update chart data whenever the selected form changes
  useEffect(() => {
    if (!selectedForm) return;

    const formUsage = {};
    const years = new Set();

    // Extract and process data for the selected form and its categories over different years
    dtaResult.forEach(report => {
      const reportYear = report.reportPeriodYear;
      const reportQuarter = report.reportQuarter;

      report.forms.forEach(form => {
        if (form.formCode === selectedForm && reportQuarter === selectedQuarter) {
          form.categories.forEach(category => {
            const categoryCode = category.formCode;
            const categoryDescription = category.description;
            const categoryKey = `${categoryCode} (${categoryDescription})`; // Combine formCode and description

            if (!formUsage[categoryKey]) {
              formUsage[categoryKey] = {};
            }
            if (!formUsage[categoryKey][reportYear]) {
              formUsage[categoryKey][reportYear] = 0;
            }
            formUsage[categoryKey][reportYear]++;
            years.add(reportYear);
          });
        }
      });
    });

    // Prepare data for Highcharts
    const seriesData = Object.entries(formUsage).map(([categoryKey, yearData]) => {
      const data = Array.from(years).map(year => yearData[year] || 0);
      return {
        name: categoryKey, // Use the combined formCode and description as the series name
        data: data,
      };
    });


    // Sort quarters for the x-axis
    const sortedQuarters = Array.from(years).sort();

    // Set Highcharts options
    setChartOptions({
      chart: {
        type: 'area',
      },
      title: {
        text: `Fuel Balance Reports Frequency for ${selectedForm} between ${selectedFrom} and ${selectedTo} (${selectedQuarter})`,
      },
      xAxis: {
        categories: sortedQuarters,  // Quarters as x-axis categories
        title: {
          text: 'Submission Year',
        },
      },
      yAxis: {
        title: {
          text: 'Submission Frequency',
        },
      },
      series: seriesData,
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
          },
        },
      },
    });
  }, [selectedQuarter, selectedForm, dtaResult]);

  return (
    <div>
      <Card className="mb-5 p-5" style={{ boxShadow: '0px 0px 0px 0px', border: '2px solid #dcdcdc', borderRadius: '10px' }}>
        {/* Dropdown to select form */}
        <div className="d-flex justify-content-between">
          <div className="row mb-4 w-100">
            {/* Left side - 3 dropdowns */}
            <div className="col-sm-3">
              <label htmlFor="fromSelect" className="">From: </label>
              <ClaySelect
                id="fromSelect"
                value={selectedFrom}
                onChange={(e) => setSelectedFrom(e.target.value)}
              >
                <ClaySelect.Option value="" label="Select a from date" />
                {fromOptions.map((from, index) => (
                  <ClaySelect.Option disabled={from > selectedTo ? true : false} key={index} value={from} label={from} />
                ))}
              </ClaySelect>
            </div>

            <div className="col-sm-3">
              <label htmlFor="toSelect" className="">To: </label>
              <ClaySelect
                id="toSelect"
                value={selectedTo}
                onChange={(e) => setSelectedTo(e.target.value)}
              >
                <ClaySelect.Option value="" label="Select a to date" />
                {toOptions.map((to, index) => (
                  <ClaySelect.Option disabled={to < selectedFrom ? true : false} key={index} value={to} label={to} />
                ))}
              </ClaySelect>
            </div>

            <div className="col-sm-3">
              <label htmlFor="quarterSelect" className="">Quarter: </label>
              <ClaySelect
                id="quarterSelect"
                value={selectedQuarter}
                onChange={(e) => setSelectedQuarter(e.target.value)}
              >
                <ClaySelect.Option value="" label="Select a quarter" />
                {quarterOptions.map((quarter, index) => (
                  <ClaySelect.Option key={index} value={quarter} label={quarter} />
                ))}
              </ClaySelect>
            </div>

            {/* Right side - existing dropdown */}
            <div className="col-sm-3">
              <label htmlFor="formSelect" className="">Select a Form: </label>
              <ClaySelect
                id="formSelect"
                value={selectedForm}
                onChange={(e) => setSelectedForm(e.target.value)}
              >
                <ClaySelect.Option value="" label="Select a form" />
                {availableForms.map((form, index) => (
                  <ClaySelect.Option key={index} value={form} label={form} />
                ))}
              </ClaySelect>
            </div>
          </div>
        </div>
        {/* Highcharts Area Chart */}
        {selectedForm && (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        )}
      </Card>
    </div>
  );
};

export default FormUsageFrequencyChart;
