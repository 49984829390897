import axiosInstance from './axiosInstance';

export const fetchEnergyData = async (searchParams) => {
  try {
    const response = await axiosInstance.post('energydata/search', searchParams, {
      params: {
        page: searchParams.page,
        size: searchParams.size
      }
    });
    return response.data; // Assuming pagination structure with content array
  } catch (error) {
    console.error('Error fetching energy data:', error);
    throw error;
  }
};

export const fetchEnergyDataById = async (id) => {
    try {
        const response = await axiosInstance.get(`energydata/${id}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching energy data:', error);
        throw error;
      }
}

export const saveEnergyData = async (energyData) => {
    try{
        const response = await axiosInstance.put('energystats',energyData);
        return response.data;
    } catch (error) {
        console.error('Error saving energy data:', error);
        throw error;
    }
}

export const deleteEnergyData = async (id) => {
    try{
        const response = await axiosInstance.delete(`energystats/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error saving energy data:', error);
        throw error;
    }
}
