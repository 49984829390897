import axiosInstance from './axiosInstance';

const endpoint = "energydatatemplate";

// Fetch all energy data templates
export const fetchEnergyDataTemplates = async () => {
    try {
        const response = await axiosInstance.get(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error fetching energy data templates:', error);
        throw error;
    }
};

// Fetch energy data template by ID
export const fetchEnergyDataTemplateById = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching energy data template by ID:', error);
        throw error;
    }
};

// Create a new energy data template
export const createEnergyDataTemplate = async (energyDataTemplate) => {
    try {
        const response = await axiosInstance.post(endpoint, energyDataTemplate);
        return response.data;
    } catch (error) {
        console.error('Error creating energy data template:', error);
        throw error;
    }
};

// Update an existing energy data template
export const updateEnergyDataTemplate = async (id, energyDataTemplate) => {
    try {
        const response = await axiosInstance.put(`${endpoint}/${id}`, energyDataTemplate);
        return response.data;
    } catch (error) {
        console.error('Error updating energy data template:', error);
        throw error;
    }
};

// Delete an energy data template
export const deleteEnergyDataTemplate = async (id) => {
    try {
        const response = await axiosInstance.delete(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting energy data template:', error);
        throw error;
    }
};

// Fetch energy data templates by formName
export const fetchEnergyDataTemplatesByFormName = async (formName) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/formName/${formName}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching energy data templates by formName:', error);
        throw error;
    }
};

// Fetch all unique types (new endpoint for types aggregation)
export const fetchEnergyDataTypes = async () => {
    try {
        const response = await axiosInstance.get(`${endpoint}/types`);
        return response.data;
    } catch (error) {
        console.error('Error fetching energy data types:', error);
        throw error;
    }
};

// Fetch all unique categories (new endpoint for categories aggregation)
export const fetchEnergyDataCategories = async () => {
    try {
        const response = await axiosInstance.get(`${endpoint}/categories`);
        return response.data;
    } catch (error) {
        console.error('Error fetching energy data categories:', error);
        throw error;
    }
};

// Fetch energy data templates by category and type
export const fetchEnergyDataTemplateByCategoryAndType = async (category, type) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/category/${category}/type/${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching energy data template by category and type:', error);
        throw error;
    }
};