import axiosInstance from './axiosInstance';

const endpoint = "/neb/manual-submission";

export const saveManualSubmission = async (manualSubmission) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/save`, manualSubmission);
    return response.data;
  } catch (error) {
    console.error('Error saving manual submission:', error);
    throw error;
  }
};

export const listManualSubmissionByYear = async (year) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/listManualSubmissionByYear/${year}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manual submission list:', error);
    throw error;
  }
};

export const listManualSubmissionByYearAndQuarter = async (year, quater) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/listManualSubmissionByYearAndQuarter/${year}/${quater}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manual submission list:', error);
    throw error;
  }
};

export const listManualSubmissionByYearAndCreatedBy = async (year, createdBy) => {
  try {
    const url = `${endpoint}/listManualSubmissionByYearAndCreatedBy/${year}/${createdBy}`;
    
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching manual submission list:', error);
    throw error;
  }
};


export const updateFileNameByFilePath = async (fileName, filePath) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/updateFileNameByFilePath`, null, {
      params: {
        fileName,
        filePath,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating file name by file path:', error);
    throw error;
  }
};

export const checkFileExistence = async (filePath) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/checkFileExistence`, {
      params: { filePath },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking file existence:', error);
    throw error;
  }
};
