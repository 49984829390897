import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getProductById } from "../../../api/nebProductService";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ProductById = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await getProductById(id);
        setProductDetails(response);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  const calculateConversion = (units) => {
    return units
      .map((unit) => {
        const conversionValue = unit.inKtoe || "______";
        return `1 ${unit.productUnitName} = ${conversionValue} ktoe`;
      })
      .join("\n");
  };

  const onBackClick = () => {
    setTimeout(() => {
      navigate(`/neb-product/neb-product-list`);
    }, 2000);
  };

  return (
    <>
      <div className="text-center p-1 mb-3 d-flex justify-content-end">
        <Link to={`/createNewProduct/${id}`} className="btn btn-primary">
          Update Details
        </Link>
      </div>
      {productDetails && (
        <div className="card" style={{ boxShadow: "0px 0px 0px 0px" }}>
          <div className="card-header">
            <h4>Product Summary</h4>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-6">
                <strong>PRODUCT CATEGORY</strong>
                <br />
                {productDetails.nebProductCategory.productCategory}
              </div>
              <div className="col-sm-6">
                <strong>PRODUCT NAME</strong>
                <br />
                {productDetails.productName}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-6">
                <strong>UNIT</strong>
                <br />
                {productDetails.nebProductUnits
                  .map((unit) => unit.productUnitName)
                  .join(", ")}
              </div>
              <div className="col-sm-6">
                <strong>Conversion</strong>
                <br />
                <span style={{ whiteSpace: "pre-line" }}>
                  {calculateConversion(productDetails.nebProductUnits)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-end mt-3">
        <Button variant="primary" onClick={onBackClick}>
          Back
        </Button>
      </div>
    </>
  );
};

export default ProductById;
