import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const exportToPdf = async (tableId, reportInfo) => {
  const input = document.getElementById(tableId);

  // Measure the width of the table
  const tableWidth = input.offsetWidth; // Get the rendered table width in pixels

  // Set the PDF width based on the table width in mm (conversion: 1 px = 0.264583 mm)
  const pdfWidth = tableWidth * 0.264583;

  const canvas = await html2canvas(input, { scale: 1, useCORS: true });
  const imgData = canvas.toDataURL('image/png', 0.5); // Adjust quality here (0.5 = 50%)
  // Determine PDF height based on reportType
  let pdfHeight;
  if (reportInfo.type === "Installed Capacity" || reportInfo.type === "Net Calorific") {
    // Automatically fit the height of the content
    pdfHeight = canvas.height * 0.264583; // Convert pixels to mm
  } else {
    // Fixed height for other report types
    pdfHeight = 148; // Set a fixed height (adjust if needed)
  }
  const pdf = new jsPDF('l', 'mm', [pdfWidth, pdfHeight]); // Custom width based on table size and fixed height

  // Get the width and height of the canvas in pixels
  const imgWidth = canvas.width * 0.264583; // Convert pixels to mm
  const imgHeight = canvas.height * 0.264583;


  // Scale it down for smaller file size
  const scaleFactor = 0.7; // Adjust this value as needed
  const scaledWidth = imgWidth * scaleFactor;
  const scaledHeight = imgHeight * scaleFactor;

  // Add report basic info at the top
  pdf.setFontSize(12);
  pdf.setFont("helvetica", "bold");
  pdf.text(`Report Period Year: ${reportInfo.year}`, 10, 20);
  pdf.text(`Report Quarter: ${reportInfo.quarter}`, 10, 30);
  pdf.text(`Agency: ${reportInfo.agency}`, 10, 40);
  pdf.text(`Data Provider: ${reportInfo.dataProvider}`, 10, 50);
  pdf.text(`Report Status: ${reportInfo.status}`, 10, 60);
  pdf.text(`Report Type: ${reportInfo.type}`, 10, 70); // Added report type
  pdf.line(10, 75, pdfWidth - 10, 75); // Draw a line for separation

  // Add image to PDF (the table)
  pdf.addImage(imgData, 'PNG', 0, 80, scaledWidth, scaledHeight); // Adjusted y-position for the image

  // Save the PDF
  pdf.save(`${reportInfo.type}-${reportInfo.agency} (${reportInfo.year}-${reportInfo.quarter}).pdf`);
};

export default exportToPdf;