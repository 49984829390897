import React, { useState, useEffect, useRef } from "react";
import { searchContents, publishContents } from '../../api/crawlService';
import ClayManagementToolbar from '@clayui/management-toolbar';
import ClayButton from '@clayui/button';
import { ClayInput } from '@clayui/form';
import ClayModal, { useModal } from '@clayui/modal';
import Pagination from "../../components/Pagination";
import Spinner from 'react-bootstrap/Spinner';
import { Toast, ToastContainer } from 'react-bootstrap'; // Import Toast components
import { format } from 'date-fns';

const Newslisting = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [toastMessage, setToastMessage] = useState(""); // State to store toast message
    const [showToast, setShowToast] = useState(false); // State to control toast visibility
    const [toastVariant, setToastVariant] = useState("success"); // State to control toast type (success or error)

    const searchInputRef = useRef();
    const { observer, onClose } = useModal({
        onClose: () => setVisibleModal(false),
    });

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);
            try {
                const data = await searchContents(searchQuery, searchQuery, currentPage, entriesPerPage);
                setNews(data.content);
                setTotalPages(data.totalPages);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
                searchInputRef.current?.focus();
            }
        };

        if (searchQuery.length === 0 || searchQuery.length >= 3) {
            const debounceFetch = setTimeout(fetchNews, 300);

            return () => clearTimeout(debounceFetch);
        }
    }, [searchQuery, currentPage, entriesPerPage]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    const getStatusLabel = (status) => {
        if (!status) {
            return "Not Published";
        }
        return status === "P" ? "Published" : status;
    };

    const handleViewClick = (content) => {
        setSelectedContent(content);
        setVisibleModal(true);
    };

    const handleCheckboxChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    const handlePublish = async () => {
        if (selectedIds.length === 0) {
            setToastMessage("Please select at least one item to publish.");
            setToastVariant("warning");
            setShowToast(true);
            return;
        }

        try {
            setLoading(true);
            await publishContents(selectedIds);
            setToastMessage("Selected items have been published.");
            setToastVariant("success");
            setShowToast(true);
            setSelectedIds([]);
        } catch (err) {
            console.error("Error publishing contents:", err);
            setToastMessage("Failed to publish selected items.");
            setToastVariant("danger");
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <ClayManagementToolbar>
                <ClayManagementToolbar.ItemList expand>
                    <ClayManagementToolbar.Search>
                        <ClayInput.Group>
                            <ClayInput.GroupItem>
                                <ClayInput
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    ref={searchInputRef}
                                />
                            </ClayInput.GroupItem>
                        </ClayInput.Group>
                    </ClayManagementToolbar.Search>
                </ClayManagementToolbar.ItemList>

                <ClayManagementToolbar.ItemList>
                    <ClayManagementToolbar.Item>
                        <ClayButton displayType="primary" onClick={handlePublish}>
                            Publish
                        </ClayButton>
                    </ClayManagementToolbar.Item>
                </ClayManagementToolbar.ItemList>
            </ClayManagementToolbar>

            {loading ? (
                <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden"></span>
                    </Spinner>
                </div>
            ) : error ? (
                <div className="alert alert-danger">Error loading data</div>
            ) : (
                <>
                    <table className="show-quick-actions-on-hover table table-autofit table-list responsive">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="table-head-title">ID</th>
                                <th className="table-cell-expand table-head-title">Title</th>
                                <th><span className="inline-item">Status</span></th>
                                <th className="table-head-title">Content Date</th>
                                <th>Downloaded Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {news.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className="custom-control custom-checkbox">
                                            <label>
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    checked={selectedIds.includes(item.id)}
                                                    onChange={() => handleCheckboxChange(item.id)}
                                                />
                                                <span className="custom-control-label"></span>
                                            </label>
                                        </div>
                                    </td>
                                    <td>{item.id}</td>
                                    <td className="table-cell-expand">{item.title}</td>
                                    <td>{getStatusLabel(item.status)}</td>
                                    <td>{formatDate(item.articleDate)}</td>
                                    <td>{formatDate(item.createdDate)}</td>
                                    <td>
                                        <ClayButton displayType="secondary" onClick={() => handleViewClick(item)}>
                                            View
                                        </ClayButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                        onEntriesChange={setEntriesPerPage}
                        entriesPerPage={entriesPerPage}
                    />
                </>
            )}

            {visibleModal && (
                <ClayModal observer={observer} size="lg" status="info">
                    <ClayModal.Header>{selectedContent.title}</ClayModal.Header>
                    <ClayModal.Body>
                        <div dangerouslySetInnerHTML={{ __html: selectedContent.text }} />
                    </ClayModal.Body>
                    <ClayModal.Footer
                        last={<ClayButton displayType="secondary" onClick={onClose}>Close</ClayButton>}
                    />
                </ClayModal>
            )}

            {/* Toast Notification */}
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={5000} // Increase delay to 5000ms (5 seconds)
                    autohide
                >
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

        </div>
    );
};

export default Newslisting;