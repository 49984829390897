import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faCalendar,
  faPaperclip,
  faImage,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router";
import CreateEvent from "./CreateEvent";
import {
  faUserCircle, faCloudUploadAlt
} from "@fortawesome/free-solid-svg-icons";
import { createAnnouncement } from "../../../api/nebAnnouncementService";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/useAuthStore";
import IconPicker from "./IconPicker";

function PostAnnouncement({ onBadgeClick, setNewAnnPosted }) {

  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [textContent, setTextContent] = useState();
  const user = useAuthStore((state) => state.user);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [date, setDate] = useState(null);

  const handleFileChange = (info) => {
    setFileList(info.fileList);
  };

  const handleDateChange = (date, dateString) => {
    setDate(dateString);
  };

  useEffect(() => {
    if (user && user.id) {
      console.log("userrole: " + JSON.stringify(user, null, 2))
    }
  }, [user]);

  const navigate = useNavigate();

  const handleSubmit = async () => {

    const ann = {
      authorId: user.id,
      authorName: user.alternateName,
      textContent: textContent,
    };

    setSubmitLoading(true);
    try {
      const result = await createAnnouncement(ann); // Await the result of the async call
      console.log("Announcement created:", result);
      toast.success("Announcement sent success.")
      setNewAnnPosted(prevCount => prevCount + 1);
    } catch (error) {
      toast.error("Error creating announcement:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Card className="mb-5" style={{ boxShadow: '0px 0px 0px 0px', border: '2px solid #dcdcdc', borderRadius: '10px' }}>
      <Card.Body className="">
        <div>
          <Card.Title>
            <div className="d-flex align-items-center">
              {/* User Profile Icon */}
              <FontAwesomeIcon
                icon={faUserCircle}
                className="rounded-circle mr-3"
                style={{ width: "50px", height: "50px", fontSize: "50px" }}
              />
              <div>
                <h5 className="fw-bold mb-0 p-1">{user.alternateName}</h5>
                <small className="p-1 badge bg-primary" onClick={onBadgeClick}>
                  <FontAwesomeIcon color="white" icon={faUsers} />
                  <span style={{ color: 'white' }}> All</span>
                </small>
              </div>
            </div>
          </Card.Title>
          <Card.Text>
            <br />
            <textarea
              className="form-control mb-2"
              id="postAnnouncement"
              rows="3"
              style={{ backgroundColor: 'white' }}
              placeholder="Type here..."
              onChange={(e) => setTextContent(e.target.value)}
            ></textarea>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <IconPicker/>
            </div>
            <br />
            <div className="d-flex justify-content-end">
              <Button disabled={submitLoading} onClick={handleSubmit} variant="primary">
                {submitLoading ? 'Sending...' : 'Post'}
              </Button>
            </div>
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PostAnnouncement;
