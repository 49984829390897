import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import AppRoutes from './components/AppRoutes';
import "react-toastify/dist/ReactToastify.css";
// import "cleanslate/cleanslate.css";
import './App.css';

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;