import React, { useEffect, useState } from "react";
import { getSubmissionList, getDashboardDTAResult } from "../../../api/nebReportService";
import { ClaySelect } from '@clayui/form';
import GaugeChart from "./GaugeChart";
import PostAnnouncement from "./PostAnnouncement";
import PastAnnouncement from "./PastAnnouncement";
import AgencyBySector from "./AgencyBySector";
import CustomLegend from "./CustomLegend";
import FormUsageFrequencyChart from "./FormUsageFrequencyChart";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

const FormsStatus = () => {

    const [reports, setReports] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [statusCounts, setStatusCounts] = useState({});
    const [filteredData, setFilteredData] = useState();
    const [yearList, setYearList] = useState([]);
    const [q1GaugeData, setQ1GaugeData] = useState();
    const [q2GaugeData, setQ2GaugeData] = useState();
    const [q3GaugeData, setQ3GaugeData] = useState();
    const [q4GaugeData, setQ4GaugeData] = useState();
    const [totalSubmissionThatYear, setTotalSubmissionThatYear] = useState();
    const [newAnnPosted, setNewAnnPosted] = useState(0);
    const [dtaResult, setDTAResult] = useState([]);
    const [productList, setProductList] = useState([]);

    //get current year and quarter
    const date = new Date();
    const currentYear = date.getFullYear();

    //get all reports
    useEffect(() => {
        const fetchList = async () => {
            try {
                const result = await getSubmissionList();
                //filter out Draft status reports
                const noDraft = result.data.filter(report => report.reportStatus !== "Draft");
                console.log("ver result: " + JSON.stringify(noDraft, null, 2));
                setReports(noDraft);
            } catch (error) {
                console.error('Error fetching list:', error);
            } finally {
                //setListLoading(false);
            }
        };

        fetchList();
        setSelectedYear(currentYear.toString());
        console.log("selectedYear: " + currentYear + typeof (currentYear));
        //setYearList([(currentYear - 2).toString(), (currentYear - 1).toString(), (currentYear).toString()]);
        const startYear = 2010;
        const yearList = Array.from({ length: currentYear - startYear + 1 }, (_, i) => (currentYear - i).toString());

        setYearList(yearList);
    }, []);

    useEffect(() => {

        //retrieve product names
        const fieldNames = new Set();
        const formCodes = new Set();

        if (dtaResult && dtaResult.length > 0) {
            dtaResult.forEach(report => {
                report.forms.forEach(form => {
                    // Check if formCode starts with "F"
                    if (form.formCode.startsWith("F")) {
                        form.categories.forEach(category => {
                            formCodes.add(category.formCode);
                            category.products.forEach(product => {
                                fieldNames.add(product.fieldName);
                            });
                        });
                    }
                });
            });
        }

        console.log("dta formlist: " + JSON.stringify(Array.from(formCodes), null, 2))

        // Convert Set to Array and update state
        setProductList(Array.from(fieldNames));
    }, [dtaResult])


    const handleYSelectChange = (event) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        const countStatuses = () => {
            if (reports && reports.length > 0) {
                const filteredData = reports.filter(report => report.reportPeriodYear === selectedYear);
                setFilteredData(filteredData);

                const counts = filteredData.reduce((acc, report) => {
                    let statusKey;

                    switch (report.reportStatus) {
                        case 'Pending for Approval':
                            statusKey = 'pendingForApproval';
                            break;
                        case 'Pending for Amendment':
                            statusKey = 'pendingForAmendment';
                            break;
                        case 'Approved':
                            statusKey = 'approved';
                            break;
                        default:
                            return acc;
                    }

                    acc[statusKey] = (acc[statusKey] || 0) + 1;
                    return acc;
                }, {});

                setStatusCounts(counts);
            };
        }

        countStatuses();
    }, [reports, selectedYear]);

    useEffect(() => {
        const q1 = getReportsByStatusAndQuarter(filteredData, "Q1")
        setQ1GaugeData(q1);
        const q2 = getReportsByStatusAndQuarter(filteredData, "Q2")
        setQ2GaugeData(q2);
        const q3 = getReportsByStatusAndQuarter(filteredData, "Q3")
        setQ3GaugeData(q3);
        const q4 = getReportsByStatusAndQuarter(filteredData, "Q4")
        setQ4GaugeData(q4);
        console.log("statusco: " + JSON.stringify(statusCounts, null, 2))
        setTotalSubmissionThatYear(calculateSum(statusCounts));
    }, [filteredData, statusCounts]);

    const calculateSum = (data) => {
        return Object.values(data).reduce((sum, value) => sum + value, 0);
    };

    function getReportsByStatusAndQuarter(filteredData, targetQuarter) {
        if (filteredData && filteredData.length > 0) {
            const initialStatusCounts = {
                "Approved": undefined,
                "Pending for Approval": undefined,
                "Pending for Amendment": undefined,
            };

            return filteredData.reduce((acc, report) => {
                if (report.reportQuarter === targetQuarter) {
                    acc[report.reportStatus] = (acc[report.reportStatus] || 0) + 1;
                }
                return acc;
            }, initialStatusCounts);
        }
    }

    return (
        <div>
            <div className="card card-primary mt-4">
                <div className="card-header">
                    <h3 className="card-title m-0">FORM STATUS</h3>
                </div>
                <div className="card-body">
                    <div className="form-group row">
                        <label htmlFor="year" className="col-sm-3 col-lg-2 col-form-label">Year :</label>
                        <div class="col-sm-3 col-lg-4">
                            <ClaySelect id="year" value={selectedYear} onChange={handleYSelectChange}>
                                {yearList.map(year => (
                                    <option key={year} value={year} >
                                        {year}
                                    </option>
                                ))}
                            </ClaySelect>
                        </div>
                    </div>
                    
                    <div class="row mt-4">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="small-box color-palette bg-yellow">
                                <div class="inner">
                                    <h3>{statusCounts.pendingForApproval ? statusCounts.pendingForApproval : 0}</h3>
                                    <p>Pending for Approval</p>
                                </div>
                                <div class="icon">
                                    <FontAwesomeIcon icon={solidIcons['faWindowRestore']} />
                                </div>
                            </div>
                        </div>  
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="small-box color-palette bg-pink">
                                <div class="inner">
                                    <h3>{statusCounts.pendingForAmendment ? statusCounts.pendingForAmendment : 0}</h3>
                                    <p>Pending for Amendment</p>
                                </div>
                                <div class="icon">
                                    <FontAwesomeIcon icon={solidIcons['faSync']} />
                                </div>
                            </div>
                        </div>  
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="small-box color-palette bg-success">
                                <div class="inner">
                                    <h3>{statusCounts.approved ? statusCounts.approved : 0}</h3>
                                    <p>Approved</p>
                                </div>
                                <div class="icon">
                                    <FontAwesomeIcon icon={solidIcons['faCheck']} />
                                </div>
                            </div>
                        </div>  
                    </div>
                    
                    <div class="row mt-3">
                        <div className="badge badge-dark badge-color1 text-md text-wrap text-left ml-3">
                            <FontAwesomeIcon icon={solidIcons['faTags']} className="mr-2" /> Submitted Forms by Quarter
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 1"} gaugeData={q1GaugeData} />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 2"} gaugeData={q2GaugeData} />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 3"} gaugeData={q3GaugeData} />
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <GaugeChart totalSubmissionThatYear={totalSubmissionThatYear} quarter={"Quarter 4"} gaugeData={q4GaugeData} />
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="card card-primary mt-4">
                <div className="card-header">
                    <h3 className="card-title m-0">POST ANNOUNCEMENT</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <PostAnnouncement setNewAnnPosted={setNewAnnPosted} />
                        </div>
                        
                        <div className="col-12 ">
                            <Card className="mb-0 p-1" style={{ boxShadow: '0px 0px 0px 0px', border: '1px solid #dcdcdc', borderRadius: '10px' }}>
                                <PastAnnouncement newAnnPosted={newAnnPosted} />
                            </Card>
                        </div>
                    </div>
                </div>  
            </div>            
            
            <div className="card card-primary mt-4">
                <div className="card-header">
                    <h3 className="card-title m-0">TOTAL AGENCY REGISTERED BY SECTOR</h3>
                </div>
                <div className="card-body">
                    <AgencyBySector />
                </div>  
            </div>         
            
            <div className="card card-primary mt-4">
                <div className="card-header">
                    <h3 className="card-title m-0">TREND ANALYSIS</h3>
                </div>
                <div className="card-body">
                    <FormUsageFrequencyChart dtaResult={dtaResult} selectedYear={selectedYear} />
                </div>  
            </div>

            <br/>
        </div>
    );

}

export default FormsStatus;