import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Modal } from "react-bootstrap";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import ClayDropDown from "@clayui/drop-down";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import {
  checkFileExistence,
  updateFileNameByFilePath,
  saveManualSubmission,
} from "../../../api/nebManualSubmissionService";
import {
  listFiles,
  deleteFile,
  createFolder,
  uploadFile,
  deleteFolder,
} from "../../../api/fileSystemService";
import useAuthStore from "../../../store/useAuthStore";

const UploadFileManualSubmission = () => {
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState({});
  const [balanceErrors, setBalanceErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [userId, setUserId] = useState("loading");
  const [fileUploaded, setFileUploaded] = useState(false);
  const allowedSheets = [
    "Fuel Balance",
    "C1",
    "Installed Capacity",
    "Plant Performance",
    "Calorific Value",
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10;
  const availableYears = Array.from({ length: 11 }, (_, i) =>
    (startYear + i).toString()
  );
  const availableQuarter = ["1", "2", "3", "4"];
  const [selectedYear, setSelectedYear] = useState(
    availableYears[availableYears.length - 1]
  );
  const [selectedQuarter, setSelectedQuarter] = useState(
    availableQuarter[availableQuarter.length - 1]
  );
  const [activeYearDropdown, setActiveYearDropdown] = useState(false);
  const [activeQuarterDropdown, setActiveQuarterDropdown] = useState(false);
  const [files, setFiles] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showFolderExistsModal, setShowFolderExistsModal] = useState(false);

  const handleYearSelection = (year) => {
    setSelectedYear(year);
    setActiveYearDropdown(false);
  };

  const handleQuarterSelection = (quarter) => {
    setSelectedQuarter(quarter);
    setActiveQuarterDropdown(false);
  };

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function

  useEffect(() => {
    if (!user?.id) return;

    try {
      console.log("user id profile: ", user.id);
      setUserId(user.id);
    } catch (error) {
      console.error("Error fetching profile data:", error);
      logout(); // Handle token invalidation
    }
  }, [user, logout]);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const data = {};
      const errors = {};

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

        if (sheetName === "Fuel Balance") {
          jsonData.slice(3, 35).forEach((row, index) => {
            const balance = row.__EMPTY_6;

            if (balance === undefined || balance !== 0) {
              errors[sheetName] = errors[sheetName] || [];
              errors[sheetName].push({
                row: index + 4,
                balance: balance === undefined ? "Kosong" : balance,
                error: "Balance is not 0",
              });
            }
          });
        } else if (sheetName === "C1") {
          jsonData.slice(4, 31).forEach((row, index) => {
            const balance = row.__EMPTY_8;

            if (balance === undefined || balance !== 0) {
              errors[sheetName] = errors[sheetName] || [];
              errors[sheetName].push({
                row: index + 5,
                balance: balance === undefined ? "Kosong" : balance,
                error: "Balance is not 0",
              });
            }
          });
        }

        data[sheetName] = jsonData;
      });

      setExcelData(data);
      setBalanceErrors(errors);
    };

    reader.readAsBinaryString(file);

    setFiles([file]);
    setFileUploaded(true);
  };

  const handleFileRemove = () => {
    setFiles([]);
    setFileUploaded(false);
  };

  const handleSubmit = () => {
    if (Object.keys(balanceErrors).length > 0) {
      alert(
        `Errors found in the following sheets: ${Object.keys(
          balanceErrors
        ).join(", ")}. Please ensure that the BALANCE value is 0.`
      );
      return;
    }

    const invalidSheets = Object.keys(excelData).filter(
      (sheetName) => !allowedSheets.includes(sheetName)
    );
    if (invalidSheets.length > 0) {
      alert(
        `The following sheets cannot be submitted: ${invalidSheets.join(
          ", "
        )}. Please check again.`
      );
      return;
    }

    // alert("Excel berjaya dihantar!");
    setShowSaveModal(true);
  };

  const handleSaveModalClose = () => setShowSaveModal(false);

  const handleFolderExistsModalNo = () => setShowFolderExistsModal(false);

  const handleModalConfirm = async () => {
    setShowSaveModal(false);

    try {
      // Define folder path as "upload/{year}/{quarter}" and ensure slashes are correct
      const folderPath = `upload/${userId}/${selectedYear}/${selectedQuarter}`.replace(
        /\\/g,
        "/"
      );

      // 1. Create the folder
      const folderCreationResponse = await createFolder(folderPath);
      console.log("Folder created at:", folderCreationResponse.relativePath);

      // 2. Upload the file
      if (files.length > 0) {
        const uploadResponse = await uploadFile(
          folderCreationResponse.relativePath.replace(/\\/g, "/"), // Ensure slashes are correct
          files[0]
        );
        console.log("File uploaded successfully:", uploadResponse.fileName);

        // 3. Save manual submission details
        const manualSubmissionData = {
          fileName: files[0].name,
          filePath: folderCreationResponse.relativePath.replace(/\\/g, "/"),
          quarter: selectedQuarter,
          year: selectedYear,
        };

        const fileExists = await checkFileExistence(
          manualSubmissionData.filePath
        );

        let saveResponse;

        if (fileExists) {
          // File exists, update file name
          saveResponse = await updateFileNameByFilePath(
            manualSubmissionData.fileName,
            manualSubmissionData.filePath
          );
          console.log("Manual submission updated:", saveResponse);
        } else {
          // File does not exist, save new submission
          saveResponse = await saveManualSubmission(manualSubmissionData);
          console.log("Manual submission saved:", saveResponse);
        }

        alert("Upload successful!");

        navigate("/neb-manual-submission/neb-manual-submission-list");
      } else {
        console.error("No files to upload.");
      }
    } catch (error) {
      setShowFolderExistsModal(true);
      console.error("Error during file submission process:", error);
    }
  };

  const handleFolderExistsModalYes = async () => {
    setShowFolderExistsModal(false);

    try {
      // Define folder path as "upload/{year}/{quarter}" and ensure slashes are correct
      const folderPath = `upload/${selectedYear}/${selectedQuarter}`.replace(
        /\\/g,
        "/"
      );

      // 1. List files in the existing folder and delete them
      const filesInFolder = await listFiles(folderPath);
      await deleteFile(`${filesInFolder}`);
      console.log("File deleted:", filesInFolder);

      // 2. Delete the existing folder
      await deleteFolder(folderPath);
      console.log("Folder deleted:", folderPath);

      // 3. Recreate the folder
      const folderCreationResponse = await createFolder(folderPath);
      console.log("Folder created at:", folderCreationResponse.relativePath);

      // 4. Upload the new file
      if (files.length > 0) {
        const uploadResponse = await uploadFile(
          folderCreationResponse.relativePath.replace(/\\/g, "/"), // Ensure slashes are correct
          files[0]
        );
        console.log("File uploaded successfully:", uploadResponse.fileName);

        // 5. Save manual submission details
        const manualSubmissionData = {
          fileName: files[0].name,
          filePath: folderCreationResponse.relativePath.replace(/\\/g, "/"),
          quarter: selectedQuarter,
          year: selectedYear,
        };

        // 6. Check if file exists in the database
        const fileExists = await checkFileExistence(
          manualSubmissionData.filePath
        );

        let saveResponse;

        if (fileExists) {
          // File exists, update file name
          saveResponse = await updateFileNameByFilePath(
            manualSubmissionData.fileName,
            manualSubmissionData.filePath
          );
          console.log("Manual submission updated:", saveResponse);
        } else {
          // File does not exist, save new submission
          saveResponse = await saveManualSubmission(manualSubmissionData);
          console.log("Manual submission saved:", saveResponse);
        }

        alert("Upload successful!");

        navigate("/neb-manual-submission/neb-manual-submission-list");
      } else {
        console.error("No files to upload.");
      }
    } catch (error) {
      console.error("Error handling existing folder:", error);
    }
  };

  return (
    <>
      <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div className="d-flex ml-auto align-items-center">
            <span className="mr-2">Year:</span>
            <ClayDropDown
              trigger={
                <ClayButton displayType="secondary" className="mr-3">
                  {selectedYear}
                  <ClayIcon symbol="caret-bottom" className="ml-2" />
                </ClayButton>
              }
              active={activeYearDropdown}
              onActiveChange={setActiveYearDropdown}
            >
              <ClayDropDown.ItemList>
                {availableYears.map((year, index) => (
                  <ClayDropDown.Item
                    key={index}
                    onClick={() => handleYearSelection(year)}
                  >
                    {year}
                  </ClayDropDown.Item>
                ))}
              </ClayDropDown.ItemList>
            </ClayDropDown>

            <span className="mr-2">Quarter:</span>
            <ClayDropDown
              trigger={
                <ClayButton displayType="secondary" className="mr-3">
                  {selectedQuarter}
                  <ClayIcon symbol="caret-bottom" className="ml-2" />
                </ClayButton>
              }
              active={activeQuarterDropdown}
              onActiveChange={setActiveQuarterDropdown}
            >
              <ClayDropDown.ItemList>
                {availableQuarter.map((quarter, index) => (
                  <ClayDropDown.Item
                    key={index}
                    onClick={() => handleQuarterSelection(quarter)}
                  >
                    {quarter}
                  </ClayDropDown.Item>
                ))}
              </ClayDropDown.ItemList>
            </ClayDropDown>
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <Dropzone onDrop={handleDrop} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    border: "2px dashed #007bff",
                    borderRadius: "10px",
                    padding: "40px",
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  <p>Drag and drop files here or</p>
                  <Button variant="primary">Browse File</Button>
                </div>
              )}
            </Dropzone>

            <div className="mt-3">
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    {file.name} - {file.size} bytes{" "}
                    <Button
                      variant="link"
                      onClick={() => handleFileRemove(file.name)}
                    >
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            </div>

            {Object.keys(balanceErrors).length > 0 && (
              <p style={{ color: "red" }}>
                {Object.entries(balanceErrors).map(([sheet, errors]) => (
                  <div key={sheet}>
                    {`Errors found in the  ${sheet} sheet:`}
                    <ul>
                      {errors.map((error, index) => (
                        <li key={index}>
                          Row {error.row} (Balance: {error.balance}) -{" "}
                          {error.error}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </p>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Button variant="secondary" className="mr-2">
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!fileUploaded} // Disable button jika tiada fail dimuat naik
            >
              Submit
            </Button>
          </div>
        </Card.Body>
      </ClayCard>

      <Modal show={showSaveModal} onHide={handleSaveModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to submit the files?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSaveModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFolderExistsModal} onHide={handleFolderExistsModalNo}>
        <Modal.Header closeButton>
          <Modal.Title>Q{selectedQuarter} Already Exists</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          File Q{selectedQuarter} already exists for year {selectedYear}. Do you
          want to replace this file?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleFolderExistsModalNo}>
            No
          </Button>
          <Button variant="success" onClick={handleFolderExistsModalYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadFileManualSubmission;
