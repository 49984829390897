import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ClayMultiStepNav from '@clayui/multi-step-nav';
import ClayCard from '@clayui/card';
import AgencyInformation from "./AgencyInformation";
import ProductList from "./ProductList";
import AgencyForm from "./AgencyForm";
import AgencySummary from "./AgencySummary";

const AgencyWizardForm = () => {
  const { agencyId: paramAgencyId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [agencyId, setAgencyId] = useState(paramAgencyId || null);
  const [showSummary, setShowSummary] = useState(false);
  const [stepComplete, setStepComplete] = useState([false, false, false, false]); // Track each step completion status

  useEffect(() => {
    if (paramAgencyId) {
      setAgencyId(paramAgencyId);
    }
  }, [paramAgencyId]);

  const handleSave = (id, stepIndex) => {
    setAgencyId(id);
    setStepComplete((prevState) => {
      const updatedState = [...prevState];
      updatedState[stepIndex] = true; // Mark current step as complete
      return updatedState;
    });
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    setShowSummary(false);
  };

  const handleStepClick = (i) => {
    if (stepComplete[i] || i <= currentStep) {
      setCurrentStep(i); // Allow navigation if step is completed or going back to previous step
    }
  };

  const steps = [
    {
      subTitle: "Step 1",
      title: "Create Agency",
      content: <AgencyInformation handleSave={(id) => handleSave(id, 0)} agencyId={agencyId} />,
    },
    {
      subTitle: "Step 2",
      title: "Product List",
      content: agencyId && (
        <ProductList
          agencyId={agencyId}
          handleSave={(id) => handleSave(id, 1)}
          handleBack={handleBack}
        />
      ),
    },
    {
      subTitle: "Step 3",
      title: "Form List",
      content: agencyId && (
        <AgencyForm
          agencyId={agencyId}
          handleSave={(id) => handleSave(id, 2)}
          handleBack={handleBack}
          setShowSummary={setShowSummary}
        />
      ),
    },
    {
      subTitle: "Step 4",
      title: "Summary",
      content: agencyId && showSummary && (
        <AgencySummary
          agencyId={agencyId}
          handleSave={(id) => handleSave(id, 3)}
          handleBack={handleBack}
          setShowSummary={setShowSummary}
        />
      ),
    },
  ];

  return (
    <>
      <ClayCard.Description displayType="title">
        {agencyId ? "Edit Agency" : "Create New Agency"}
      </ClayCard.Description>
      <ClayCard>
        <ClayCard.Body>
          <ClayMultiStepNav>
            {steps.map(({ subTitle, title }, i) => {
              const complete = stepComplete[i];
              return (
                <ClayMultiStepNav.Item
                  active={currentStep === i}
                  expand={i + 1 !== steps.length}
                  key={i}
                  state={complete ? "complete" : undefined}
                >
                  <ClayMultiStepNav.Title>{title}</ClayMultiStepNav.Title>
                  <ClayMultiStepNav.Divider />
                  <ClayMultiStepNav.Indicator
                    complete={complete}
                    label={1 + i}
                    onClick={() => handleStepClick(i)}
                    subTitle={subTitle}
                  />
                </ClayMultiStepNav.Item>
              );
            })}
          </ClayMultiStepNav>

          <div className="mt-4">
            {steps[currentStep]?.content}
          </div>
        </ClayCard.Body>
      </ClayCard>
    </>
  );
};

export default AgencyWizardForm;
