import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClayButton from "@clayui/button";
import { downloadFile } from "../../../api/fileSystemService";
import useAuthStore from "../../../store/useAuthStore";
import '@fortawesome/fontawesome-free/css/all.min.css';

function ModuleGuide() {
  const [visibleModuleGuide, setVisibleModuleGuide] = useState(true);
  const [userAcc, setUserAcc] = useState();
  const [userRole, setUserRole] = useState("");
  const [menuItems, setMenuItems] = useState();

  const user = useAuthStore((state) => state.user);

  const handleDownload = async (filePath) => {
    try {
      console.log("File path: ", filePath);
      await downloadFile(filePath);
      console.log("File downloaded successfully");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    const fetchUserAccount = async () => {
      try {
        const uac = user;
        if (uac) {
          console.log("uac: " + JSON.stringify(uac, null, 2));
          setUserAcc(uac);
        }
      } catch (error) {
        console.error("Error fetching user account:", error);
      }
    };

    fetchUserAccount();
  }, [user]);

  useEffect(() => {
    if (userAcc && userAcc.roleBriefs) {
      const checkUserRole = () => {
        const nebAdmin = userAcc.roleBriefs.find(
          (role) => role.id === 987264 && role.name === "NEB Admin"
        );
        const nebDataProvider = userAcc.roleBriefs.find(
          (role) => role.id === 907344 && role.name === "NEB Data Provider"
        );

        if (nebAdmin && !nebDataProvider) {
          setUserRole("nebadmin");
        } else if (nebDataProvider && !nebAdmin) {
          setUserRole("nebdp");
        } else if (nebAdmin && nebDataProvider) {
          setUserRole("dev");
        }
      };
      checkUserRole();
    }
  }, [userAcc]);

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title">
          <b className="tm-header">
            {userRole === "nebadmin"
              ? "User Manual on How to Create New Agency and Data Provider"
              : "User Manual on How to Submit New Data Submission"}
          </b>
        </h3>
        <button
          type="button"
          onClick={() => setVisibleModuleGuide(!visibleModuleGuide)}
          className="btn btn-tool"
          title={visibleModuleGuide ? "Collapse" : "Expand"}
        >
          <i className={`fas ${visibleModuleGuide ? "fa-minus" : "fa-plus"}`} />
        </button>
      </div>
      <div
        className="card-body"
        style={{ display: visibleModuleGuide ? "block" : "none" }}
      >
        Click
        <Link
          className="btn btn-link"
          onClick={() =>
            handleDownload(
              userRole === "nebadmin"
                ? `admin/trainingModule/moduleGuide/User Manual Admin.pdf`
                : `dataProvider/trainingModule/moduleGuide/User manual_Data Provider.pdf`
            )
          }
        >
          <ClayButton displayType="primary" className="mb-2 mb-md-0">
            Download File
          </ClayButton>
        </Link>
        button to read more about the user manual.
      </div>
    </div>
  );
}

export default ModuleGuide;
