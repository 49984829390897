import React, { useEffect, useRef, useState } from "react";
import profilePlaceholder from "./profile-svgrepo-com.svg";
import useAuthStore from "../../../store/useAuthStore";
import { getAgencyByLiferayId } from "../../../api/nebAgencyService";
import {
  getDataProviderByAgencyId,
  updateColumnDataProviderById,
} from "../../../api/nebDataProviderService";
import {
  fetchUserProfileImage,
  uploadUserImage,
} from "../../../api/userService";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import ClayCard from "@clayui/card";
import { Link } from "react-router-dom";
import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";
import { toast, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updatePassword } from "../../../api/userService";

const ProfileEdit = () => {
  const [profileData, setProfileData] = useState({
    name: "loading",
    role: "loading",
    designation: "loading",
    agency: "loading",
    email: "loading",
    phoneNumber: "loading",
    contactPerson: {
      name: "loading",
      email: "loading",
      phoneNumber: "loading",
    },
    productList: [],
    formList: [],
    profileImageUrl: profilePlaceholder, // Default placeholder image
  });

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const user = useAuthStore((state) => state.user); // Zustand store for user data
  const logout = useAuthStore((state) => state.logout); // Zustand logout function
  const fileInputRef = useRef(null); // Ref for the hidden file input

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user?.id) return;

      try {
        console.log("user id profile: ", user.id);
        const agency = await getAgencyByLiferayId(user.id);
        const dp = await getDataProviderByAgencyId(agency.id);

        // Fetch the profile image as a base64-encoded string
        const profileImageBase64 = await fetchUserProfileImage();

        setProfileData({
          name: dp.name,
          role: "Data Provider", // Example role
          designation: "Agency Representative", // Example designation
          agency: agency.agencyName,
          email: dp.email,
          phoneNumber: dp.phoneNo,
          contactPerson: {
            name: dp.agency.cpdName,
            email: dp.agency.cpdEmail,
            phoneNumber: dp.agency.cpdPhoneNumber,
          },
          productList: extractProductList(dp),
          formList: extractFormFields(dp),
          profileImageUrl: profileImageBase64 || profilePlaceholder, // Set base64 URL for image
        });
        setPhoneNumber(dp.phoneNo);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        logout(); // Handle token invalidation
      }
    };

    if (user?.id) {
      fetchProfileData();
    }
  }, [user, logout]);

  // Trigger file input click
  const handleProfileImageClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input when the image is clicked
  };

  const handleCancel = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast.success(`The process have been cancel`);
      setTimeout(() => {
        navigate(`/neb-profile/view`);
      }, 2000);
    }
  };

  const handlePhoneChange = (e) => {
    setProfileData({
      ...profileData,
      phoneNumber: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      if (!user?.id) return;

      const agency = await getAgencyByLiferayId(user.id);
      const dp = await getDataProviderByAgencyId(agency.id);

      // Update phone number if changed
      if (phoneNumber !== existingPhoneNumber) {
        await updateColumnDataProviderById(dp.userId, { phoneNo: phoneNumber });
      }

      // update password
      if (password === confirmPassword) {
        const passwordData = {
          password: password,
          currentPassword: currentPassword,
        };

        const response = await updatePassword(passwordData);
        console.log("Password updated successfully:", response);
      } else {
        toast.error("Passwords do not match!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      toast.success(`Edit Profile success!`);
      setTimeout(() => {
        navigate(`/neb-profile/view`);
      }, 2000);
    } catch (error) {
      toast.error(`Failed to edit profile.`);
    }
  };

  // Check if the passwords match while typing
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };

  const handleUpdateRequest = () => {
    const confirmation = window.confirm(
      "Are you sure you want to proceed with the update? You will be redirected to the Report & Resolve Center."
    );
    if (confirmation) {
      toast.success(`You have been redirected to the Report & Resolve Center.`);
      setTimeout(() => {
        navigate(`/neb-data-provider-ticket/new`);
      }, 2000);
    }
  };

  // Handle file selection and upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        // Upload the image
        const response = await uploadUserImage(formData);
        if (response.status == 200) {
          const updatedProfileImageBase64 = await fetchUserProfileImage(); // Fetch the updated profile image
          setProfileData((prevState) => ({
            ...prevState,
            profileImageUrl: updatedProfileImageBase64 || profilePlaceholder,
          }));
        }
      } catch (error) {
        console.error("Error uploading profile image:", error);
      }
    }
  };

  const extractProductList = (data) => {
    return data.agency.categories.flatMap((category) =>
      category.products.map((product) => ({
        productName: product.productName,
      }))
    );
  };

  const extractFormFields = (data) => {
    if (!data?.agency?.forms) return [];

    const formsList = data.agency.forms
      .map((form) => {
        const filteredFields = form.formFields.filter(
          (field) => !field.formFieldCode.includes("-NA")
        );
        if (filteredFields.length === 0) return null;

        return {
          formType: form.formType,
          formDescription: form.formDescription,
          formFields: filteredFields.map((field) => ({
            formFieldCode: field.formFieldCode,
            formFieldName: field.formFieldName,
          })),
        };
      })
      .filter(Boolean);

    if (data.agency.installedCapacity) {
      formsList.push({ formDescription: "Installed Capacity", formFields: [] });
    }
    if (data.agency.powerPlantPerformance) {
      formsList.push({
        formDescription: "Power Plant Performance",
        formFields: [],
      });
    }
    if (data.agency.netCalorificValue) {
      formsList.push({
        formDescription: "Net Calorific Value",
        formFields: [],
      });
    }

    return formsList;
  };

  const {
    name,
    role,
    designation,
    agency,
    email,
    phoneNumber: existingPhoneNumber,
    contactPerson,
    productList,
    formList,
    profileImageUrl,
  } = profileData;

  return (
    <ClayCard className="p-4">
      <ClayCard.Body>
        <ClayLayout.Row className="justify-content-center mb-4">
          {/* Profile Image */}
          <ClayLayout.Col size={12} className="text-center">
            <img
              src={profileImageUrl}
              alt="Profile"
              className="profile-image mb-3"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileImageClick}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
            <h3 className="profile-name">{name}</h3>
            <p className="text-muted mb-1">{role}</p>
            <p className="text-muted">{designation}</p>
          </ClayLayout.Col>
        </ClayLayout.Row>

        {/* Product & Form Section */}
        <ClayLayout.Row className="mb-4">
          <ClayLayout.Col size={12}>
            <h5>Product & Form</h5>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <strong>Product</strong>
              <ClayButton displayType="link" onClick={handleUpdateRequest}>
                <i className="bi bi-pencil-square"></i> Request to Update
              </ClayButton>
            </div>
            <div>
              <ul className="list-group list-group-flush">
                {productList.length ? (
                  productList.map((product, index) => (
                    <li key={index} className="list-group-item">
                      {product.productName}
                    </li>
                  ))
                ) : (
                  <li className="list-group-item">No Products Available</li>
                )}
              </ul>
            </div>

            <strong className="mt-3 d-block">Form</strong>
            {formList.length ? (
              formList.map((form, index) => (
                <div key={index} className="mb-3">
                  <div>{form.formDescription}</div>
                  <ul className="form-field-list list-unstyled">
                    {form.formFields.map((field, fieldIndex) => (
                      <li key={fieldIndex}>
                        {field.formFieldCode} ({field.formFieldName})
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <p>No Forms Available</p>
            )}
          </ClayLayout.Col>
        </ClayLayout.Row>

        {/* Change Password Section */}
        <ClayLayout.Row className="mb-4">
          <ClayLayout.Col size={12}>
            <h5>Change Password</h5>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="currentPassword">
                  Current Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  autoComplete="off"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="newPassword">New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="confirmPassword">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  isInvalid={!passwordMatch}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  id="phoneNumber"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>
            </Form>
          </ClayLayout.Col>
        </ClayLayout.Row>

        {/* Save & Cancel Buttons */}
        <ClayLayout.Row>
          <ClayLayout.Col className="d-flex justify-content-end">
            <ClayButton
              displayType="secondary"
              className="mr-2"
              onClick={handleCancel}
            >
              Cancel
            </ClayButton>
            <ClayButton
              displayType="primary"
              onClick={handleSave}
              disabled={!passwordMatch}
            >
              Save
            </ClayButton>
          </ClayLayout.Col>
        </ClayLayout.Row>
      </ClayCard.Body>
    </ClayCard>
  );
};

export default ProfileEdit;
