import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faEnvelope, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'; // Import the icons you need

const iconMap = {
  home: faHome,
  about: faInfoCircle,
  contact: faEnvelope,
  projects: faProjectDiagram,
  // Add other icon mappings as needed
};

const Breadcrumb = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li
            key={item.href || item.label}
            className={`breadcrumb-item${item.active ? ' active' : ''}`}
            aria-current={item.active ? 'page' : undefined}
          >
            {item.active ? (
              item.label
            ) : (
              <Link to={item.href}>
                {item.icon && <FontAwesomeIcon icon={iconMap[item.icon]} />} {item.label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;