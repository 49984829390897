import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { ClaySelect } from '@clayui/form';
import HighchartsMore from 'highcharts/highcharts-more'; // Import additional modules if needed
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'; // Import additional modules 

const PlantEfficiencyDP = ({ plantRatio }) => {

  HighchartsMore(Highcharts);
  HighchartsSolidGauge(Highcharts);

  // Highcharts options
  const options = {
    chart: {
      type: 'gauge',
      backgroundColor: 'transparent', // Set the background to transparent
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '40%'
    },

    title: {
      text: null
    },

    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', '75%'],
      size: '100%'
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 100,
      //tickPixelInterval: 10,
      tickPosition: 'inside',
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: '14px'
        }
      },
      lineWidth: 0,
      plotBands: [{
        from: 20,
        to: 50,
        color: '#55BF3B', // green
        thickness: 20,
        borderRadius: '50%'
      }, {
        from: 0,
        to: 19,
        color: '#DF5353', // red
        thickness: 20,
        borderRadius: '50%'
      }, {
        from: 51,
        to: 100,
        color: '#DF5353', // red
        borderRadius: '50%',
        thickness: 20
      }]
    },

    series: [{
      name: 'Ratio',
      data: [plantRatio],
      tooltip: {
        valueSuffix: ' %'
      },
      dataLabels: {
        format: plantRatio < 0 || plantRatio > 100 ? '-' : '{y}%',
        borderWidth: 0,
        color: (
          Highcharts.defaultOptions.title &&
          Highcharts.defaultOptions.title.style &&
          Highcharts.defaultOptions.title.style.color
        ) || '#333333',
        style: {
          fontSize: '30px'
        }
      },
      dial: {
        radius: plantRatio < 0 || plantRatio > 100 ? '0%' : '80%',
        backgroundColor: 'gray',
        baseWidth: 9,
        baseLength: '0%',
        rearLength: '0%'
      },
      pivot: {
        //backgroundColor: 'gray',
        radius: 10
      }

    }],
    credits: {
      enabled: false
    }

  };

  useEffect(() => {
    console.log("ratio: " + plantRatio)
    const chart = Highcharts.charts[0];
    if (chart && !chart.renderer.forExport && chart.series.length > 0 && plantRatio) {
      const point = chart.series[0].points[0];
      point.update(plantRatio); // Update gauge with new plantRatio value
    }
  }, [plantRatio]);


  return (  
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div style={{textAlign: 'center'}}>{<i style={{color: '#9a9a9a'}}>The gauge meter represents the ratio between 'F2-Production Supply / Electricity Generated' and 'F3-Conversion'. <br/> Ensure the product value and unit is inserted under its appropriate form. </i>}</div>
    </div>
  );
};

export default PlantEfficiencyDP;
