import Dashboard from "../containers/Dashboard";
import AgencyWizardForm from "../views/neb/agency/agency-form/AgencyWizardForm";
import AgencyList from "../views/neb/agency/AgencyList";
import AgencyById from "../views/neb/agency/AgencyById";
import DataSubmissionListDP from "../views/neb/data-submission/DataSubmissionListDP";
import SubmitNewReportDP from "../views/neb/data-submission/SubmitNewReportDP";
import DataProviderList from "../views/neb/dataprovider/DataProviderList";
import CreateNewDataProvider from "../views/neb/dataprovider/CreateNewDataProvider";
import DataProviderById from "../views/neb/dataprovider/DataProviderById";
import ProductList from "../views/neb/product/ProductList";
import VerificationList from "../views/neb/verification-module/VerificationList";
import ViewReport from "../views/neb/verification-module/ViewReport";
import Newslisting from "../views/news/Newslisting";
import DashboardTicket from "../views/ticket/DashboardTicket";
import IssueListing from "../views/ticket/IssueListing";
import TicketForm from "../views/ticket/TicketForm";
import CreateNewProduct from "../views/neb/product/CreateNewProduct";
import ProductById from "../views/neb/product/ProductById";
import Profile from "../views/neb/user-profile/Profile";
import ProfileEdit from "../views/neb/user-profile/ProfileEdit";
import TicketResolutionCentre from "../views/neb/data-provider-ticketing/TicketResolutionCentre";
import TicketFormDP from "../views/neb/data-provider-ticketing/TicketFormDP";
import TrainingModule from "../views/neb/training-module/TrainingModule";
import FAQ from "../views/neb/faq/FAQ";
import ManualSubmission from "../views/neb/manual-submission/ManualSubmission";
import UploadFileManualSubmission from "../views/neb/manual-submission/UploadFileManualSubmission";
import FilesystemComponent from "../views/neb/manual-submission/FileSystemComponent";
import TicketFormView from "../views/neb/data-provider-ticketing/TicketFormView";
import EnergyDataFormList from "../views/stats/EnergyDataFormList";
import ExcelBalanceChecker from "../views/neb/manual-submission/ExcelBalanceChecker";
import ManualSubmissionAdmin from "../views/neb/manual-submission/ManualSubmissionAdmin";

const menuItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    iconlabel: "faDashboard",
    href: "/",
    roles: ["ADMINISTRATOR", "USER", "NEB_DATA_PROVIDER"],
    element: <Dashboard />,
    showInMenu: true, // Visible in the menu
  },
  {
    id: "energystats",
    label: "Energy Statistics",
    iconLabel: "faBolt",
    roles: ["ADMINISTRATOR"],
    items: [
      {
        id: "energystatslist",
        label: "Energy Statistics List",
        iconlabel: "faCaretRight",
        roles: ["ADMINISTRATOR"],
        element: <EnergyDataFormList />,
        href: "/energystats/list",
      },
    ],
  },
  {
    id: "portal",
    label: "Portal Management",
    iconlabel: "faCalendarAlt",
    roles: ["ADMINISTRATOR"],
    showInMenu: true, // Visible in the menu
    items: [
      {
        id: "portal-news-highlight",
        label: "News & Highlights",
        iconlabel: "faCaretRight",
        href: "/portal/news-listing",
        roles: ["ADMINISTRATOR"],
        element: <Newslisting />,
        showInMenu: true, // Visible in the menu
      },
    ],
  },
  {
    id: "neb",
    label: "Data Provider & Product Management",
    iconlabel: "faFire",
    roles: ["NEB_ADMIN"],
    showInMenu: true, // Visible in the menu
    items: [
      {
        id: "neb-agency",
        label: "Agency",
        iconlabel: "faCaretRight",
        roles: ["NEB_ADMIN"],
        showInMenu: true, // Visible in the menu
        items: [
          {
            id: "neb-agency-list",
            label: "Agency List",
            iconlabel: "faCaretRight",
            href: "/neb-agency",
            roles: ["NEB_ADMIN"],
            element: <AgencyList />,
            showInMenu: true, // Visible in the menu
          },
          {
            id: "neb-agency-form-wizard",
            label: "Create New Agency",
            iconlabel: "faCaretRight",
            roles: ["NEB_ADMIN"],
            href: "/neb-agency/neb-agency-form-wizard",
            element: <AgencyWizardForm />,
            showInMenu: false,
          },
          {
            id: "neb-agency-form-wizard",
            label: "Edit Agency",
            iconlabel: "faCaretRight",
            roles: ["NEB_ADMIN"],
            href: "/neb-agency/neb-agency-form-wizard/:agencyId",
            element: <AgencyWizardForm />,
            showInMenu: false,
          },
          {
            id: "neb-agency-view",
            label: "View Agency",
            iconlabel: "faCaretRight",
            href: `/agencyById/:id`,
            roles: ["NEB_ADMIN"],
            element: <AgencyById />,
            showInMenu: false,
          },
        ],
      },
      {
        id: "neb-data-provider",
        label: "Data Provider",
        iconlabel: "faCaretRight",
        roles: ["NEB_ADMIN"],
        showInMenu: true,
        items: [
          {
            id: "neb-data-provider-list",
            label: "Data Provider List",
            iconlabel: "faCaretRight",
            href: "/neb-data-provider/neb-data-provider-list",
            roles: ["NEB_ADMIN"],
            element: <DataProviderList />,
            showInMenu: true,
          },
          {
            id: "neb-data-provider-new",
            label: "Create New Data Provider",
            iconlabel: "faCaretRight",
            href: "/createNewDataProvider",
            roles: ["NEB_ADMIN"],
            element: <CreateNewDataProvider />,
            showInMenu: false,
          },
          {
            id: "neb-data-provider-view",
            label: "View Data Provider",
            iconlabel: "faCaretRight",
            href: `/dataProviderById/:userId`,
            roles: ["NEB_ADMIN"],
            element: <DataProviderById />,
            showInMenu: false,
          },
          {
            id: "neb-data-provider-update",
            label: "Update Data Provider",
            iconlabel: "faCaretRight",
            href: `/createNewDataProvider/:id`,
            roles: ["NEB_ADMIN"],
            element: <CreateNewDataProvider />,
            showInMenu: false,
          },
        ],
      },
      {
        id: "neb-product",
        label: "Product",
        iconlabel: "faCaretRight",
        roles: ["NEB_ADMIN"],
        showInMenu: true,
        items: [
          {
            id: "neb-product-list",
            label: "Product List",
            iconlabel: "faCaretRight",
            href: "/neb-product/neb-product-list",
            roles: ["NEB_ADMIN"],
            element: <ProductList />,
            showInMenu: true,
          },
          {
            id: "neb-product-new",
            label: "Create New Product",
            iconlabel: "faCaretRight",
            href: "/createNewProduct",
            roles: ["NEB_ADMIN"],
            element: <CreateNewProduct />,
            showInMenu: false,
          },
          {
            id: "neb-product-view",
            label: "View a Product",
            iconlabel: "faCaretRight",
            href: "/productById/:id",
            roles: ["NEB_ADMIN"],
            element: <ProductById />,
            showInMenu: false,
          },
          {
            id: "neb-product-update",
            label: "Update a Product",
            iconlabel: "faCaretRight",
            href: "/createNewProduct/:id",
            roles: ["NEB_ADMIN"],
            element: <CreateNewProduct />,
            showInMenu: false,
          },
        ],
      },
    ],
  },
  {
    id: "neb-verification",
    label: "NEB Verification Module",
    iconlabel: "faClipboardCheck",
    roles: ["NEB_ADMIN"],
    showInMenu: true, // Visible in the menu
    items: [
      {
        id: "neb-verification-list",
        label: "Verification List",
        iconlabel: "faCaretRight",
        href: "/neb-verification/neb-verification-list",
        roles: ["NEB_ADMIN"],
        element: <VerificationList />,
        showInMenu: true, // Visible in the menu
      },
      {
        id: "neb-manual-submission-list",
        label: "Manual Submission List",
        iconlabel: "faCaretRight",
        href: "/neb-verification/neb-manual-Submission-list",
        roles: ["NEB_ADMIN"],
        element: <ManualSubmissionAdmin />,
        showInMenu: true, // Visible in the menu
      },
      {
        id: "neb-verification-view",
        label: "View Report",
        iconlabel: "faCaretRight",
        href: "/neb-verification/neb-verification-view/:reportId/:agencyId/:agencyName/:paramQuarter/:paramYear/:reportStatus",
        roles: ["NEB_ADMIN"],
        element: <ViewReport />,
        showInMenu: false,
      },
    ],
  },
  {
    id: "ticket",
    label: "Ticket Resolution Center",
    iconlabel: "faClipboardCheck",
    roles: ["ADMINISTRATOR", "NEB_ADMIN"],
    showInMenu: true, // Visible in the menu
    items: [
      {
        id: "ticket-issue-listing",
        label: "Issues Listing",
        iconlabel: "faCaretRight",
        href: "/ticket/issues-listing",
        roles: ["ADMINISTRATOR", "NEB_ADMIN"],
        element: <TicketResolutionCentre />,
        // element: <IssueListing />,
        showInMenu: true, // Visible in the menu
      },
      {
        id: "ticket-ticket-form",
        label: "Ticket Form",
        iconlabel: "faCaretRight",
        href: "/ticket/ticket-form/:ticketId",
        roles: ["ADMINISTRATOR", "NEB_ADMIN"],
        element: <TicketFormView />,
        // element: <TicketForm />,
        showInMenu: false, // Hidden from the menu
      },
    ],
  },
  {
    id: "neb-data-submission",
    label: "Data Submission",
    roles: ["NEB_DATA_PROVIDER"],
    iconlabel: "faPaperPlane",
    showInMenu: true, // Visible in the menu
    items: [
      {
        id: "neb-data-submission-list",
        label: "Submission List",
        iconlabel: "faCaretRight",
        href: "/neb-data-submission/neb-data-submission-list",
        roles: ["NEB_DATA_PROVIDER"],
        element: <DataSubmissionListDP />,
        showInMenu: true, // Visible in the menu
      },
      {
        id: "neb-data-submission-new",
        label: "Submit New Report",
        iconlabel: "faCaretRight",
        href: "/neb-data-submission/neb-data-submission-new",
        roles: ["NEB_DATA_PROVIDER"],
        element: <SubmitNewReportDP />,
        showInMenu: false, // Visible in the menu
      },
      {
        id: "neb-data-submission-view",
        label: "View Report",
        iconlabel: "faCaretRight",
        href: "/neb-data-submission/neb-data-submission-view/:viewMode/:reportId/:paramsAgencyName/:paramsQuarter/:paramsYear/:paramsReportStatus",
        roles: ["NEB_DATA_PROVIDER"],
        element: <SubmitNewReportDP />,
        showInMenu: false,
      },
    ],
  },
  {
    id: "neb-manual-submission",
    label: "Manual Submission",
    roles: ["NEB_DATA_PROVIDER"],
    iconlabel: "faPaperPlane",
    showInMenu: true,
    items: [
      {
        id: "neb-manual-submission-list",
        label: "Submission List",
        iconlabel: "faCaretRight",
        href: "/neb-manual-submission/neb-manual-submission-list",
        roles: ["NEB_DATA_PROVIDER"],
        element: <ManualSubmission />,
        showInMenu: true,
      },
      {
        id: "neb-manual-submission-upload",
        label: "Submit New Report",
        iconlabel: "faCaretRight",
        href: "/neb-manual-submission/neb-manual-submission-upload",
        roles: ["NEB_DATA_PROVIDER"],
        element: <UploadFileManualSubmission />,
        showInMenu: false,
      },
      {
        id: "filesystemcomponent",
        label: "Submit New Report",
        iconlabel: "faCaretRight",
        href: "/neb-manual-submission/filesystemcomponent",
        roles: ["NEB_DATA_PROVIDER"],
        element: <FilesystemComponent />,
        showInMenu: false,
      },
      {
        id: "excel-balance-checker",
        label: "Excel Balance Checker",
        iconlabel: "faCaretRight",
        href: "/neb-manual-submission/excel-balance-checker",
        roles: ["NEB_DATA_PROVIDER"],
        element: <ExcelBalanceChecker />,
        showInMenu: false,
      },
    ],
  },
  {
    id: "neb-data-provider-ticket",
    label: "Report & Resolve Center",
    roles: ["NEB_DATA_PROVIDER"],
    iconlabel: "faCalendarCheck",
    showInMenu: true,
    items: [
      {
        id: "neb-data-provider-ticket-list",
        label: "Tickets",
        iconlabel: "faCaretRight",
        href: "/neb-data-provider-ticket/list",
        roles: ["NEB_DATA_PROVIDER"],
        element: <TicketResolutionCentre />,
        showInMenu: true,
      }, //"/ticket/ticket-form/:ticketId"
      {
        id: "neb-data-provider-ticket-new",
        //label: 'New Ticket',
        //iconlabel: 'faCaretRight',
        href: "/neb-data-provider-ticket/new",
        roles: ["NEB_DATA_PROVIDER"],
        element: <TicketFormDP />,
        showInMenu: false,
      },
    ],
  },
  {
    // file management is for upload file for manual submission template and Training Module for admin and dp
    // this is internal sidebar menu 
    id: "neb-file-management",
    label: "File Management",
    iconlabel: "faBookReader",
    roles: ["NEB_ADMIN"],
    showInMenu: false,
    items: [
      {
        id: "neb-file-management-system",
        label: "File Management",
        iconlabel: "faCaretRight",
        href: "/neb-file-management",
        roles: ["NEB_ADMIN"],
        element: <FilesystemComponent />,
        showInMenu: false,
      },
    ],
  },
  {
    id: "neb-training-module",
    label: "Training Module",
    iconlabel: "faBookReader",
    roles: ["NEB_DATA_PROVIDER", "NEB_ADMIN"],
    showInMenu: true,
    items: [
      {
        id: "neb-training-module-view",
        label: "Training Module",
        iconlabel: "faCaretRight",
        href: "/neb-training-module/view",
        roles: ["NEB_DATA_PROVIDER", "NEB_ADMIN"],
        element: <TrainingModule />,
        showInMenu: true,
      },
    ],
  },
  {
    id: "neb-profile",
    label: "My Profile",
    iconlabel: "faUser",
    roles: ["NEB_DATA_PROVIDER"],
    showInMenu: true,
    items: [
      {
        id: "neb-profile-view",
        label: "View Profile",
        iconlabel: "faCaretRight",
        href: "/neb-profile/view",
        roles: ["NEB_DATA_PROVIDER"],
        element: <Profile />,
        showInMenu: true,
      },
      {
        id: "neb-profile-edit",
        label: "Edit Profile",
        iconlabel: "faCaretRight",
        href: "/neb-profile/edit",
        roles: ["NEB_DATA_PROVIDER"],
        element: <ProfileEdit />,
        showInMenu: false,
      },
    ],
  },
  {
    id: "neb-faq",
    label: "FAQ",
    iconlabel: "faQuestionCircle",
    roles: ["NEB_DATA_PROVIDER"],
    showInMenu: true,
    items: [
      {
        id: "neb-faq-view",
        label: "View FAQ",
        iconlabel: "faCaretRight",
        href: "/neb-faq/view",
        roles: ["NEB_DATA_PROVIDER"],
        element: <FAQ />,
        showInMenu: true,
      },
    ],
  },
];

export default menuItems;
