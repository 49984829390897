import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Badge,
} from "react-bootstrap";
import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import ClayInput from "@clayui/form/lib/Input";
import ClayLayout from "@clayui/layout";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "../../../components/Pagination";
import { listPaginatedDataProvider } from "../../../api/nebDataProviderService";

const DataProviderList = () => {
  // const dataProviderService = NebDataProviderService();
  const initialPage = 1;
  const initialItemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [dataProviderList, setDataProviderList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await listPaginatedDataProvider(
          currentPage - 1,
          itemsPerPage,
          searchTerm
        );
        setDataProviderList(result.content);
        setTotalPages(result.totalPages);
        setTotalItems(result.totalElements);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, searchTerm]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term on input change
    setCurrentPage(1); // Reset to first page when searching
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

  return (
    <>
      <ClayCard className="mb-3" style={{ borderRadius: "20px" }}>
        <Card.Header className="d-flex flex-column align-items-start">
        <ClayLayout.Row className="w-100">
            <ClayLayout.Col md={6}>
              <ClayInput.Group>
                <ClayInput.GroupItem>
                  <ClayInput
                    placeholder="Search Name"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    value={searchTerm} // Bind input to search term state
                    onChange={handleSearchChange} // Handle input change
                  />
                </ClayInput.GroupItem>
              </ClayInput.Group>
            </ClayLayout.Col>
            <ClayLayout.Col md={6} className="d-flex justify-content-end">
              <Link to="/createNewDataProvider">
                <ClayButton displayType="primary" className="mb-2 mb-md-0">
                + Add New Data Provider
                </ClayButton>
              </Link>
            </ClayLayout.Col>
          </ClayLayout.Row>
        </Card.Header>
        <Card.Body className="p-3" style={{ overflowX: "auto" }}>
          <Table striped bordered hover size="sm" className="mb-2">
            <thead className="text-white">
              <tr>
                <th style={{ width: '5%' }}>No</th>
                <th style={{ width: '30%' }}>Agency</th>
                <th style={{ width: '30%' }}>Name</th>
                <th style={{ width: '15%' }}>Date Created</th>
                <th style={{ width: '10%' }}>Status</th>
                <th style={{ width: '10%' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataProviderList.map((d, i) => (
                <tr key={d.userId}>
                  <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                  <td>{d.agency.agencyName}</td>
                  <td>{d.name}</td>
                  <td>{formatDate(d.createdDate)}</td>
                  <td>
                    <Badge
                      bg={d.status === "PENDING" ? "warning" : d.status === "ACTIVE" ? "success" : "danger"}
                    >
                      {d.status === "PENDING" ? "Pending Activation" : d.status === "ACTIVE" ? "Active" : "Inactive"}
                    </Badge>
                  </td>
                  <td>
                    <Link
                      to={`/dataProviderById/${parseInt(d.userId)}`}
                      className="btn btn-link"
                      style={{ width: "80px" }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalItems} // Pass totalItems to Pagination
              onPageChange={handlePageChange}
              onEntriesChange={handleItemsPerPageChange}
              entriesPerPage={itemsPerPage} // Pass entriesPerPage to Pagination
            />
        </Card.Body>
      </ClayCard>
    </>
  );
}

export default DataProviderList