import axiosInstance from "./axiosInstance";

const endpoint = "/neb/announcement";

// Create new announcement
export const createAnnouncement = async (announcement) => {
  try {
    const response = await axiosInstance.post(`${endpoint}/submit`, announcement);
    return response.data;
  } catch (error) {
    console.error("Error creating announcement:", error);
    throw error;
  }
};

// Get all announcements
export const getAnnouncements = async () => {
  try {
    const response = await axiosInstance.get(`${endpoint}/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching announcements:", error);
    throw error;
  }
};

// Update announcement by ID
export const updateAnnouncement = async (id, updatedAnnouncement) => {
  try {
    const response = await axiosInstance.put(`${endpoint}/${id}`, updatedAnnouncement);
    return response.data;
  } catch (error) {
    console.error("Error updating announcement:", error);
    throw error;
  }
};

// Delete announcement by ID
export const deleteAnnouncement = async (id) => {
  try {
    const response = await axiosInstance.delete(`${endpoint}/${id}`);
    return response;
  } catch (error) {
    console.error("Error deleting announcement:", error);
    throw error;
  }
};
