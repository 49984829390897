import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/dataprovider";
const endpoint2 = "/neb/agency";

// Save Data Provider
export const saveDataProvider = async (dataProvider) => {
    try {
        const response = await axiosInstance.put(`${endpoint}/save`, dataProvider);
        return response.data;
    } catch (error) {
        console.error('Error saving data provider:', error);
        throw error;
    }
};

// Fetch paginated Data Providers with search term
export const listPaginatedDataProvider = async (page, size, searchTerm) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/paginated`, {
            params: { page, size, searchTerm }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching paginated data providers:', error);
        throw error;
    }
};

// List agency names
export const listAgencyName = async () => {
    try {
        const response = await axiosInstance.get(`${endpoint2}/list-ids-names`);
        return response.data;
    } catch (error) {
        console.error('Error fetching agency names:', error);
        throw error;
    }
};

// Get Data Provider by ID
export const getDataProviderById = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data provider by ID:', error);
        throw error;
    }
};

// Update Data Provider
export const updateDataProvider = async (dataProvider) => {
    try {
        const response = await axiosInstance.put(`${endpoint}/update`, dataProvider);
        return response.data;
    } catch (error) {
        console.error('Error updating data provider:', error);
        throw error;
    }
};

// Get Data Provider by Agency ID
export const getDataProviderByAgencyId = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/byAgencyId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data provider by agency ID:', error);
        throw error;
    }
};

// Get Active Data Provider by Agency ID
export const getActiveDataProviderByAgencyId = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/activeByAgencyId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching active data provider by agency ID:', error);
        throw error;
    }
};

// Get Data Provider list by Agency ID
export const getDataProviderListByAgencyId = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/listByAgencyId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data provider list by agency ID:', error);
        throw error;
    }
};

// Update data provider column by ID
export const updateColumnDataProviderById = async (id, updatedFields) => {
    try {
      const response = await axiosInstance.put(
        `${endpoint}/updateColumn/${id}`,
        updatedFields
      );
      return response.data;
    } catch (error) {
      console.error("Error updating agency:", error);
      throw error;
    }
  };

