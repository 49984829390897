import axiosInstance from './axiosInstance';

const submitEndpoint = "/neb/report/submt2"; // submit report
const submissionListEndpoint = "/neb/report/submissionList" //get all reports quarters and statuses
const submissionListAgencyEndpoint = "/neb/report/submissionListAgency"; //get reports by agency id
const getAllReportsEndpoint = "/neb/report/getAll"; // get all documents in the collection
const getReportByIdEndpoint = "/neb/report/getReportById"; // get report by report id
const updateReportEndpoint = "/neb/report/updateReport"; // update report
const deleteReportEndpoint = "/neb/report/deleteReport"; // delete report by report id
const getDTAResultEndpoint = "/neb/report/getProductStats"; // data trend analysis
const getDTADashboardResultEndpoint = "/neb/report/getReportTrendAnalysis"; // data trend analysis on admin dashboard
const getDistinctYearsEndpoint = "/neb/report/getDistinctYears"; // get distinct years from the collection

export const getSubmissionListAgency = async (agencyId) => {
    try {
        const response = await axiosInstance.get(`${submissionListAgencyEndpoint}/${agencyId}`);
        return response;
    } catch (error) {
        console.error('Error getting list:', error);
        throw error;
    }
};

export const getSubmissionList = async () => {
    try {
        const response = await axiosInstance.get(`${submissionListEndpoint}`);
        return response;
    } catch (error) {
        console.error('Error getting list:', error);
        throw error;
    }
};

export const submitReport = async (reportData) => {
    try {
        const response = await axiosInstance.put(submitEndpoint, reportData);
        return response;
    } catch (error) {
        console.error('Error submitting report:', error);
        throw error;
    }
};

export const updateReport = async (reportId, reportData) => {
    try {
        const response = await axiosInstance.put(`${updateReportEndpoint}/${reportId}`, reportData);
        return response;
    } catch (error) {
        console.error('Error submitting report:', error);
        throw error;
    }
};

export const deleteReport = async (reportId) => {
    try {
        const response = await axiosInstance.delete(`${deleteReportEndpoint}/${reportId}`);
        return response;
    } catch (error) {
        console.error('Error deleting report:', error);
        throw error;
    }
};

export const getAllReport = async (agencyId) => {
    try {
        const response = await axiosInstance.get(`${getAllReportsEndpoint}/${agencyId}`);
        return response;
    } catch (error) {
        console.error('Error getting reports:', error);
        throw error;
    }
};

export const getReportById = async (id) => {
    try {
        const response = await axiosInstance.get(`${getReportByIdEndpoint}/${id}`);
        return response;
    } catch (error) {
        console.error('Error getting report:', error);
        throw error;
    }
};

export const getDTAResult = async (quarter, year, product, form, agencyId) => {
    try {
        const response = await axiosInstance.get(`${getDTAResultEndpoint}/${product}/${quarter}/${year}/${form}/${agencyId}`);
        return response;
    } catch (error) {
        console.error('Error getting report:', error);
        throw error;
    }
};

export const getDashboardDTAResult = async (yearFrom, yearTo) => {
    try {
        const response = await axiosInstance.get(`${getDTADashboardResultEndpoint}/${yearFrom}/${yearTo}`);
        return response;
    } catch (error) {
        console.error('Error getting report:', error);
        throw error;
    }
};

export const getDistinctYears = async () => {
    try {
        const response = await axiosInstance.get(`${getDistinctYearsEndpoint}`);
        return response;
    } catch (error) {
        console.error('Error getting year list:', error);
        throw error;
    }
};

