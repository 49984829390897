import React, { useState, useEffect } from "react";
import ClayModal, { useModal } from "@clayui/modal";
import ClayButton from "@clayui/button";
import ClayForm, { ClayInput, ClaySelect } from "@clayui/form";
import ClayLayout from "@clayui/layout";
import DatePicker from "react-datepicker"; // Ensure you have this installed
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for DatePicker

function EnergyDataModal({ observer, onClose, onSave, selectedData, mode, selectedTemplate }) {
    const [year, setYear] = useState(null);
    const [dataFields, setDataFields] = useState([]);
    const [error, setError] = useState(null); // State to manage error messages

    useEffect(() => {
        if (mode === "edit") {
            if (!selectedTemplate) {
                setError("Template not found. Please select a valid template.");
                return; // Exit early if the template is not found
            }

            if (selectedData) {
                // Pre-populate fields with existing EnergyData if in edit mode
                setYear(new Date(selectedData.year, 0, 1)); // Set year to a date object (January 1st of the year)
                const existingDataFields = selectedTemplate.fields.map((field) => ({
                    key: field.key,
                    fieldName: field.fieldName,
                    fieldUnit: field.fieldUnit,
                    value: selectedData.data[field.key]?.value || selectedData.data[field.key] || "", // Handle object fields with value
                    selectValues: field.selectValues || [],
                }));
                setDataFields(existingDataFields);
                setError(null); // Clear error if template is valid
            }
        } else if (mode === "create") {
            // For create mode, set fields from the selected template
            if (selectedTemplate) {
                setYear(null); // Reset year
                const newDataFields = selectedTemplate.fields.map((field) => ({
                    key: field.key,
                    fieldName: field.fieldName,
                    fieldUnit: field.fieldUnit,
                    value: "", // Default to empty for new entries
                    selectValues: field.selectValues || [],
                }));
                setDataFields(newDataFields);
                setError(null); // Clear error in create mode
            }
        }
    }, [mode, selectedData, selectedTemplate]);

    const handleFieldChange = (index, value) => {
        const updatedFields = [...dataFields];
        updatedFields[index].value = value;
        setDataFields(updatedFields);
    };

    const handleYearChange = (date) => {
        if (date) {
            setYear(date);
            setError(null); // Clear error when year is selected
        }
    };

    const handleSave = () => {
        if (!year) {
            setError("Year is required."); // Set error if year is not selected
            return;
        }

        const formData = {
            id: selectedData?.id || null, // Include the ID if it's an edit, otherwise it's null for create
            year: year.getFullYear(), // Extract the year from the Date object
            type: selectedTemplate.type,
            category: selectedTemplate.category,
            data: dataFields.reduce((acc, field) => {
                // If the field has a unit, save the value as an object { value, unit }
                if (field.fieldUnit) {
                    acc[field.key] = {
                        value: field.value,
                        unit: field.fieldUnit,
                    };
                } else if (field.selectValues.length > 0) {
                    // If the field has selectValues, save the selected option as an object { code, description }
                    const selectedOption = field.selectValues.find((option) => option.code === field.value);
                    acc[field.key] = selectedOption ? {
                        code: selectedOption.code,
                        description: selectedOption.description,
                    } : null;
                } else {
                    // Otherwise, save the value directly
                    acc[field.key] = field.value;
                }
                return acc;
            }, {}),
        };

        console.log("Form Data being saved: ", formData); // Log formData for debugging
        onSave(formData); // Save form data (either new or edited)
    };

    return (
        <ClayModal observer={observer} size="lg">
            <ClayModal.Header>{mode === "edit" ? "Edit Energy Data" : "Add Energy Data"}</ClayModal.Header>
            <ClayModal.Body>
                {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
                <ClayForm>
                    <ClayLayout.Row>
                        <ClayLayout.Col size={12}>
                            <ClayForm.Group>
                                <label htmlFor="formName">Form Name</label>
                                <ClayInput
                                    id="formName"
                                    type="text"
                                    value={selectedTemplate.formName || ""}
                                    readOnly
                                />
                            </ClayForm.Group>
                        </ClayLayout.Col>
                        <ClayLayout.Col size={6}>
                            <ClayForm.Group>
                                <label htmlFor="type">Energy</label>
                                <ClayInput
                                    id="type"
                                    type="text"
                                    value={selectedTemplate.type?.description || ""}
                                    readOnly
                                />
                            </ClayForm.Group>
                        </ClayLayout.Col>
                        <ClayLayout.Col size={6}>
                            <ClayForm.Group>
                                <label htmlFor="category">Process</label>
                                <ClayInput
                                    id="category"
                                    type="text"
                                    value={selectedTemplate.category?.description || ""}
                                    readOnly
                                />
                            </ClayForm.Group>
                        </ClayLayout.Col>
                        <ClayLayout.Col size={12}>
                            <ClayForm.Group>
                                <label htmlFor="unit">Unit</label>
                                <ClayInput
                                    id="unit"
                                    type="text"
                                    value={selectedTemplate.unit || ""}
                                    readOnly
                                />
                            </ClayForm.Group>
                        </ClayLayout.Col>

                        <ClayLayout.Col size={12}>
                            <ClayForm.Group>
                                <label htmlFor="year">Year <span style={{ color: 'red' }}>*</span></label>
                                <DatePicker
                                    selected={year}
                                    onChange={handleYearChange}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    placeholderText="Select Year"
                                    className={`form-control ${error ? 'is-invalid' : ''}`} // Add invalid class for styling if there's an error
                                />
                            </ClayForm.Group>
                        </ClayLayout.Col>

                        <ClayLayout.Col size={12}>
                            <label>Data Fields</label>
                            {dataFields.map((field, index) => (
                                <ClayLayout.Row key={index}>
                                    <ClayLayout.Col size={8}>
                                        <label>{field.fieldName}</label>
                                        {field.selectValues.length > 0 ? (
                                            <ClaySelect
                                                value={field.value?.code || field.value} // Display the code if it's a select value
                                                onChange={(e) => handleFieldChange(index, e.target.value)}
                                            >
                                                <option value="">Select Value</option>
                                                {field.selectValues.map((option, optIndex) => (
                                                    <option key={optIndex} value={option.code}>{option.description}</option>
                                                ))}
                                            </ClaySelect>
                                        ) : (
                                            <ClayInput
                                                type="text"
                                                placeholder="Enter Value"
                                                value={typeof field.value === 'object' ? field.value.value : field.value} // Display only the value part for object fields
                                                onChange={(e) => handleFieldChange(index, e.target.value)}
                                            />
                                        )}
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={4}>
                                        <label>{field.fieldUnit || ""}</label>
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                            ))}
                        </ClayLayout.Col>
                    </ClayLayout.Row>
                </ClayForm>
            </ClayModal.Body>
            <ClayModal.Footer
                last={
                    <ClayButton.Group spaced>
                        <ClayButton displayType="secondary" onClick={onClose}>
                            Cancel
                        </ClayButton>
                        <ClayButton displayType="primary" onClick={handleSave} disabled={!year}>
                            Save
                        </ClayButton>
                    </ClayButton.Group>
                }
            />
        </ClayModal>
    );
}

export default EnergyDataModal;