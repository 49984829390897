import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import {
  getLookupParameterDetailsByCode,
  findByLookupCategoryAndParentCode,
} from "../../../../api/lookupService";
import { saveAgency, getAgencyById } from "../../../../api/nebAgencyService";
import {
  getDataProviderByAgencyId,
  saveDataProvider,
} from "../../../../api/nebDataProviderService";
import { getLookupParametersByCategory } from "../../../../api/lookupService";

const AgencyInformation = ({ handleSave, agencyId }) => {
  const navigate = useNavigate();
  const [energySectorListing, setEnergySectorListing] = useState([]);
  const [regionListing, setRegionListing] = useState([]);
  const [stateListing, setStateListing] = useState([]);
  const [cityListing, setCityListing] = useState([]);
  const [agencyById, setAgencyById] = useState(null);
  const [dataProviderByAgencyId, setDataProviderByAgencyId] = useState(null);

  const [dataProviderChecked, setDataProviderChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("active");

  const [agency, setAgency] = useState({
    sectorId: "",
    regionId: "",
    agencyName: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    address3: "",
    poscode: "",
    city: "",
    state: "",
    country: "",
    cpdName: "",
    cpdEmail: "",
    cpdPhoneNumber: "",
    loginId: "",
    status: "DRAFT",
    manualSubmission: false,
    installedCapacity: false,
    powerPlantPerformance: false,
    netCalorificValue: false,
    c1CheckBox: false,
  });

  const [dataProvider, setDataProvider] = useState({
    agencyId: "",
    name: "",
    email: "",
    phoneNo: "",
    status: "PENDING",
    loginId: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (agencyId) {
      fetchDataAgencyDetails(agencyId);
    }
    fetchEnergySectors();
    fetchRegion();
    fetchState();
  }, [agencyId]);

  useEffect(() => {
    if (agency.state) {
      fetchCity(agency.state);
    }
  }, [agency.state]);

  const fetchDataAgencyDetails = async (agencyId) => {
    try {
      const resultAgency = await getAgencyById(agencyId);
      setAgencyById(resultAgency);
      setAgency({
        id: resultAgency.id || "",
        sectorId: resultAgency.sectorId || "",
        regionId: resultAgency.regionId || "",
        agencyName: resultAgency.agencyName || "",
        phoneNumber: resultAgency.phoneNumber || "",
        address1: resultAgency.address1 || "",
        address2: resultAgency.address2 || "",
        address3: resultAgency.address3 || "",
        postcode: resultAgency.postcode || "",
        city: resultAgency.city || "",
        state: resultAgency.state || "",
        country: resultAgency.country || "",
        cpdName: resultAgency.cpdName || "",
        cpdEmail: resultAgency.cpdEmail || "",
        cpdPhoneNumber: resultAgency.cpdPhoneNumber || "",
        name: resultAgency.name || "",
        email: resultAgency.email || "",
        phoneNo: resultAgency.phoneNo || "",
        loginId: resultAgency.loginId
          ? resultAgency.loginId.replace("@neb.st", "")
          : "",
        status:
          resultAgency.status === "PENDING" || resultAgency.status === "DRAFT"
            ? "DRAFT"
            : resultAgency.status || "",
        manualSubmission: resultAgency.manualSubmission || "",
        installedCapacity: resultAgency.installedCapacity || "",
        c1CheckBox: resultAgency.c1CheckBox || "",
        powerPlantPerformance: resultAgency.powerPlantPerformance || "",
        netCalorificValue: resultAgency.netCalorificValue || "",
        liferayOrganizationId:
          resultAgency.liferayOrganizationId === null
            ? null
            : resultAgency.liferayOrganizationId,
        liferayUserId:
          resultAgency.liferayUserId === null
            ? null
            : resultAgency.liferayUserId,
      });

      const resultDataProvider = await getDataProviderByAgencyId(agencyId);
      setDataProviderByAgencyId(resultDataProvider);
      console.log("data provider details: ", resultDataProvider);
      setDataProvider({
        userId: resultDataProvider.userId || "",
        agencyId: resultDataProvider.agencyId || "",
        name: resultDataProvider.name || "",
        email: resultDataProvider.email || "",
        phoneNo: resultDataProvider.phoneNo || "",
        loginId: resultDataProvider.loginId || "",
        status:
          resultDataProvider.status === "PENDING"
            ? "PENDING"
            : resultAgency.status,
        liferayAgencyId:
          resultDataProvider.liferayAgencyId === null
            ? null
            : resultDataProvider.liferayAgencyId,
        liferayId:
          resultDataProvider.liferayId === null
            ? null
            : resultDataProvider.liferayId,
      });

      const isActive = resultAgency.status === "ACTIVE";
      setChecked(isActive);
      setSelectedLabel(isActive ? "active" : "inactive");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEnergySectors = async () => {
    try {
      const result = await getLookupParametersByCategory("ENERGY-SECTOR");
      setEnergySectorListing(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRegion = async () => {
    try {
      const result = await getLookupParametersByCategory("REGION");
      setRegionListing(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchState = async () => {
    try {
      const result = await getLookupParametersByCategory("STATE");
      setStateListing(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCity = async (stateCode) => {
    try {
      const result = await findByLookupCategoryAndParentCode("CITY", stateCode);
      setCityListing(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAgency({ ...agency, [name]: value });
    setDataProvider({ ...dataProvider, [name]: value });
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);

    if (name === "state") {
      fetchCity(value);
    }
  };

  useEffect(() => {
    if (
      agency.cpdName === dataProvider.name &&
      agency.cpdEmail === dataProvider.email &&
      agency.cpdPhoneNumber === dataProvider.phoneNo
    ) {
      setDataProviderChecked(true);
    } else {
      setDataProviderChecked(false);
    }
  }, [agency, dataProvider]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setDataProviderChecked(checked);
  };

  const validate = () => {
    const newErrors = {};
    if (!agency.sectorId) newErrors.sectorId = "Energy Sector is required";
    if (!agency.regionId) newErrors.regionId = "Region is required";
    if (!agency.agencyName) newErrors.agencyName = "Agency Name is required";
    if (!agency.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (!agency.address1) newErrors.address1 = "Address line 1 is required";
    if (!agency.city) newErrors.city = "City is required";
    if (!agency.state) newErrors.state = "State is required";
    if (!agency.cpdName) newErrors.cpdName = "Contact Person Name is required";
    if (!agency.cpdEmail)
      newErrors.cpdEmail = "Contact Person Email is required";
    if (!agency.cpdPhoneNumber)
      newErrors.cpdPhoneNumber = "Data Provider Phone Number is required";
    if (!agency.loginId) newErrors.loginId = "Login ID is required";
    if (dataProviderChecked === false) {
      if (!dataProvider.name) newErrors.name = "Data Provider Name is required";
      if (!dataProvider.email)
        newErrors.email = "Data Provider Email is required";
      if (!dataProvider.phoneNo)
        newErrors.phoneNo = "Data Provider Phone Number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSaveClick = async () => {
    if (!validate()) {
      console.log("Validation failed");
      return;
    }

    try {
      console.log("Check Agency:", agency);
      const updatedAgency = {
        ...agency,
        loginId: `${agency.loginId}@neb.st`,  // Save loginId from dataProvider to agency
      };
      const response = await saveAgency(updatedAgency);
      console.log("Agency saved successfully:", response);
      setAgency(response);

      let dataProviderToSave = dataProvider;
      if (dataProviderChecked) {
        dataProviderToSave = {
          ...dataProviderToSave,
          agencyId: response.id,
          name: agency.cpdName,
          email: agency.cpdEmail,
          phoneNo: agency.cpdPhoneNumber,
          status: dataProvider.status,
          loginId: updatedAgency.loginId,
        };

        const dataproviderResult = await saveDataProvider(dataProviderToSave);
        console.log("Data provider saved successfully:", dataproviderResult);
      } else {
        dataProviderToSave = {
          ...dataProviderToSave,
          agencyId: response.id,
          name: dataProvider.name || "Default Name",
          email: dataProvider.email || "default@example.com",
          phoneNo: dataProvider.phoneNo || "000-000-0000",
          status: dataProvider.status,
          loginId: updatedAgency.loginId,
        };

        const dataproviderResultnotChecked = await saveDataProvider(
          dataProviderToSave
        );
        console.log(
          "Data provider not checked saved successfully:",
          dataproviderResultnotChecked
        );
      }

      // Show success toast notification
      toast.success(`The details have been saved successfully!`);
      setTimeout(() => {
        handleSave(response.id);
      }, 1000);
    } catch (error) {
      console.error("Error saving agency:", error);
      toast.error(`Failed to save agency details.`);
    }
  };

  const onCancelClick = () => {
    const confirmation = window.confirm(
      "Are you sure you want to cancel this process?"
    );
    if (confirmation) {
      toast.success(`The process have been cancel`);
      setTimeout(() => {
        navigate(`/neb-agency`);
      }, 2000);
    }
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex flex-column align-items-start">
          <div className="d-flex justify-content-between w-100 mb-3">
            <Card.Title as="h2" className="mb-0">
              <b>Create Agency</b>
              <p>
                Fill up all details <span className="text-danger">*</span>
              </p>
            </Card.Title>
          </div>
        </Card.Header>
        <Card.Body>
          {/* Details */}
          <Form className="mt-3">
            <b>Details</b>
            <Form.Group as={Row} className="mt-3">
              <Col sm={3}>
                <Form.Label
                  htmlFor="energySector"
                  className="font-weight-normal"
                >
                  Energy Sector <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="sectorId"
                  aria-label="Select Energy Sector"
                  value={agency.sectorId}
                  onChange={handleChange}
                  isInvalid={!!errors.sectorId}
                >
                  <option value="">Please Select.. </option>
                  {energySectorListing.map((sector) => (
                    <option key={sector.code} value={sector.code}>
                      {sector.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.sectorId}
                </Form.Control.Feedback>
              </Col>

              <Col sm={3}>
                <Form.Label htmlFor="region" className="font-weight-normal">
                  Region <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="regionId"
                  aria-label="Select Region"
                  value={agency.regionId}
                  onChange={handleChange}
                  isInvalid={!!errors.regionId}
                >
                  <option value="">Please Select.. </option>
                  {regionListing.map((region) => (
                    <option key={region.code} value={region.code}>
                      {region.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.regionId}
                </Form.Control.Feedback>
              </Col>

              <Col sm={3}>
                <Form.Label htmlFor="agencyName" className="font-weight-normal">
                  Agency Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="agencyName"
                  placeholder="Enter Agency Name"
                  value={agency.agencyName}
                  onChange={handleChange}
                  isInvalid={!!errors.agencyName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.agencyName}
                </Form.Control.Feedback>
              </Col>

              <Col sm={3}>
                <Form.Label
                  htmlFor="phoneNumber"
                  className="font-weight-normal"
                >
                  Office Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder="Enter Office Number"
                  value={agency.phoneNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* Address Section */}
            <Form.Group as={Row} className="mt-3">
              <Col sm={8}>
                <Form.Label htmlFor="address" className="font-weight-normal">
                  Address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address1"
                  placeholder="Enter Address line 1"
                  value={agency.address1}
                  onChange={handleChange}
                  isInvalid={!!errors.address1}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address1}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="address2"
                  placeholder="Enter Address line 2"
                  value={agency.address2}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-3">
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="address3"
                  placeholder="Enter Address line 3"
                  value={agency.address3}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mt-3">
              <Col sm={4}>
                <Form.Control
                  type="text"
                  name="postcode"
                  placeholder="Postcode"
                  value={agency.postcode}
                  onChange={handleChange}
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  name="state"
                  aria-label="State"
                  value={agency.state}
                  onChange={handleChange}
                  isInvalid={!!errors.state}
                >
                  <option value="">Please Select State..</option>
                  {stateListing.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.state}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mt-3">
              <Col sm={4}>
                <Form.Control
                  as="select"
                  name="city"
                  aria-label="City"
                  value={agency.city}
                  onChange={handleChange}
                  isInvalid={!!errors.city}
                >
                  <option value="">Please Select City..</option>
                  {cityListing.map((city) => (
                    <option key={city.code} value={city.code}>
                      {city.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Col>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  name="country"
                  placeholder="Malaysia"
                  value="Malaysia"
                  readOnly
                />
              </Col>
            </Form.Group>
          </Form>
          {/* Contact Person Details */}
          <Form className="mt-3">
            <b>Contact Person Details</b>
            <Form.Group as={Row} className="mt-3">
              <Col sm={4}>
                <Form.Label htmlFor="cpdName" className="font-weight-normal">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cpdName"
                  placeholder="Enter Name"
                  value={agency.cpdName}
                  onChange={handleChange}
                  isInvalid={!!errors.cpdName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cpdName}
                </Form.Control.Feedback>
              </Col>
              <Col sm={4}>
                <Form.Label htmlFor="cpdEmail" className="font-weight-normal">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cpdEmail"
                  placeholder="Enter Email"
                  value={agency.cpdEmail}
                  onChange={handleChange}
                  isInvalid={!!errors.cpdEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cpdEmail}
                </Form.Control.Feedback>
              </Col>
              <Col sm={4}>
                <Form.Label
                  htmlFor="cpdPhoneNumber"
                  className="font-weight-normal"
                >
                  Phone Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cpdPhoneNumber"
                  placeholder="Enter Phone Number"
                  value={agency.cpdPhoneNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.cpdPhoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cpdPhoneNumber}
                </Form.Control.Feedback>
              </Col>
              <Col className="mt-2">
                <Form.Check
                  label="Data Provider Details same with Contact Person Details"
                  type="checkbox"
                  id="checkbox1"
                  checked={dataProviderChecked}
                  onChange={handleCheckboxChange}
                />
              </Col>
            </Form.Group>
          </Form>

          {/* Login ID Information */}
          <Form className="mt-3">
            <b>Login ID Information</b>
            <Form.Group as={Row} className="mt-3">
              <Col sm={4}>
                <Form.Label htmlFor="loginId" className="font-weight-normal">
                  Login ID <span className="text-danger">*</span>
                </Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="loginId"
                    placeholder="Enter Login ID"
                    value={agency.loginId}
                    onChange={handleChange}
                    isInvalid={!!errors.loginId}
                  />
                  <span className="ml-2">@neb.st</span>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.loginId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
          {/* Conditional Data Provider Section */}
          {dataProviderChecked === false && (
            <Form className="mt-3">
              <b>Data Provider Details</b>
              <Form.Group as={Row} className="mt-3">
                <Col sm={4}>
                  <Form.Label htmlFor="name" className="font-weight-normal">
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={dataProvider.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Col>
                <Col sm={4}>
                  <Form.Label htmlFor="email" className="font-weight-normal">
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    value={dataProvider.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
                <Col sm={4}>
                  <Form.Label htmlFor="phoneNo" className="font-weight-normal">
                    Phone Number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneNo"
                    placeholder="Enter Phone Number"
                    value={dataProvider.phoneNo}
                    onChange={handleChange}
                    isInvalid={!!errors.phoneNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNo}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="link"
          onClick={onCancelClick}
          className="btn btn-outline-primary"
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={onSaveClick} className="ml-2">
          Save
        </Button>
      </div>
    </>
  );
};

export default AgencyInformation;
