import { create } from 'zustand';

// Zustand store for authentication
const useAuthStore = create((set) => ({
  user: null,
  token: null,
  roles: [],
  
  // Action to log in the user
  login: (user, token) => set({ user, token }),

  // Action to log out the user
  logout: () => set({ user: null, token: null, roles: [] }),

  // Action to set user roles
  setRoles: (roles) => set({ roles }),

  // Action to set user information
  setUser: (user) => set({ user }),
}));

export default useAuthStore;