import axios from 'axios';
import useAuthStore from '../store/useAuthStore';

// Create an Axios instance with base configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Use the API URL from environment variables
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the authentication token from Zustand
axiosInstance.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().token; // Get the token from Zustand store
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach the token to the Authorization header
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Optionally add a response interceptor for handling token expiration or other errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response; // Return the response if everything is okay
  },
  (error) => {
    const { logout } = useAuthStore.getState();
    if (error.response && error.response.status === 401) {
      // Token has expired or is invalid, trigger a logout
      logout();
    }
    return Promise.reject(error); // Handle other errors
  }
);

export default axiosInstance;