import React from 'react';
import ClayPanel from '@clayui/panel';
import faq from './faq.json';
import './FAQ.css';

const FAQ = () => {
  return (
    <div className="faq-container">
      {faq.map((category, index) => (
        <ClayPanel.Group key={index} className="faq-category-panel">
          <ClayPanel
            collapsable
            displayTitle={
              <ClayPanel.Title className="category-title">
                <h5>{category.Category}</h5>
              </ClayPanel.Title>
            }
            displayType="secondary"
            showCollapseIcon={true}
          >
            {category["Sub-Category"].map((item, subIndex) => (
              <div key={subIndex} className="faq-item">
                <ClayPanel
                  collapsable
                  displayTitle={
                    <ClayPanel.Title className="faq-title">
                      <span>{item.FAQ}</span>
                    </ClayPanel.Title>
                  }
                  displayType="unstyled"
                  showCollapseIcon={true}
                >
                  <ClayPanel.Body className="faq-answer">
                    {item.Answer}
                  </ClayPanel.Body>
                </ClayPanel>
                {subIndex < category["Sub-Category"].length - 1 && (
                  <div className="divider"></div>
                )}
              </div>
            ))}
          </ClayPanel>
        </ClayPanel.Group>
      ))}
    </div>
  );
};

export default FAQ;
