import React, { useEffect, useState } from 'react';
import { fetchTicketCountByStatus } from '../../api/ticketService'; // New dynamic service method
import ClayCard from '@clayui/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

const Ticketing = () => {
    const [openTicketsCount, setOpenTicketsCount] = useState(0);
    const [resolvedTicketsCount, setResolvedTicketsCount] = useState(0);

    useEffect(() => {
        const loadTicketCounts = async () => {
            try {
                const openTickets = await fetchTicketCountByStatus('Open');
                const resolvedTickets = await fetchTicketCountByStatus('Resolved');

                setOpenTicketsCount(openTickets);
                setResolvedTicketsCount(resolvedTickets);
            } catch (error) {
                console.error('Error fetching ticket counts:', error);
            }
        };

        loadTicketCounts();
    }, []);

    return (
        <div className="row">
            <div class="col-6">
                <div class="small-box color-palette bg-gray">
                    <div class="inner">
                        <h3>{openTicketsCount}</h3>
                        <p>Open Tickets</p>
                    </div>
                    <div class="icon">
                        <FontAwesomeIcon icon={solidIcons['faFileAlt']} />
                    </div>
                </div>
            </div>  
            <div class="col-6">
                <div class="small-box color-palette bg-gray">
                    <div class="inner">
                        <h3>{resolvedTicketsCount}</h3>
                        <p>Resolved Tickets</p>
                    </div>
                    <div class="icon">
                        <FontAwesomeIcon icon={solidIcons['faFileAlt']} />
                    </div>
                </div>
            </div>  
            {/*<div className="col-md-6">
                    <ClayCard>
                        <ClayCard.Body>
                            <ClayCard.Description displayType="title">Open Tickets</ClayCard.Description>
                            <ClayCard.Description displayType="text">{openTicketsCount}</ClayCard.Description>
                        </ClayCard.Body>
                    </ClayCard>
                </div>
                <div className="col-md-6">
                    <ClayCard>
                        <ClayCard.Body>
                            <ClayCard.Description displayType="title">Resolved Tickets</ClayCard.Description>
                            <ClayCard.Description displayType="text">{resolvedTicketsCount}</ClayCard.Description>
                        </ClayCard.Body>
                    </ClayCard>
                </div>*/}
        </div>
    );
};

export default Ticketing;