import React, { useState, useEffect } from "react";
import VerticalNav from "./VerticalNav";
import { useLocation } from "react-router-dom";
import useAuthStore from "../store/useAuthStore";
import { toast, ToastContainer } from "react-toastify";
import menuItems from "../config/menuconfig";
import "../assets/css/layout.css"; // Import CSS for styling
import BreadCrumb from "./BreadCrumb"; // Import your custom breadcrumb component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import {
  faChevronLeft,
  faChevronRight,
  faBars,
} from "@fortawesome/free-solid-svg-icons"; // Import icons
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import { getAgencyByLiferayId } from "../api/nebAgencyService";

import "../assets/css/theme1.css"; // CSS custom theme
import "../assets/css/adminlte_custom.css"; // CSS custom adminlte

const ClaySidebarLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false); // State to handle the sidebar collapse
  const { roles, user } = useAuthStore(); // Zustand store to get user roles
  const location = useLocation(); // Hook to access the current route
  const [navMenuItems, setNavMenuItems] = useState([]); // Initialize with an empty array
  const [breadcrumbItems, setBreadcrumbItems] = useState([]); // State for breadcrumb items
  const [headerLabel, setHeaderLabel] = useState(""); // State for header label
  const [manualSubmissionFlag, setManualSubmissionFlag] = useState(null);
  const [agencyName, setAgencyName] = useState("Loading");

  useEffect(() => {
    const fetchManualSubmissionFlag = async () => {
      if (!user?.id) return;

      try {
        const agency = await getAgencyByLiferayId(user.id);
        const flag = agency?.manualSubmission;
        console.log("Manual Submission Flag:", flag);
        setManualSubmissionFlag(flag);
        setAgencyName(agency.agencyName);
      } catch (error) {
        console.error("Error fetching Manual Submission Flag:", error);
      }
    };

    if (user?.id) {
      fetchManualSubmissionFlag();
    }
  }, [user]);

  useEffect(() => {
    const roleNames = roles.map((role) => role); // Map role names

    const filterMenuItemsByRoleAndFlag = (items) => {
      return items
        .map((item) => {
          const hasRoleAccess =
            item.roles && item.roles.some((role) => roleNames.includes(role));
          const filteredItems = item.items
            ? filterMenuItemsByRoleAndFlag(item.items)
            : [];

          const isManualSubmissionItem = item.id === "neb-manual-submission";
          if (isManualSubmissionItem && manualSubmissionFlag === false) {
            return null;
          }

          if (hasRoleAccess || filteredItems.length > 0) {
            return { ...item, items: filteredItems };
          }
          return null;
        })
        .filter((item) => item !== null);
    };

    const filteredMenuItems = filterMenuItemsByRoleAndFlag(menuItems);
    console.log("filteredMenuItems", filteredMenuItems);
    setNavMenuItems(filteredMenuItems);
  }, [roles, manualSubmissionFlag]);

  // Handle breadcrumb generation based on the current route
  useEffect(() => {
    const generateBreadcrumbs = (path) => {
      const breadcrumbs = [];

      const findBreadcrumbs = (items, currentPath) => {
        for (const item of items) {
          if (item.href === currentPath) {
            breadcrumbs.push({
              href: item.href,
              label: item.label,
              active: true,
            });
            return true;
          }

          if (item.items) {
            if (findBreadcrumbs(item.items, currentPath)) {
              breadcrumbs.unshift({
                href: item.href,
                label: item.label,
              });
              return true;
            }
          }
        }
        return false;
      };

      findBreadcrumbs(menuItems, path);
      return breadcrumbs;
    };

    const currentPath = location.pathname;
    const crumbs = generateBreadcrumbs(currentPath);

    console.log("Breadcrumb items:", crumbs); // Debugging
    setBreadcrumbItems(crumbs);

    const activeBreadcrumb = crumbs.find((item) => item.active);
    if (activeBreadcrumb) {
      console.log("Header label:", activeBreadcrumb.label); // Debugging
      setHeaderLabel(activeBreadcrumb.label);
    }
  }, [location.pathname]);

  //sidebar collapse on windows resize
  useEffect(() => {
    function updateSize() {
      //setSize([window.innerWidth, window.innerHeight]);
      setCollapsed(window.innerWidth < 768);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Handle toggling the sidebar collapse
  const handleToggleSidebar = () => setCollapsed((prev) => !prev);

  const onLogOut = () => {
    console.log("logout");
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
    if (logoutUrl) {
      window.location.href = logoutUrl; // Redirect to logout URL
    } else {
      console.error("REACT_APP_LOGOUT_URL is not defined.");
    }
    return false;
  };

  return (
    <div className="layout-root">
      <div className="layout-content">
        <div className={`elevation-4 sidebar ${collapsed ? "collapsed" : ""}`}>
          <div className="sidebar-header">
            {/* logo system */}
            <img src="/myenergystatslogo.png" className="brand-logo mr-2" />
            <span className="brand-text">
              <b>NEB Module</b>
            </span>
          </div>
          <VerticalNav items={navMenuItems} />
        </div>
        <div className="content-area">
          <div className="main-header bg-mytheme">
            <button className="sidebar-toggle" onClick={handleToggleSidebar}>
              <FontAwesomeIcon icon={faBars} className="sidebar-toggle-icon" />
            </button>

            {console.log("User roles:", roles)}
            {roles.includes("NEB_DATA_PROVIDER") ? (
              <span id="usernameText" className="ml-3 mb-1">
                {agencyName}
              </span> // Assuming 'agencyName' holds the agency's name
            ) : (
              <span id="usernameText" className="ml-3 mb-1">
                {user ? (user.name !== null ? user.name : "Loading") : ""}
              </span>
            )}
            <a href="#" className="ml-4 mb-1" onClick={onLogOut}>
              <FontAwesomeIcon icon={solidIcons["faSignOut"]} /> Logout
            </a>
          </div>
          <div className="content-body">
            <BreadCrumb items={breadcrumbItems} />
            <div>
              <h1>{headerLabel}</h1>
            </div>{" "}
            {/* Display the current item label as header */}
            {children}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ClaySidebarLayout;
