import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getLookupParametersByCategory } from "../../../api/lookupService";
import { countAgenciesBySectorId } from "../../../api/nebAgencyService";
import { Card } from "react-bootstrap";

const AgencyBySector = () => {
  const [sectorData, setSectorData] = useState([]);

  // Function to generate chart options based on fetched sector data
  const getChartOptions = (sectors) => ({
    chart: {
      height: 300,
      type: "pie",
      innerSize: "50%", // Donut chart
      backgroundColor: null, // Make the background transparent
      spacing: [0, 0, 0, 0], // Remove spacing around the chart
    },
    title: {
      text: undefined,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    exporting: {
      enabled: false // This disables the menu bar
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true, // Enable labels for each sector
          format: "{point.percentage:.1f} %", // Format for sector labels
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Sectors",
        data: sectors,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      align: 'right', // Horizontal alignment (left, center, right)
      verticalAlign: 'middle', // Vertical alignment (top, middle, bottom)
      layout: 'vertical' // Layout of items (horizontal or vertical)
    }
  });

  // Fetch sector data from the LookupService and extract only the description
  const fetchEnergySectors = async () => {
    try {
      // Fetch the list of sectors
      const result = await getLookupParametersByCategory("ENERGY-SECTOR");

      // Map over each sector to fetch the count from the API
      const sectors = await Promise.all(result.map(async (sector) => {
        const sectorId = sector.code; // Use sector.code as sectorId for API call
        console.log("sectorId: ", sectorId);

        try {
          // Fetch the count of agencies by sectorId
          const response = await countAgenciesBySectorId(sectorId);

          // Check and log the response structure
          console.log("response data: ", response);

          // Ensure response.data is an array and has at least one item
          const count = response && response.length > 0
            ? response[0].total
            : 0;

          console.log("count: ", count);

          // Return the sector data with the count
          return {
            name: sector.description, // Use description as the name for chart data
            y: count, // Set the actual count value
          };
        } catch (error) {
          console.error(`Error fetching count for sectorId ${sectorId}:`, error);
          // Return a fallback value in case of an error
          return {
            name: sector.description,
            y: 0, // Or another default value
          };
        }
      }));

      // Set the formatted sector data for the chart
      setSectorData(sectors);
    } catch (error) {
      console.log('Error fetching energy sectors:', error);
    }
  };




  useEffect(() => {
    fetchEnergySectors();
  }, []);


  return (
    <div
      // style={{
      //   display: "flex",
      //   flexWrap: "wrap",
      //   justifyContent: "space-around",
      // }}
    >
      <Card className="mb-5 p-5" style={{ boxShadow: '0px 0px 0px 0px', border: '2px solid #dcdcdc', borderRadius: '10px' }}>
        <div >
          {sectorData.length > 0 && (
            <HighchartsReact
              highcharts={Highcharts}
              options={getChartOptions(sectorData)}
            />
          )}
        </div>
      </Card>
    </div>

  );
};

export default AgencyBySector;
