import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/nebproductunit";

export const saveProductUnit = async (productUnits) => {
  try {
    const response = await axiosInstance.post(`${endpoint}/saveproductunit`, productUnits);
    console.log("save product unit: ", response);
    return response.data;
  } catch (error) {
    console.error('Error saving product units:', error);
    throw error;
  }
};

export const deleteProductUnitsByProductId = async (productId) => {
  try {
    const response = await axiosInstance.delete(`${endpoint}/deleteProductUnitsByProductId/${productId}`);
    console.log("delete product units: ", response);
    return response.data;
  } catch (error) {
    console.error('Error deleting product units:', error);
    throw error;
  }
};

export const getValuesInKtoeDTA = async (productName, unit) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/getValuesInKtoeDTA/${productName}/${unit}`);
  } catch (error) {
    
  }
}

