import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/nebproductcategory";

export const getAllProductCategory = async () => {

  try {
    const response = await axiosInstance.get(`${endpoint}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

