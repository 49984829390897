import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/ticketassignee";

// Save Ticket Assignee
export const saveTicketAssignee = async (ticketAssignee) => {
    try {
        const response = await axiosInstance.put(`${endpoint}/save`, ticketAssignee);
        return response.data;
    } catch (error) {
        console.error('Error saving ticket assignee:', error);
        throw error;
    }
};

// Get Ticket Assignee by ID
export const getTicketAssigneeById = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching ticket assignee by ID:', error);
        throw error;
    }
};

// Get Ticket Assignee by Ticket ID
export const getTicketAssigneeByTicketId = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/byTicketId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching ticket assignee by agency ID:', error);
        throw error;
    }
};

// Get Ticket Assignee list by Ticket ID
export const getTicketAssigneeListByTicketId = async (id) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/listByTicketId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching ticket assignee list by agency ID:', error);
        throw error;
    }
};
