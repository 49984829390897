import { useEffect, useState } from 'react';
import { OAuth2AuthCodePkceClient } from 'oauth2-pkce';
import useAuthStore from '../store/useAuthStore';
import { fetchUserData, fetchUserRoles } from '../api/userService'; // Import from userService.js

// Function to get redirect URL from the query string or environment variable
const getRedirectUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUriFromQuery = urlParams.get('redirect_uri'); // Get redirect_uri from query params
    return redirectUriFromQuery || process.env.REACT_APP_OAUTH_REDIRECT_URI; // Fall back to env var if not present
};

// Create an instance of OAuth2AuthCodePkceClient with the relevant configuration
const oauthClient = new OAuth2AuthCodePkceClient({
    scopes: process.env.REACT_APP_SCOPES.split(' '),
    authorizationUrl: process.env.REACT_APP_OAUTH_AUTHORIZATION_URL,
    tokenUrl: process.env.REACT_APP_OAUTH_TOKEN_URL,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirectUrl: getRedirectUrl(), // Use dynamic redirect URL
    storeRefreshToken: false,
    onAccessTokenExpiry() {
        return oauthClient.exchangeRefreshTokenForAccessToken();
    },
    onInvalidGrant() {
        console.warn('Invalid grant! Redirecting and removing query params.');

        // Clear the query string and reload the page
        const url = new URL(window.location.href);
        url.search = ''; // Remove all query params
        window.location.replace(url.toString());
    },
    onInvalidToken() {
        console.warn('Invalid token! Refresh and access tokens need to be renewed.');
    },
});

export const useLiferayOAuth = (requiresAuth = true) => {
    const { login, logout: storeLogout, setRoles, setUser } = useAuthStore(); // Alias Zustand logout
    const [token, setToken] = useState(null);
    const [loginInProgress, setLoginInProgress] = useState(true);

    // Function to log out and redirect
    const oauthLogout = () => {
        //storeLogout(); // Call Zustand's logout to clear session
        //setToken(null);
        console.log("logging out");

        const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
        if (logoutUrl) {
            window.location.href = logoutUrl; // Redirect to logout URL
        } else {
            console.error('REACT_APP_LOGOUT_URL is not defined.');
        }
    };

    useEffect(() => {
        const performLogin = async () => {
            try {
                const authCode = new URLSearchParams(window.location.search).get('code');

                if (authCode) {
                    await oauthClient.receiveCode(); // Receive the code from the URL
                    const tokens = await oauthClient.getTokens(); // Get the tokens
                    const { accessToken, refreshToken } = tokens;
                    const accessTokenString = accessToken || '';
                    const refreshTokenString = refreshToken || '';

                    login({ name: 'Authenticated User' }, accessTokenString);
                    setToken(accessTokenString);

                    // Fetch and store user data and roles using userService functions
                    const userData = await fetchUserData(accessTokenString);
                    setUser(userData);
                    const roles = await fetchUserRoles(accessTokenString);
                    setRoles(roles);
                } else if (requiresAuth) {
                    await oauthClient.requestAuthorizationCode(); // Only request if authentication is required
                }
            } catch (error) {
                console.error('Login failed:', error);
                storeLogout(); // Call Zustand logout if login fails
            } finally {
                setLoginInProgress(false);
            }
        };

        if (requiresAuth) {
            performLogin();
        } else {
            setLoginInProgress(false); // Skip login for public routes
        }
    }, [login, storeLogout, setRoles, setUser, requiresAuth]);

    return { token, loginInProgress, oauthLogout }; // Return renamed logout function
};