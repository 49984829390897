import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/agencyform";

// Save agency form
export const saveAgencyForm = async (agencyForm) => {
    try {
        const response = await axiosInstance.put(`${endpoint}/save`, agencyForm);
        return response.data;
    } catch (error) {
        console.error('Error saving agency form:', error);
        throw error;
    }
};

// Get parent forms by agency ID
export const getParentForms = async (agencyId) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/parentForms/${agencyId}`);
        return response.data;
    } catch (error) {
        // console.error('Error fetching parent forms:', error);
        throw error;
    }
};

// Delete form and form fields by agency ID
export const deleteFormAndFormFieldsByAgencyId = async (agencyId) => {
    try {
        const response = await axiosInstance.delete(`${endpoint}/deleteByAgencyId/${agencyId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting form and form fields:', error);
        throw error;
    }
};
