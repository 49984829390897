import React, { useState, useEffect } from "react";
import {
  getAgencyById,
  updateStatusAgencyById,
  sendActivationLink,
} from "../../../../api/nebAgencyService";
import { getDataProviderListByAgencyId } from "../../../../api/nebDataProviderService";
import { getLookupParameterDetailsByCode } from "../../../../api/lookupService";
import { Button } from "react-bootstrap";
import { toast, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AgencySummary = ({
  agencyId,
  handleSave,
  handleBack,
  setShowSummary,
}) => {
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [latestDataProvider, setLatestDataProvider] = useState(null);
  const [sectorDescription, setSectorDescription] = useState(""); // State for sector description
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgencyDetails = async () => {
      try {
        const response = await getAgencyById(agencyId);
        console.log("Fetched Agency Details:", response);
        setAgencyDetails(response);

        // Get sector description based on sectorId
        const sectorResponse = await getLookupParameterDetailsByCode(
          response.sectorId
        );
        setSectorDescription(
          sectorResponse ? sectorResponse.description : "Description not found"
        );
        console.log(
          "Sector Description:",
          sectorResponse ? sectorResponse.description : "Description not found"
        );
      } catch (error) {
        console.error("Error fetching agency details:", error);
      }
    };

    fetchAgencyDetails();
  }, [agencyId, setShowSummary]);

  useEffect(() => {
    const fetchLatestDataProvider = async (agencyId) => {
      try {
        const response = await getDataProviderListByAgencyId(agencyId);
        if (response.length > 0) {
          const sortedDataProviders = response.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLatestDataProvider(sortedDataProviders[0]);
        }
      } catch (error) {
        console.error("Error fetching Data Provider details:", error);
      }
    };

    fetchLatestDataProvider(agencyId);
  }, [agencyId]);

  const isSameAsContactPerson =
    latestDataProvider &&
    agencyDetails &&
    latestDataProvider.name === agencyDetails.cpdName &&
    latestDataProvider.email === agencyDetails.cpdEmail &&
    latestDataProvider.phoneNo === agencyDetails.cpdPhoneNumber;

  const onCreateAgency = async () => {
    try {
      const updateStatus = {
        status: "PENDING",
      };

      // Update the status of the agency
      await updateStatusAgencyById(agencyId, updateStatus);

      // Show success toast notification
      toast.success(`Agency Submitted!`);

      // send activation link
      await sendActivationLink(agencyDetails);

      // Navigate to the agency list after a delay
      setTimeout(() => {
        navigate(`/neb-agency`);
      }, 2000);
    } catch (error) {
      console.log("Error saving", error);
      toast.error("Error submitting the agency");
    }
  };

  const onCancelClick = () => {
    if (agencyDetails.status !== "ACTIVE") {
      const confirmation = window.confirm(
        "Are you sure you want to cancel this process?"
      );
      if (confirmation) {
      toast.success(`The process have been cancel`);
        setTimeout(() => {
          navigate(`/neb-agency`);
        }, 2000);
      }
    } else {
      setTimeout(() => {
        navigate(`/neb-agency`);
      }, 2000);
    }
  };

  return (
    <>
      {agencyDetails && (
        <div className="card">
          <div className="card-header">
            <h4>Data Provider Summary</h4>
            <p>Check all the details before creating data provider's account</p>
          </div>
          <div className="card-body">
            {/* Agency Details */}
            <div className="row mb-2">
              <h5>
                <strong>Agency Details</strong>
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-sm-3">
                <strong>ENERGY SECTOR</strong>
                <br />
                {sectorDescription}
              </div>
              <div className="col-sm-3">
                <strong>AGENCY</strong>
                <br />
                {agencyDetails.agencyName}
              </div>
              <div className="col-sm-3">
                <strong>OFFICE NUMBER</strong>
                <br />
                {agencyDetails.phoneNumber}
              </div>
              <div className="col-sm-3">
                <strong>ADDRESS</strong>
                <br />
                {agencyDetails.address1}, {agencyDetails.address2},{" "}
                {agencyDetails.address3}
              </div>
            </div>

            {/* Contact Person details */}
            <div className="row mb-2">
              <h5>
                <strong>Contact Person details</strong>
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-sm-3">
                <strong>NAME</strong>
                <br />
                {agencyDetails.cpdName}
              </div>
              <div className="col-sm-3">
                <strong>EMAIL</strong>
                <br />
                {agencyDetails.cpdEmail}
              </div>
              <div className="col-sm-3">
                <strong>PHONE NUMBER</strong>
                <br />
                {agencyDetails.cpdPhoneNumber}
              </div>
            </div>
            <div className="row mb-2">
              <h5>
                <strong>Login ID Information</strong>
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-sm-3">
                <strong>Login ID</strong>
                <br />
                {agencyDetails.loginId}
              </div>
            </div>

            {/* Data Provider Details */}
            <div className="row mb-2">
              <h5>
                <strong>Data Provider Details</strong>
              </h5>
            </div>
            {latestDataProvider ? (
              <div className="row mb-4">
                {isSameAsContactPerson ? (
                  <div className="col-sm-12">
                    Data Provider Details same with Contact Person Details
                  </div>
                ) : (
                  <>
                    <div className="col-sm-3">
                      <strong>NAME</strong>
                      <br />
                      {latestDataProvider.name}
                    </div>
                    <div className="col-sm-3">
                      <strong>EMAIL</strong>
                      <br />
                      {latestDataProvider.email}
                    </div>
                    <div className="col-sm-3">
                      <strong>PHONE NUMBER</strong>
                      <br />
                      {latestDataProvider.phoneNo}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="row mb-4">
                <div className="col-sm-3">
                  <strong>NAME</strong>
                  <br />
                  No data provider available
                </div>
              </div>
            )}

            {/* Product List */}
            <div className="row mb-2">
              <h5>
                <strong>Product List</strong>
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                {agencyDetails.categories.map((category) =>
                  category.products.map((product) => (
                    <div key={product.id}>{product.productName}</div>
                  ))
                )}
              </div>
            </div>

            {/* Form List */}
            <div className="row mb-2">
              <h5>
                <strong>Form List</strong>
              </h5>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                {agencyDetails.forms.map((form) => (
                  <div key={form.id} className="mb-3">
                    {form.formDescription}
                    <div>
                      {form.formFields.map((formField) => (
                        <span
                          key={formField.id}
                          className="badge bg-primary me-1 mr-1"
                        >
                          {formField.formFieldName}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Action Buttons */}
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="light"
          className="mr-2 btn btn-outline-secondary"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="link"
          className="mr-2 btn btn-outline-primary"
          onClick={onCancelClick}
        >
          {agencyDetails && agencyDetails.status !== "ACTIVE"
            ? "Cancel"
            : "Done"}
        </Button>
        {agencyDetails && agencyDetails.status !== "ACTIVE" && (
          <Button variant="primary" className="mr-2" onClick={onCreateAgency}>
            Save Agency
          </Button>
        )}
      </div>
    </>
  );
};

export default AgencySummary;
