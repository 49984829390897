import React, { useState } from 'react';
import { createFolder, uploadFile, listFiles, downloadFile } from '../../../api/fileSystemService';

const FilesystemComponent = () => {
  const [folderPath, setFolderPath] = useState('');
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileToDownload, setFileToDownload] = useState('');

  // Fungsi untuk membuat folder baru
  const handleCreateFolder = async () => {
    try {
      const response = await createFolder(folderPath);
      console.log('Folder created at:', response.relativePath);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  // Fungsi untuk memuat naik fail
  const handleUploadFile = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }
    
    try {
      const response = await uploadFile(folderPath, file);
      console.log('File uploaded:', response.relativePath);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleListFiles = async () => {
    try {
      const filesList = await listFiles(folderPath);
      setFiles(filesList);
    } catch (error) {
      console.error('Error listing files:', error);
    }
  };

  const handleDownloadFile = async () => {
    if (!fileToDownload) {
      alert('Please select a file to download!');
      return;
    }
    
    try {
      const response = await downloadFile(fileToDownload);
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileToDownload;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div>
      <h2>Filesystem Operations</h2>
      
      <input
        type="text"
        placeholder="Enter folder path"
        value={folderPath}
        onChange={(e) => setFolderPath(e.target.value)}
      />
      <button onClick={handleCreateFolder}>Create Folder</button>
      
      <input
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <button onClick={handleUploadFile}>Upload File</button>

      <button onClick={handleListFiles}>List Files</button>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            {file}
            <button onClick={() => setFileToDownload(file)}>Select for Download</button>
          </li>
        ))}
      </ul>
      <button onClick={handleDownloadFile}>Download Selected File</button>
    </div>
  );
};

export default FilesystemComponent;
