import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/formtemplate";

// Fetch form template by parent code
export const getFormTemplateByParentCode = async (parentCode) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/listByParent/${parentCode}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching form template by parent code:', error);
        throw error;
    }
};

// Fetch all form templates and return in the desired format
export const getParentsWithFirstChild = async () => {
    try {
        const response = await axiosInstance.get(`${endpoint}/parents-with-first-child`);
        return response.data; // Assuming the controller returns the correct map structure
    } catch (error) {
        console.error('Error fetching parents with first child:', error);
        throw error;
    }
};

// Fetch all form templates (optional example for completeness)
export const getAllFormTemplates = async () => {
    try {
        const response = await axiosInstance.get(`${endpoint}/list`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all form templates:', error);
        throw error;
    }
};

// Save or update form template (optional example for completeness)
export const saveFormTemplate = async (formTemplate) => {
    try {
        const response = await axiosInstance.post(`${endpoint}/save`, formTemplate);
        return response.data;
    } catch (error) {
        console.error('Error saving form template:', error);
        throw error;
    }
};

// Fetch form templates by list of codes
export const getFormTemplatesByCodes = async (codes) => {
    try {
        const response = await axiosInstance.post(`${endpoint}/by-codes`, codes);
        return response.data; // Assuming the API returns a map of codes to form details
    } catch (error) {
        console.error('Error fetching form templates by codes:', error);
        throw error;
    }
};

///totalSelloutRelated

// Fetch form templates related to total sellout
export const getTotalSelloutRelatedForms = async (codes) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/totalSelloutRelated`);
        return response.data; // Assuming the API returns a map of codes to form details
    } catch (error) {
        console.error('Error fetching form templates by codes:', error);
        throw error;
    }
};
