import axiosInstance from './axiosInstance';

const endpoint = "/lookup";

export const fetchLookupParameters = async (category) => {
    const response = await axiosInstance.get(`${endpoint}/category/${category}`);
    return response.data; // Assuming the response is an array of {code, description}
};

export const getLookupParameterDetailsByCode = async (code) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/getLookupParameterDetailsByCode/${code}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const findByLookupCategoryAndParentCode = async (lookupCategory, parentCode) => {
    try {
        const response = await axiosInstance.get(endpoint + "/category/" + lookupCategory + "/parent/" + parentCode);
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}

export const getLookupParametersByCategory = async (lookupCategory) => {
    try {
        const response = await axiosInstance.get(`${endpoint}/category/${lookupCategory}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};