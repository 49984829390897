import axiosInstance from './axiosInstance'; // Import the axios instance

const endpoint = "/neb/nebproductcategoryunitlookup";

export const getUnitByCategoryId = async (categoryId) => {
  try {
    const response = await axiosInstance.get(`${endpoint}/unitByCategoryId/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products by category id:', error);
    throw error;
  }
};


