import React, { useCallback, useState, useEffect } from "react";
import { Table, Button, Pagination } from "react-bootstrap";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchTicketCountByStatus,
  fetchTickets,
} from "../../../api/ticketService";
import { getLookupParametersByCategory } from "../../../api/lookupService";
import ClayButton from "@clayui/button";
import useAuthStore from "../../../store/useAuthStore";

import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";

const TicketResolutionCentre = () => {
  const [newTicketsCount, seNewTicketsCount] = useState(0);
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const [resolvedTicketsCount, setResolvedTicketsCount] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [sort, setSort] = useState({
    column: "createdDate",
    direction: "ascending",
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [categoryDescriptions, setCategoryDescriptions] = useState({});
  const [statusDescriptions, setStatusDescriptions] = useState({}); // For storing status descriptions
  const navigate = useNavigate(); // Use useNavigate hook for navigation

  const [sortColumn, setSortColumn] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("asc");

  const { roles } = useAuthStore((state) => ({ roles: state.roles })); // Get roles from Zustand store

  useEffect(() => {
    const loadTicketCounts = async () => {
      try {
        const newTickets = await fetchTicketCountByStatus("NEW");
        const openTickets = await fetchTicketCountByStatus("OPEN");
        const resolvedTickets = await fetchTicketCountByStatus("RESOLVED");

        seNewTicketsCount(newTickets);
        setOpenTicketsCount(openTickets);
        setResolvedTicketsCount(resolvedTickets);
      } catch (error) {
        console.error("Error fetching ticket counts:", error);
      }
    };

    loadTicketCounts();
  }, []);

  // Fetch tickets with sorting and pagination
  const loadTickets = useCallback(async () => {
    try {
      const response = await fetchTickets(
        page,
        size,
        sort.column,
        sort.direction
      );
      setTickets(response.content); // Assuming response.content contains the paginated data
      setTotalPages(response.totalPages); // Assuming totalPages is part of the response
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  }, [page, size, sort]);

  useEffect(() => {
    loadTickets();
  }, [loadTickets]);

  // Fetch status descriptions
  useEffect(() => {
    const loadStatusDescriptions = async () => {
      try {
        const response = await getLookupParametersByCategory("FEEDBACK-STATUS"); // Fetch status descriptions
        const statusMap = response.reduce((acc, status) => {
          acc[status.code] = status.description;
          return acc;
        }, {});
        setStatusDescriptions(statusMap);
      } catch (error) {
        console.error("Error fetching status descriptions:", error);
      }
    };

    const loadCategoryDescriptions = async () => {
      try {
        const response = await getLookupParametersByCategory("TICKET-CATEGORY");
        const categoryMap = response.reduce((acc, category) => {
          acc[category.code] = category.description;
          return acc;
        }, {});
        setCategoryDescriptions(categoryMap);
      } catch (error) {
        console.error("Error fetching status descriptions:", error);
      }
    };

    loadStatusDescriptions();
    loadCategoryDescriptions();
  }, []);

  // Handle sort change
  const onSortChange = (column) => {
    const isAsc = sortColumn === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortColumn(column);

    // Sort the agency list based on column and order
    const sortedList = [...tickets].sort((a, b) => {
      if (a[column] < b[column]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    setTickets(sortedList);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage - 1); // Pages are 0-indexed on the backend
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy h:mm:ss aa");
  };

  // Navigate to TicketForm when clicking "View Details"
  const viewTicket = (ticketId) => {
    navigate(`/ticket/ticket-form/${ticketId}`); // Navigate to the TicketForm component
  };

  return (
    <>
      <div className="dashboard">
        <div className="row">
          <div className="col-md-4">
            <ClayCard>
              <ClayCard.Body>
                <ClayCard.Description displayType="title">
                  New Tickets
                </ClayCard.Description>
                <ClayCard.Description displayType="text">
                  {newTicketsCount}
                </ClayCard.Description>
              </ClayCard.Body>
            </ClayCard>
          </div>
          <div className="col-md-4">
            <ClayCard>
              <ClayCard.Body>
                <ClayCard.Description displayType="title">
                  Open Tickets
                </ClayCard.Description>
                <ClayCard.Description displayType="text">
                  {openTicketsCount}
                </ClayCard.Description>
              </ClayCard.Body>
            </ClayCard>
          </div>
          <div className="col-md-4">
            <ClayCard>
              <ClayCard.Body>
                <ClayCard.Description displayType="title">
                  Resolved Tickets
                </ClayCard.Description>
                <ClayCard.Description displayType="text">
                  {resolvedTicketsCount}
                </ClayCard.Description>
              </ClayCard.Body>
            </ClayCard>
          </div>
        </div>
      </div>
      <div className="issue-listing">
        {roles.includes("NEB_DATA_PROVIDER") && (
          <div className="button-submit-new-ticket">
            <ClayLayout.Col md={15} className="d-flex justify-content-end">
              <Link to="/neb-data-provider-ticket/new">
                <ClayButton displayType="primary" className="mb-2 mb-md-0">
                  + Submit New Ticket
                </ClayButton>
              </Link>
            </ClayLayout.Col>
            <br />
          </div>
        )}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => onSortChange("id")}>
                Ticket ID{" "}
                {sortColumn === "id" && (sortOrder === "ascending" ? "▲" : "▼")}
              </th>
              <th onClick={() => onSortChange("category")}>
                Category{" "}
                {sortColumn === "category" &&
                  (sortOrder === "ascending" ? "▲" : "▼")}
              </th>
              <th onClick={() => onSortChange("title")}>
                Title{" "}
                {sortColumn === "title" &&
                  (sortOrder === "ascending" ? "▲" : "▼")}
              </th>
              <th onClick={() => onSortChange("createdDate")}>
                Created Date{" "}
                {sortColumn === "createdDate" &&
                  (sortOrder === "ascending" ? "▲" : "▼")}
              </th>
              <th onClick={() => onSortChange("status")}>
                Status{" "}
                {sortColumn === "status" &&
                  (sortOrder === "ascending" ? "▲" : "▼")}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>{`#${ticket.id.toString().padStart(5, "0")}`}</td>
                <td>
                  {categoryDescriptions[ticket.category] || ticket.category}
                </td>
                <td>{ticket.title}</td>
                <td>{formatDate(ticket.createdDate)}</td>
                <td>
                  {statusDescriptions[ticket.status] || ticket.status}
                </td>{" "}
                {/* Display the description */}
                <td>
                  <Button
                    variant="primary"
                    onClick={() => viewTicket(ticket.id)}
                  >
                    View Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Pagination Component */}
        <Pagination>
          <Pagination.First onClick={() => handlePageChange(1)} />
          <Pagination.Prev
            onClick={() => handlePageChange(page)}
            disabled={page === 0}
          />
          {[...Array(totalPages)].map((_, idx) => (
            <Pagination.Item
              key={idx + 1}
              active={idx === page}
              onClick={() => handlePageChange(idx + 1)}
            >
              {idx + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(page + 2)}
            disabled={page + 1 === totalPages}
          />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} />
        </Pagination>
      </div>
    </>
  );
};

export default TicketResolutionCentre;
