import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom'; // To get URL parameters
import { toast, ToastContainer } from 'react-toastify'; // Toast notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const Activate = () => {
  const location = useLocation(); // Get the location object
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Extract hashedId from the query string
  const queryParams = new URLSearchParams(location.search);
  const hashedId = queryParams.get('hashedId');

  useEffect(() => {
    const activateAgency = async () => {
      setLoading(true);
      try {
        // Fetch the base URL from environment variables
        const apiUrl = process.env.REACT_APP_API_URL;
        
        // Send the PUT request to activate the agency
        const response = await axios.get(`${apiUrl}/neb/agency/activateHashed/${hashedId}`);

        // If successful, show a success message
        toast.success('Agency activated successfully!');

        // Redirect to the login URL after a short delay (3 seconds)
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 3000); // 3-second delay for the toast message
      } catch (err) {
        // Handle errors
        const errorMsg = err.response?.data || 'An error occurred';
        setError(errorMsg);
        toast.error(`Failed to activate agency: ${errorMsg}`);
      } finally {
        setLoading(false);
      }
    };

    if (hashedId) {
      activateAgency(); // Call the API if hashedId is present
    } else {
      toast.error('No hashed ID provided.'); // Handle case when hashedId is missing
    }
  }, [hashedId]);

  if (loading) {
    return <div>Activating agency, please wait...</div>;
  }

  return (
    <div>
      <ToastContainer /> {/* Toast notifications will be displayed here */}
      {error ? <div>Error: {error}</div> : <div>Agency activation initiated successfully.</div>}
    </div>
  );
};

export default Activate;