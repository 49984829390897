import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { faAngleLeft, faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({ currentPage, totalPages, onPageChange, onEntriesChange, entriesPerPage }) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    // Determine the range of pages to display (show 10 pages max)
    const maxVisiblePages = 10;
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    const visiblePages = pageNumbers.slice(startPage - 1, endPage);

    return (
        <div className="pagination-bar d-flex align-items-center justify-content-between">
            {/* Entries Per Page Dropdown */}
            <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {`${entriesPerPage} entries`}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {[5, 10, 20, 30, 50].map((num) => (
                        <Dropdown.Item
                            key={num}
                            onClick={() => onEntriesChange(num)}
                        >
                            {num} entries
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {/* Pagination Results */}
            <div className="pagination-results">
                Showing {Math.min((currentPage - 1) * entriesPerPage + 1, totalPages * entriesPerPage)} to {Math.min(currentPage * entriesPerPage, totalPages * entriesPerPage)} of {totalPages * entriesPerPage} entries.
            </div>

            {/* Pagination Controls */}
            <ul className="pagination">
                {/* Previous Button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a
                        className="page-link"
                        href="#"
                        role="button"
                        onClick={(e) => {
                            e.preventDefault();
                            currentPage > 1 && onPageChange(currentPage - 1);
                        }}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                        <span className="sr-only">Previous</span>
                    </a>
                </li>

                {/* Page Numbers */}
                {visiblePages.map(num => (
                    <li key={num} className={`page-item ${num === currentPage ? 'active' : ''}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onPageChange(num);
                            }}
                        >
                            {num}
                        </a>
                    </li>
                ))}

                {/* Next Button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a
                        className="page-link"
                        href="#"
                        role="button"
                        onClick={(e) => {
                            e.preventDefault();
                            currentPage < totalPages && onPageChange(currentPage + 1);
                        }}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span className="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;