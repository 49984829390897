import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { useLocation, Link } from 'react-router-dom';

const VerticalNav = ({ items }) => {
  const [expandedSections, setExpandedSections] = useState({});
  const location = useLocation();

  const handleToggle = (id) => {
    setExpandedSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const isActive = (href) => {
    return location.pathname === href;
  };

  const onBackToMainPage = () => {
    console.log("logout");
    const mainPageUrl = process.env.REACT_APP_PORTAL_HOST;
    if (mainPageUrl) {
      window.location.href = mainPageUrl;
    } else {
      console.error('REACT_APP_PORTAL_HOST is not defined.');
    }
    return false;
  };

  const renderNavItems = (navItems) => {
    return navItems
      .filter(item => item.showInMenu !== false) // Filter out items that shouldn't be shown
      .map((item) => {
        const isExpanded = expandedSections[item.id] || false;
        const itemActive = item.items 
          ? item.items.some(subItem => isActive(subItem.href)) 
          : isActive(item.href);

        return (
          <li key={item.id} className={`nav-item ${itemActive ? 'active' : ''}`}>
            {item.items && item.items.length > 0 ? (
              <>
                <button
                  aria-controls={`collapse${item.id}`}
                  aria-expanded={isExpanded}
                  className={`nav-link collapse-icon ${isExpanded ? '' : 'collapsed'}`}
                  onClick={() => handleToggle(item.id)}
                >
                    <span><FontAwesomeIcon icon={solidIcons[item.iconlabel]} className="mr-1" /> <span className="nav-text">{item.label}</span></span>
                  <FontAwesomeIcon
                    icon={isExpanded ? faChevronDown : faChevronLeft}
                    className="ml-2"
                  />
                </button>
                <div
                  className={`collapse ${isExpanded ? 'show' : ''}`}
                  id={`collapse${item.id}`}
                >
                  <ul className="nav nav-stacked">
                    {renderNavItems(item.items)} {/* Render child items recursively */}
                  </ul>
                </div>
              </>
            ) : (
              <Link className={`nav-link ${itemActive ? 'active' : ''}`} to={item.href}>
                <FontAwesomeIcon icon={solidIcons[item.iconlabel]} className="mr-1" /> <span className="nav-text">{item.label}</span>
              </Link>
            )}
          </li>
        );
      });
  };

  return (
    <nav className="menubar mytheme-menubar">
        <div className="ml-3 mt-2 mb-3"><a href="#" className="text-xs" onClick={onBackToMainPage}><FontAwesomeIcon icon={solidIcons['faAngleDoubleLeft']} /> <span className="nav-text">back to MyEnergyStats</span></a></div>

      <ul className="nav nav-nested-margins">{renderNavItems(items)}</ul>
    </nav>
  );
};

export default VerticalNav;