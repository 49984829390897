import React, { useEffect, useState } from "react";
import { ClaySelect, ClayInput } from '@clayui/form';
import ClayButton from "@clayui/button";
import ClayLayout from "@clayui/layout";
import { Table } from "@clayui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchEnergyData, fetchEnergyDataById, saveEnergyData, deleteEnergyData } from "../../api/energyDataService"; // Add delete API
import { fetchEnergyDataTypes, fetchEnergyDataCategories, fetchEnergyDataTemplateByCategoryAndType } from "../../api/energyDataTemplateService";
import { useModal } from "@clayui/modal";
import EnergyDataModal from "./EnergyDataModal";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import Pagination from "../../components/Pagination";

function EnergyDataFormList() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [typeList, setTypeList] = useState([]);

    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedType, setSelectedType] = useState("");

    const [currentPage, setCurrentPage] = useState(1); // Change to 1-based index
    const [pageSize, setPageSize] = useState(10); // Default page size
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [mode, setMode] = useState("create");
    const { observer, onClose } = useModal({
        onClose: () => setShowModal(false),
    });
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedData, setSelectedData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = {
                year: selectedYear ? selectedYear.getFullYear() : "",
                category: selectedCategory,
                type: selectedType,
                page: currentPage - 1, // Adjust for 0-based index in the API
                size: pageSize,
            };
            console.log(searchParams);
            const result = await fetchEnergyData(searchParams);
            setData(result.content || []);
            setFilteredData(result.content || []);
            setTotalPages(result.totalPages || 0);
            setTotalElements(result.totalElements || 0);
        };

        fetchData();
    }, [selectedYear, selectedCategory, selectedType, currentPage, pageSize]);

    useEffect(() => {
        const loadCategoriesAndTypes = async () => {
            try {
                const categories = await fetchEnergyDataCategories();
                const types = await fetchEnergyDataTypes();

                setCategoryList(categories);
                setTypeList(types);
            } catch (error) {
                console.error("Error fetching categories or types:", error);
            }
        };

        loadCategoriesAndTypes();
    }, []);

    const handleYearChange = (date) => {
        setSelectedYear(date);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleEntriesChange = (entries) => {
        setPageSize(entries);
        setCurrentPage(1); // Reset to first page when changing entries
    };

    const handleAddNewReport = async () => {
        try {
            if (!selectedCategory || !selectedType) {
                toast.error("Please select a category and type before adding a new report.");
                return;
            }

            const template = await fetchEnergyDataTemplateByCategoryAndType(selectedCategory, selectedType);

            if (!template) {
                toast.error("No form template found for the selected category and type.");
                return;
            }

            setSelectedTemplate(template);
            setSelectedData(null);
            setMode("create");
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching template:", error);
            if (error.response && error.response.status === 404) {
                toast.error("Template not found for the selected category and type.");
            } else {
                toast.error("An error occurred while fetching the form template.");
            }
        }
    };

    const handleEditReport = async (dataId) => {
        try {
            const existingData = await fetchEnergyDataById(dataId);
            const template = await fetchEnergyDataTemplateByCategoryAndType(existingData.category.code, existingData.type.code);

            if (!template) {
                toast.error("No form template found for the selected category and type.");
                return;
            }

            setSelectedTemplate(template);
            setSelectedData(existingData);
            setMode("edit");
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching template:", error);
            if (error.response && error.response.status === 404) {
                toast.error("Template not found for the selected category and type.");
            } else {
                toast.error("An error occurred while fetching the form template.");
            }
        }
    };

    const handleSaveReport = async (formData) => {
        try {
            await saveEnergyData(formData);

            const searchParams = {
                year: selectedYear ? selectedYear.getFullYear() : "",
                category: selectedCategory,
                type: selectedType,
                page: currentPage - 1, // Adjust for 0-based index in the API
                size: pageSize,
            };

            const result = await fetchEnergyData(searchParams);
            setData(result.content || []);
            setFilteredData(result.content || []);
            setTotalPages(result.totalPages || 0);
            setTotalElements(result.totalElements || 0);

            toast.success(mode === "create" ? "Energy data saved successfully!" : "Energy data updated successfully!");
            setShowModal(false);
        } catch (error) {
            console.error("Error saving energy data:", error);
            toast.error("An error occurred while saving energy data.");
        }
    };

    const handleDeleteReport = async (dataId) => {
        try {
            await deleteEnergyData(dataId);

            const searchParams = {
                year: selectedYear ? selectedYear.getFullYear() : "",
                category: selectedCategory,
                type: selectedType,
                page: currentPage - 1,
                size: pageSize,
            };

            const result = await fetchEnergyData(searchParams);
            setData(result.content || []);
            setFilteredData(result.content || []);
            setTotalPages(result.totalPages || 0);
            setTotalElements(result.totalElements || 0);

            toast.success("Energy data deleted successfully!");
        } catch (error) {
            console.error("Error deleting energy data:", error);
            toast.error("An error occurred while deleting energy data.");
        }
    };

    return (
        <>
            <ClayButton displayType="primary" onClick={handleAddNewReport}>
                Add New Report
            </ClayButton>

            <ClayLayout.Row style={{ marginTop: "20px" }}>
                <ClayLayout.Col size={4}>
                    <ClaySelect id="type" value={selectedType} onChange={handleTypeChange}>
                        <option value="">Select Energy</option>
                        {typeList.map((type, index) => (
                            <option key={index} value={type.code}>{type.description}</option>
                        ))}
                    </ClaySelect>
                </ClayLayout.Col>
                <ClayLayout.Col size={4}>
                    <ClaySelect id="category" value={selectedCategory} onChange={handleCategoryChange}>
                        <option value="">Select Process</option>
                        {categoryList.map((category, index) => (
                            <option key={index} value={category.code}>{category.description}</option>
                        ))}
                    </ClaySelect>
                </ClayLayout.Col>
                <ClayLayout.Col size={4}>
                    <DatePicker
                        selected={selectedYear}
                        onChange={handleYearChange}
                        showYearPicker
                        dateFormat="yyyy"
                        placeholderText="Select Year"
                        className="form-control"
                    />
                </ClayLayout.Col>
            </ClayLayout.Row>

            <Table style={{ marginTop: "20px" }}>
                <thead>
                    <tr>
                        <th>Energy</th>
                        <th>Process</th>
                        <th>Year</th>
                        <th>Date Created</th>
                        <th>Date Modified</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.type ? item.type.description : "N/A"}</td>
                            <td>{item.category.description}</td>
                            <td>{item.year}</td>
                            <td>{dayjs(item.createdDate).format("DD/MM/YYYY")}</td>
                            <td>{dayjs(item.modifiedDate).format("DD/MM/YYYY")}</td>
                            <td>
                                <ClayButton displayType="secondary" onClick={() => handleEditReport(item.id)}>
                                    Edit
                                </ClayButton>
                                <ClayButton displayType="danger" style={{ marginLeft: "10px" }} onClick={() => handleDeleteReport(item.id)}>
                                    Delete
                                </ClayButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onEntriesChange={handleEntriesChange}
                entriesPerPage={pageSize} // Pass the current page size
            />

            {showModal && (
                <EnergyDataModal
                    observer={observer}
                    onClose={() => setShowModal(false)}
                    onSave={handleSaveReport}
                    selectedData={selectedData}
                    selectedTemplate={selectedTemplate}
                    selectedYear={selectedYear}
                    mode={mode}
                />
            )}
        </>
    );
}

export default EnergyDataFormList;