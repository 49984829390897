

import React, { useEffect, useState } from "react";
import ClayTabs from '@clayui/tabs';
import FuelBalanceTableDP from "./report-tables/FuelBalanceTableDP";
import C1TableDP from "./report-tables/C1TableDP";
import InstalledCapacityTableDP from "./report-tables/InstalledCapacityTableDP";
import NetCalorificTableDP from "./report-tables/NetCalorificTableDP";
import PlantPerformanceTableDP from "./report-tables/PlantPerformanceTableDP";
import { getParentForms } from "../../../api/nebAgencyFormService";
import { render } from "@testing-library/react";


const DynamicTabsDP = ({ energySector, viewMode, fetchedReport, updateCheckedFormsCount, setParentFormsCount, agencyId, setFuelValuesNETChecking, setProductAndUnitNETChecking, setFuelValuesC1Checking, setProductAndUnitC1Checking, setFuelValuesFBChecking, setProductAndUnitFBChecking, reportMap, setReportMap, selectedQuarter, selectedYear, reportStatus, combinedStructure, setCombinedStructure }) => {

    const [parentForms, setParentForms] = useState([]);
    const [activeTab, setActiveTab] = useState();
    const [totalSellouts, setTotalSellouts] = useState([]);
    const [tabsLoading, setTabsLoading] = useState(true);
    const [firstTabFound, setFirstTabFound] = useState(false);

    useEffect(() => {
        const fetchParentForms = async () => {
            try {
                if (viewMode === "draft") {
                    //get parent forms from report
                    // console.log("parent fetched report: " + JSON.stringify(fetchedReport, null, 2));
                    // const reportParentForms = getParentFormsFromReport(fetchedReport);
                    // console.log("fetching parents:" + JSON.stringify(reportParentForms, null, 2));
                    // setParentForms(reportParentForms);
                    // setParentFormsCount(reportParentForms.length);
                    //get parent forms from agency info in db
                    const result = await getParentForms(agencyId);
                    setParentForms(result);
                    setParentFormsCount(result.length);
                    console.log("fetching parents:" + JSON.stringify(result, null, 2))
                } else {
                    //get parent forms from agency info in db
                    const result = await getParentForms(agencyId);
                    setParentForms(result);
                    setParentFormsCount(result.length);
                    console.log("fetching parents:" + JSON.stringify(result, null, 2))
                }
            } catch (error) {
                // console.error('Error fetching parent forms:', error);
            }
        };

        fetchParentForms();
    }, [agencyId]);

    const getParentFormsFromReport = (report) => {
        const result = [];
        let hasFB = false;
        let hasC1FECS = false;
        let hasIC = false;
        let hasPP = false;
        let hasNC = false;

        report.forms.forEach(form => {
            if (!hasFB && form.formCode.includes('F')) {
                result.push({
                    code: 'FB',
                    description: 'Fuel Balance'
                });
                hasFB = true;
            }

            if (!hasC1FECS && form.formCode.includes('C1')) {
                result.push({
                    code: 'C1-FECS',
                    description: 'Final Energy Consumption Sectors'
                });
                hasC1FECS = true;
            }

            if (!hasIC && form.formCode === 'IC') {
                result.push({
                    code: 'IC',
                    description: 'Installed Capacity'
                });
                hasIC = true;
            }

            if (!hasPP && form.formCode === 'POW') {
                result.push({
                    code: 'PP',
                    description: 'Plant Performance'
                });
                hasPP = true;
            }

            if (!hasNC && form.formCode === 'NETC') {
                result.push({
                    code: 'NC',
                    description: 'Net Calorific'
                });
                hasNC = true;
            }
        });

        return result;
    };

    useEffect(() => {
        if (parentForms.length > 0 && !firstTabFound) {
            setActiveTab(parentForms[0].code)
            setFirstTabFound(true)
        }
    }, [parentForms]);

    const handleSelectTab = (tabKey) => {
        console.log("rendering tabs tabkey: " + tabKey)
        setActiveTab(tabKey);
    };

    const tabDefinitions = {
        "FB": {
            title: "Fuel Balance",
            content: (
                <div style={{ display: activeTab === "FB" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Fuel Balance Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <FuelBalanceTableDP
                                energySector={energySector}
                                viewMode={viewMode}
                                fetchedReport={fetchedReport}
                                updateCheckedFormsCount={updateCheckedFormsCount}
                                agencyId={agencyId}
                                setFuelValuesFBChecking={setFuelValuesFBChecking}
                                setProductAndUnitFBChecking={setProductAndUnitFBChecking}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure}
                                totalSellouts={totalSellouts}
                                setTotalSellouts={setTotalSellouts}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        "C1-FECS": {
            title: "Final Energy Consumption Sectors",
            content: (
                <div style={{ display: activeTab === "C1-FECS" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Final Energy Consumption Sectors Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <C1TableDP
                                viewMode={viewMode}
                                fetchedReport={fetchedReport}
                                updateCheckedFormsCount={updateCheckedFormsCount}
                                agencyId={agencyId}
                                setFuelValuesC1Checking={setFuelValuesC1Checking}
                                setProductAndUnitC1Checking={setProductAndUnitC1Checking}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure}
                                totalSellouts={totalSellouts}
                                setTotalSellouts={setTotalSellouts}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        "IC": {
            title: "Installed Capacity",
            content: (
                <div style={{ display: activeTab === "IC" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Installed Capacity Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <InstalledCapacityTableDP
                                viewMode={viewMode}
                                fetchedReport={fetchedReport}
                                updateCheckedFormsCount={updateCheckedFormsCount}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure} />
                        </div>
                    </div>
                </div>
            ),
        },
        "PP": {
            title: "Plant Performance",
            content: (
                <div style={{ display: activeTab === "PP" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Plant Performance Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <PlantPerformanceTableDP
                                viewMode={viewMode}
                                fetchedReport={fetchedReport}
                                updateCheckedFormsCount={updateCheckedFormsCount}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter} selectedYear={selectedYear} reportStatus={reportStatus} combinedStructure={combinedStructure} setCombinedStructure={setCombinedStructure} />
                        </div>
                    </div>
                </div>
            )
        },
        "NC": {
            title: "Net Calorific",
            content: (
                <div style={{ display: activeTab === "NC" ? "block" : "none" }}>
                    <div>
                        <br />
                        <h3 style={{ fontWeight: 'bold', }}>
                            Net Calorific Report - {selectedQuarter} {selectedYear}
                            &nbsp;&nbsp;&nbsp;
                            <span className={`badge ${reportStatus === "Approved" ? "bg-success" : reportStatus === "Pending for Approval" ? "bg-warning" : reportStatus === "Pending for Amendment" ? "bg-danger" : "bg-secondary"}`}>
                                <span>{reportStatus}</span>
                            </span>
                        </h3>
                        <br />
                        <div style={{ display: 'block' }}>
                            <NetCalorificTableDP
                                viewMode={viewMode}
                                fetchedReport={fetchedReport}
                                updateCheckedFormsCount={updateCheckedFormsCount}
                                setFuelValuesNETChecking={setFuelValuesNETChecking}
                                setProductAndUnitNETChecking={setProductAndUnitNETChecking}
                                reportMap={reportMap}
                                setReportMap={setReportMap}
                                selectedQuarter={selectedQuarter}
                                selectedYear={selectedYear}
                                reportStatus={reportStatus}
                                combinedStructure={combinedStructure}
                                setCombinedStructure={setCombinedStructure} />
                        </div>
                    </div>
                </div>
            ),
        },

    };

    const renderTabs = () => {
        console.log("rendering tabs:" + JSON.stringify(parentForms, null, 2))
        return parentForms.map((form, index) => {
            const tabDef = tabDefinitions[form.code];
            if (tabDef) {
                return (
                    <ClayTabs.Item
                        key={index}
                        active={activeTab === form.code}
                        onClick={() => handleSelectTab(form.code)}
                    >
                        {form.description}
                    </ClayTabs.Item>
                );
            }
            return null;
        });
    };

    const renderTabContent = () => {
        return parentForms.map((form, index) => {
            const tabDef = tabDefinitions[form.code];
            if (tabDef) {
                console.log("rendering tabs found tab content:" + JSON.stringify(form.code));
                return (
                    <div id={`${form.code}_${index}`} key={index}>
                        {tabDef.content}
                    </div>
                );
            }
            return null;
        });
    };

    return (
        <>
            <div>
                <div>
                    <ClayTabs>
                        {renderTabs()}
                    </ClayTabs>
                    <ClayTabs.Content>
                        {renderTabContent()}
                    </ClayTabs.Content>
                </div>
            </div>
        </>
    );
};

export default DynamicTabsDP;


